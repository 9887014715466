import { CssBaseline , ThemeProvider } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import "./styles/styled-components.css";
import { useMemo , useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { themeSettings } from "theme";
import { BrowserRouter as Router , Routes , Route, Navigate } from "react-router-dom";
import Login from "Scenes/Login";
import { logOut } from './state/index';
import Layout from "Scenes/layout";
import Dashboard from 'Scenes/dashboard';
import { signIn } from "state"; 
import AuthOutlet from '@auth-kit/react-router/AuthOutlet'
import { setUserInfo } from "state";
import PointsOfSale from "Scenes/pointsOfSale";
import ResetPassword from "Scenes/ResetPassword";
import NotFound from "Scenes/NotFound";

 
function App() {
  const dispatch = useDispatch();
  const storedToken = sessionStorage.getItem("token");
  if (storedToken) {
      dispatch(signIn(storedToken));
  }
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)))


  useEffect(() => {
    const storedToken = sessionStorage.getItem("token");
    if (storedToken) {
      try {
        // Assuming the token has an 'exp' field indicating its expiration time
        const tokenData = JSON.parse(atob(storedToken.split(".")[1]));
        const isTokenValid = tokenData.exp * 1000 > Date.now(); // Convert seconds to milliseconds
      
        if (isTokenValid) {
          // Token is still valid, dispatch signIn and update userData
          dispatch(signIn(storedToken));
          const userDataString = sessionStorage.getItem("userData");
          if (userDataString) {
            const userData = JSON.parse(userDataString);
            dispatch(setUserInfo(userData));
          }
        } else {
          // Token is expired, clear stored data
          dispatch(logOut());
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("userData");

          document.cookie = '_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    
          // Clear the session
          sessionStorage.clear();
          window.location.reload();
        }
      } catch (error) {
        console.error("Error parsing or validating token:", error);
      }
    }
  }, [dispatch]);


  return (
    <div className="app">
      <Router>
      <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
          <Route element={<AuthOutlet fallbackPath='/auth' />}>
              <Route element={<Layout />} >
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/pointOfSale" element={<PointsOfSale/>} />
             </Route>
            </Route> 
          <Route element={ <Login /> } path="/auth" />
          <Route element={ <ResetPassword /> } path="/reset" />
          <Route path="*" element={<NotFound />} />
          </Routes>
          </ThemeProvider>
        </Router>
    </div>
  );
}

export default App;
