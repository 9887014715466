import React, { useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
import { useGetLoggedInMembersQuery, useGetUserQuery } from 'state/api';


function Layout() {
  const isNonMobile = useMediaQuery("(min-width : 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const userId = useSelector((state) => state.global.userId
  );

  const { data : user } = useGetUserQuery(userId);
  const { data : loggedInMembersData } = useGetLoggedInMembersQuery();
  
  
  /* const { data2, error2, isLoading2  } = useGetloggedInMembersQuery(); */
 

  return (
    <Box  display={isNonMobile ? "flex" : "flex"} width="100%" height="100%" >
      <Sidebar  user={user || {}} isNonMobile={isNonMobile} drawerWidth={!isNonMobile ? "100%" : "250px"} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      <Box flexGrow={1} style={{ overflowY: 'auto' }} >
        <Navbar
          user={user || {}}
        isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}
        />
        <Outlet  />
     </Box>
    </Box>
  )
}

export default Layout;
