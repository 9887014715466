import React from 'react'
import {
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme
} from "@mui/material";

import {
    SettingsOutlined,
    ChevronLeft,
    ChevronRightOutlined,
    HomeOutlined,
    ShoppingCartOutlined,
    Groups2Outlined,
    ReceiptLongOutlined,
    PublicOutlined,
    PointOfSaleOutlined,
    TodayOutlined,
    CalendarMonthOutlined,
    AdminPanelSettingsOutlined,
    TrendingUpOutlined,
    PieChartOutlined
} from '@mui/icons-material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import StoreIcon from '@mui/icons-material/Store';
import GridViewIcon from '@mui/icons-material/GridView';

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FlexBetween from './FlexBetween';


/*{ icon: null, text: "Client Facing"}*/
const navItems = [
    { icon: <GridViewIcon />, text: "Dashboard", linkTo:
        "/"
    },
    { icon: <StoreIcon />, text: "Points Of Sale (POS)", linkTo:
        "/pointOfSale"
    },
]

const Sidebar = ({
    user,
    drawerWidth,
    isSidebarOpen,
    setIsSidebarOpen,
    isNonMobile,
}) => {
    const { pathname } = useLocation();
    const [active, setActive] = useState("");
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        setActive(pathname.substring(1));
    }, [pathname])
    


  return (
    <Box component="nav" >
        {
            isSidebarOpen && (
                <Drawer 
                    open={isSidebarOpen}
                    onClose={() => {
                        setIsSidebarOpen(false)
                    }}
                    variant='temporary'
                    anchor="left"
                    sx={{
    width: drawerWidth,
    "& .MuiDrawer-paper": {
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        overflow: 'auto',
        height: "100%",
        color: theme.palette.secondary[200],
        backgroundColor: "#FFFFFF",
        boxSizing: "border-box",
        borderWidth: isNonMobile ? 0 : "2px",
        width: drawerWidth,
        "&::-webkit-scrollbar": {
            width: 0,  // This will hide the scrollbar in webkit browsers
        }
    }
}}
                >
                    <Box width="100%" >
                        <Box m="1.5rem 2rem 2rem 3rem" >
                            <FlexBetween color={theme.palette.secondary.main} >
                                <Box display="flex" alignItems='center' gap=".5rem" >
                                    <Typography variant='h4' fontWeight='bold' color="#00B185" >
                                        DATA CENTER
                                    </Typography>
                                </Box>
                                {!isNonMobile && (
                                    <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen
                                    )}>
                                        <ChevronLeft/>
                                        </IconButton>
                                )}
                            </FlexBetween>
                        </Box>
                        <List>
                            {
                                navItems.map(({text , icon , linkTo }) => {
                                    if (!icon) {
                                return (
                            <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }} >
                                {text}
                                    </Typography>
                                )
                                    }
                                    const lcText = text.toLowerCase();

                                    return (
                                        <ListItem    key={text} disablePadding >
                                            <ListItemButton onClick={() => {
                                                navigate(`${linkTo}`);
                                                setActive(lcText);
                                            }} sx={{
                                                backgroundColor: active === lcText ? '#DFECFB' : "transparent",
                                                color : active === lcText ? "black" : theme.palette.secondary[100],
                                                margin : "1em",
                                                borderRadius : "10px"
                                            }} >
                                                <ListItemIcon sx={{ ml: "2rem", color: active === lcText ? '#00B185' : theme.palette.secondary[200] }} >{icon}</ListItemIcon>
                                                <ListItemText primary={text} />
                                                {active === lcText && (
                                                    <ChevronRightOutlined sx={{ ml : "auto"}} />
                                                )}
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                }
                                    )
                        }
                        </List>
                      </Box>
                      <Box  mb="2rem" >
                          <Divider />
                          <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 0rem 3rem" >
                              <Box component="img" alt='profile' src={user.img} height="40px" width="40px" borderRadius='50%' sx={{objectFit:"cover"}} />
                                  <Box textAlign="left" >
                                      <Typography fontWeight="bold" fontSize="0.9rem" sx={{ color: theme.palette.secondary[100] }} >
                                          {user.name}
                                      </Typography>
                                      <Typography  fontSize="0.8rem" sx={{ color: "#747981" }} >
                                          {user.lastName}
                                      </Typography>
                                  </Box>
                                  <SettingsOutlined sx={{color : '#00B185', fontSize : "25px"}} />
                              
                          </FlexBetween>
                      </Box>
                </Drawer>
            )
    }
    </Box>
  )
}

export default Sidebar;
