import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Error from '../assets/404.svg';
import Logo from '../assets/Logo_jti.png';

function NotFound() {


  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: '10px',
          left: '10px',
          zIndex: 1, // Ensure the logo is on top
        }}
      >
        <img
          src={Logo}
          alt="Logo"
          className='LogoIntro_Wezign'
          style={{
            userSelect: "none",
            WebkitUserSelect: "none",
            userDrag: "none",
            width: '10em'
          }}
        />
       
      </Box>
      <Box
      className='desktop_bubbles'
        sx={{
          width: '30vw',
          height: '30vw',
          borderRadius: '50%',
          position: 'fixed',
          top: '-25%',
          right: '-10%',
          backgroundColor: '#06B085',
        }}
      />
      <Box
      className='desktop_bubbles'
        sx={{
          width: '30vw',
          height: '30vw',
          borderRadius: '50%',
          position: 'fixed',
          top: '60%',
          left: '-10%',
          backgroundColor: '#06B085',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'fixed',
          gap: '.05rem',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
         <div className="image-wrapper-notfound">
        <img 
          src={Error} 
          className='logoNotFound'
          alt="404 Error" 
          style={{ userSelect: "none", WebkitUserSelect: "none", userDrag: "none", width: '50%', height: '50%' }} 
        />
        </div>
        <Typography
          sx={{
            fontFamily: 'Roboto',
            color: 'black',
            fontSize: '64px',
            fontWeight: '900',
          }}
        >
          Oops !
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Roboto',
            color: '#808080',
            fontSize: '32', // Add the 'px' unit to the fontSize
            fontWeight: '500',
            textAlign: 'center',
            marginTop: '5px'
          }}
        >
          We couldn’t find the page you were <br />
          looking for
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleGoBack}
          sx={{
            backgroundColor: '#06B085',
            borderRadius: '30px',
            width: '10em',
            color: '#FFFFFF',
            marginTop: '15px'
          }}
        >
          Go Back
        </Button>
      </Box>
    </>
  );
}

export default NotFound;
