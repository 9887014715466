import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetTransactionsQuery , useGetMembersActiveParticipationsQuery } from "state/api";
import Header from "components/Header";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import WalletComponent from './WalletComponent';





const  PointsOfSale = () => {

  const theme = useTheme();
  const rowCount = 100;
  // values to be sent to the backend
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [sort, setSort] = useState(JSON.stringify([{ field: "accountCode", sort: "desc" }]));
  const [search, setSearch] = useState("");

  const [dataLoading,setDataLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page, // Initial page
    pageSize, // Initial page size
    search,
    sort
  });

  
 const { data, isLoading, refetch } = useGetMembersActiveParticipationsQuery(paginationModel);

  const [rowCountState, setRowCountState] = useState(rowCount);



  useEffect(() => {
    if (data && !isLoading) {
      setDataLoading(false);
      setRowCountState(data.total);
    }
  }, [search , data , rowCount ]);

  //sort: JSON.stringify(sort),
  //search,



  const columns = [
    {
      field: "accountCode",
      headerName: "AccountCode",
      flex: 0.5,
    },
    {
      field: "memberId",
      headerName: "Member ID",
      flex: 0.7,
    },
    {
      field: "region",
      headerName: "Region",
      flex: 0.5,
    },
    {
      field: "city",
      headerName: "City",
      flex: 0.5,
    },
    {
      field: "accountStatus",
      headerName: "Account Status",
      flex: 0.5,
    },
    {
      field: "challenge",
      headerName: "Nbr of Challenges",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => params.value.length,
    },
    {
      field: "quiz",
      headerName: "Nbr of Quizzes",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => params.value.length,
    },
    {
      field: "game",
      headerName: "Nbr of Games",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => params.value.length,
    },
    {
      field: "pointBalance",
      headerName: "Wallet",
      flex: 1,
      renderCell: (params) => <WalletComponent accountCode={params.row.accountCode} />
    },
  ];

  const formatDataForExport = () => {
    return data?.members.map((item) => ({
      accountCode: item.accountCode,
      memberId: item.memberId,
      region: item.region,
      city: item.city,
      accountStatus: item.accountStatus,
      number_of_challenge: item.challenge.length,
      number_of_quiz: item.quiz.length,
      game: item.game.length,
      Wallet: item.pointBalance,
    })) || [];
  };

  
  return !isLoading && (
    <Box backgroundColor="#F3F5FA" borderRadius="20px 0px 0px 0px" p="1.5em">
      <Header title="POINTS OF SALE (POS)" subtitle="Shows the entire list of active points of sale with there wallets." />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#06B085",
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-row:nth-of-type(odd)": {
            backgroundColor: "rgba(6, 176, 133, 0.2)",
          },
          "& .MuiDataGrid-row:nth-of-type(even)": {
            backgroundColor: "#FFFFFF",
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: "#FFFFFF",
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
       <DataGrid
  getRowId={(row) => row.accountCode}
  rows={data?.members || []}
  {...data}
  columns={columns}
  rowCount={rowCountState}
  disableRowSelectionOnClick
  paginationMode="server"
  sortingMode="server"
  pageSize={paginationModel.pageSize} 
  loading={dataLoading}
  onPaginationModelChange={(newModel) => {
    setDataLoading(true);
    setPaginationModel(newModel);
  }}
  onSortModelChange={(newSortModel) => {
    // Update your state with the new sorting model
    
    // Assuming your pagination model includes sorting information
    setPaginationModel(prev => ({
      ...prev,
      sort: JSON.stringify(newSortModel[0]), // Assuming single column sorting
    }));
  }}
  slots={{
    toolbar: () => (
      <DataGridCustomToolbar
        setPaginationModel={setPaginationModel}
        search={search}
        formatDataForExport={formatDataForExport}
      />
    ),
  }}
/>

      </Box>
    </Box>
  );
};

export default PointsOfSale;


/*
loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={(data && data.members) || []}
          columns={columns}
          rowCount={rowCountState}
          rowsPerPageOptions={[10, 25, 50]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          onPageChange={(newPage) => setPage(page)}
          onPageSizeChange={(newPageSize) => setPageSize(pageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
            }}
*/