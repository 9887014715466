import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme, IconButton, Tooltip} from "@mui/material";
import FlexBetween from './FlexBetween';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import PageViewsSVG from '../assets/page_views.bbb7822e2308d7b6763d80e879d5a113.svg fill.svg';
import { useSelector } from 'react-redux';
import { useGetGAPageViewsQuery, useGETWeeklyDataQuery } from 'state/api';

const StatBoxPageViews = ({ title, help }) => {
  const theme = useTheme();
  const startDate = useSelector(state => state.global.startDate);
  const endDate = useSelector(state => state.global.endDate);
  const dataRender = useSelector(state => state.global.dataRender);
  const CompareToRender = useSelector(state => state.global.CompareToRender);
  const compareStartDate = useSelector(state => state.global.compareStartDate);
  const compareEndDate = useSelector(state => state.global.compareEndDate);
  const weekId = useSelector(state => state.global.weekId);
  const weekCompareId = useSelector(state => state.global.weekCompareId);
  const isCompare = useSelector(state => state.global.isCompare);
  const [showHelp, setShowHelp] = useState(false);
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);
  const [increase, setIncrease] = useState(null);

  const { data: customData, isLoading: customDataIsLoading, error: customDataError } = useGetGAPageViewsQuery({
    startDate,
    endDate
  });


  const { data: customCompareData, isLoading: customCompareDataIsLoading, error: customCompareDataError } = useGetGAPageViewsQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });

  const { data : weekData , isLoading : weekLoading , refetch: refetchWeekData} = useGETWeeklyDataQuery(weekId ? {weekId : weekId.id} : null);
  const { data : CompareToData , isLoading : CompareToDataIsLoading , refetch: refetchCompareToData} = useGETWeeklyDataQuery(weekCompareId ? {weekId : weekCompareId.id }: null);


  const { data: weeklyData, isLoading: weeklyDataIsLoading, error: weeklyDataError } = useGetGAPageViewsQuery({
    startDate: weekData && weekData[0]?.data?.startDate?.split("T")[0],
    endDate: weekData && weekData[0]?.data?.endDate?.split("T")[0]
  });

  const { data: weeklyCompareData, isLoading: weeklyCompareDataIsLoading, error: weeklyCompareDataError } = useGetGAPageViewsQuery({
    startDate: CompareToData && CompareToData[0]?.data?.startDate?.split("T")[0],
    endDate: CompareToData && CompareToData[0]?.data?.endDate?.split("T")[0]
  });
  

  useEffect(() => {

    let primaryData;
    let comparisonData;

    switch (dataRender) {
      case 'Custom':
        primaryData = customData ? customData[1] : null;
        comparisonData = isCompare ? CompareToRender === "Custom" ? customCompareData?.[1] : CompareToRender === "Week" ? weeklyCompareData?.[1] : null : null;
        setValue1(primaryData?.value);
        setValue2(isCompare ? comparisonData?.value : null);
        break;
      case 'Week':
        primaryData = weeklyData ? weeklyData[1] : null;
        comparisonData = isCompare ? CompareToRender === "Custom" ? customCompareData?.[1] : CompareToRender === "Week" ? weeklyCompareData?.[1] : null : null;
        setValue1(primaryData?.value);
        setValue2(isCompare ? comparisonData?.value : null);
        break;
      default:
        primaryData = customData ? customData[1] : null;
        comparisonData = isCompare ? CompareToRender === "Custom" ? customCompareData?.[1] : CompareToRender === "Week" ? weeklyData?.[1]?.data : null : null;
        break;
    }

    setIncrease(isCompare && primaryData !== null && comparisonData !== null ? (((primaryData?.value - comparisonData?.value) / primaryData?.value) * 100).toFixed(2) : null);
  }, [
    startDate, 
    endDate,
    compareStartDate,
    compareEndDate,
    isCompare,
    dataRender,
    CompareToRender,
    weekData,
    CompareToData,  
    weekLoading,
    CompareToDataIsLoading, 
    customData,
    customDataIsLoading,  
    customDataError,    
    customCompareData, 
    customCompareDataIsLoading, 
    customCompareDataError,
    weeklyData,
    weeklyDataIsLoading,
    weeklyDataError,
    weeklyCompareData,
    weeklyCompareDataIsLoading,
    weeklyCompareDataError,
  ]);

  useEffect(() => {
    refetchWeekData();
    refetchCompareToData();
  },[weekId,weekCompareId])



  return (
    <Box
      gridColumn="span 1"
      gridRow="span 1"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor='#FFFFFF'
      borderRadius="0.55rem"
    >
      <FlexBetween margin='0rem 0rem 0.75rem 0rem'>
        <FlexBetween gap="0.75rem">
          <img src={PageViewsSVG} alt="Page Views" width="36" height="36" />
          <Typography variant="subtitle1" sx={{ color: theme.palette.secondary[100], fontSize: '1rem',  display: 'flex', alignItems: 'center' }}>
            {title}
          </Typography>
        </FlexBetween>
        <Tooltip title={help} arrow>
          <IconButton
            size="small"
            onMouseEnter={() => setShowHelp(true)}
            onMouseLeave={() => setShowHelp(false)}
          >
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </FlexBetween>

      {customDataIsLoading || customCompareDataIsLoading || weekLoading || CompareToDataIsLoading  ? (
        <Typography
          variant="h3"
          fontWeight="600"
          sx={{ color: theme.palette.secondary[200] }}
        >
          Loading...
        </Typography>
      ) : (
        <>
        <FlexBetween 
            gap="1rem"
            >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p="0.25rem"
          >
          <Typography
            variant="h3"
            fontWeight="600"
            sx={{ 
              color: theme.palette.secondary[200],
              fontSize: '1.3rem', 
            }}
          >
            {value1}
          </Typography>
          {isCompare && (
          <FlexBetween
            gap="0.5rem"
          >
            <Typography
              variant="h6"
              fontStyle="italic"
              
              display="flex"
              alignItems="center" // Align items vertically in the center
              sx={{
                color: increase !== null ? (increase < 0 ? theme.palette.error.main : theme.palette.success.main) : theme.palette.secondary.light,
                fontSize: '0.87rem',
              }}
            >
              {increase !== null && (
                <>
                  {increase > 0 ? <TrendingUpIcon sx={{ marginRight: '0.2rem' }} /> : <TrendingDownIcon sx={{ marginRight: '0.2rem' }} />}
                  {`${Math.abs(increase)} %`}
                </>
              )}
            </Typography>
            <Typography
              variant="h6"
              fontWeight="400"
              sx={{
                color: theme.palette.secondary[200],
                fontSize: '0.87rem',
              }}
            >
              vs {value2}
            </Typography>
          </FlexBetween>
          )}

          </Box>
          
        </FlexBetween>
        </>
      )}
    </Box>
  );
}

export default StatBoxPageViews;
