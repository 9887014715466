import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme} from "@mui/material";
import FlexBetween from './FlexBetween';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import GameSVG from '../assets/game.0c3a814635f1e9b9d23844cfe2935fe9.svg fill.svg';
import { useSelector } from 'react-redux';
import { useGetNumberLaunchGameBetweendatesQuery, useGetPercentageuniqueparticipantsingameQuery, useGETWeeklyDataQuery } from 'state/api';

const StatBoxGame = () => {
  const theme = useTheme();
  const startDate = useSelector(state => state.global.startDate);
  const endDate = useSelector(state => state.global.endDate);
  const dataRender = useSelector(state => state.global.dataRender);
  const CompareToRender = useSelector(state => state.global.CompareToRender);
  const compareStartDate = useSelector(state => state.global.compareStartDate);
  const compareEndDate = useSelector(state => state.global.compareEndDate);
  const weekId = useSelector(state => state.global.weekId);
  const weekCompareId = useSelector(state => state.global.weekCompareId);
  const isCompare = useSelector(state => state.global.isCompare);
  const [value1, setValue1] = useState(0); // Initialize value state
  const [value2, setValue2] = useState(0); // Initialize value state
  const [value3, setValue3] = useState(0); // Initialize value state
  const [value4, setValue4] = useState(0); // Initialize value state
  const [increase, setIncrease] = useState(null); // Initialize increase state
  const [increase1, setIncrease1] = useState(null); // Initialize increase state

  const { data: customData, isLoading: customDataIsLoading, error: customDataError } = useGetNumberLaunchGameBetweendatesQuery({
    startDate,
    endDate
  });

  const { data: customCompareData, isLoading: customCompareDataIsLoading, error: customCompareDataError } = useGetNumberLaunchGameBetweendatesQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });

	const { data: customDataUnique, isLoading: customDataUniqueIsLoading, error: customDataUniqueError } = useGetPercentageuniqueparticipantsingameQuery({
    startDate,
    endDate
  });

  

  const { data: customCompareDataUnique, isLoading: customCompareDataUniqueIsLoading, error: customCompareDataUniqueError } = useGetPercentageuniqueparticipantsingameQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });

  const { data : weekData , isLoading : weekLoading , refetch: refetchWeekData} = useGETWeeklyDataQuery(weekId ? {weekId : weekId.id} : null);
  const { data : CompareToData , isLoading : CompareToDataIsLoading , refetch: refetchCompareToData} = useGETWeeklyDataQuery(weekCompareId ? {weekId : weekCompareId.id }: null);

  //console.log("CompaaaaaaaaaaaaaaareToDataaaaaaaaaaaa", customCompareDataUnique);
  useEffect(() => {

    let primaryData;
    let comparisonData;
    let primaryDataUnique;
    let comparisonDataUnique;

    switch (dataRender) {
      case 'Custom':
        primaryData = customData ? customData[0] : null;
        comparisonData = isCompare ? CompareToRender === "Custom" ? customCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[28]?.data : null : null;
        primaryDataUnique = customDataUnique ? customDataUnique[0] : null;
        comparisonDataUnique = isCompare ? CompareToRender === "Custom" ? customCompareDataUnique?.[0] : CompareToRender === "Week" ? CompareToData?.[30]?.data : null : null;
        setValue1(primaryData?.totalLaunch);
        setValue2(isCompare ? comparisonData?.totalLaunch : null);
        setValue3(primaryDataUnique?.globalGamePercentage || 0);
        setValue4(isCompare ? comparisonDataUnique?.globalGamePercentage : null);
        break;
      case 'Week':
        primaryData = weekData ? weekData[28]?.data : null;
        comparisonData = isCompare ? CompareToRender === "Custom" ? customCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[28]?.data : null : null;
        primaryDataUnique = weekData ? weekData[30]?.data : null;
        comparisonDataUnique = isCompare ? CompareToRender === "Custom" ? customCompareDataUnique?.[0] : CompareToRender === "Week" ? CompareToData?.[30]?.data : null : null;
        setValue1(primaryData?.totalLaunch);
        setValue2(isCompare ? comparisonData?.totalLaunch : null);
        setValue3(primaryDataUnique?.globalGamePercentage);
        setValue4(isCompare ? comparisonDataUnique?.globalGamePercentage : null);
        break;
      default:
        primaryData = customData ? customData[0] : null;
        comparisonData = isCompare ? CompareToRender === "Custom" ? customCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[28]?.data : null : null;
        primaryDataUnique = customDataUnique ? customDataUnique[0] : null;
        comparisonDataUnique = isCompare ? CompareToRender === "Custom" ? customCompareDataUnique?.[0] : CompareToRender === "Week" ? CompareToData?.[30]?.data : null : null;
        break;
    }

    setIncrease(isCompare && primaryData !== null && comparisonData !== null ? (((primaryData?.totalLaunch - comparisonData?.totalLaunch) / primaryData?.totalLaunch) * 100).toFixed(2) : null); // Update increase state
    setIncrease1(isCompare && primaryDataUnique !== null && comparisonDataUnique !== null ? (((primaryDataUnique?.globalGamePercentage - comparisonDataUnique?.globalGamePercentage) / primaryDataUnique?.globalGamePercentage) * 100).toFixed(2) : null); // Update increase state
  }, [
    startDate, 
    endDate,
    compareStartDate,
    compareEndDate,
    isCompare,
    dataRender,
    CompareToRender,
    weekData,
    CompareToData,   
    customData,
    customDataIsLoading,  
    customDataError,    
    customCompareData, 
    customCompareDataIsLoading, 
    customCompareDataError,
    customDataUnique,
    customDataUniqueIsLoading,
    customDataUniqueError,
    customCompareDataUnique,
    customCompareDataUniqueIsLoading,
    customCompareDataUniqueError,
    weekLoading,
    CompareToDataIsLoading,
  ]);

  useEffect(() => {
    refetchWeekData();
    refetchCompareToData();
  },[weekId,weekCompareId])



  return (
    <Box
      gridColumn="span 2"
      gridRow="span 1"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="0.5rem 1rem 1rem 1rem"
      m="0.05rem 0rem"
      flex="1 1 100%"
      backgroundColor='#F3F6FF'
      borderRadius="0.55rem"
      height="90%"
    >
      <FlexBetween>
      <FlexBetween gap="0.75rem" margin='0.4rem 0.2rem'>
          <img src={GameSVG} alt="Game Insights" width="36px" height="36px" />
          <Typography variant="subtitle1" sx={{ color: theme.palette.secondary[100], fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
            {'Game Insights'}
          </Typography>
      </FlexBetween>
      </FlexBetween>

      <Typography variant="subtitle1" sx={{ color: '#00B185', display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
          {"Number of Launches"}
      </Typography>

      {customDataIsLoading || customCompareDataIsLoading || weekLoading || CompareToDataIsLoading ? (
        <Typography
          variant="h3"
          fontWeight="600"
          sx={{ color: theme.palette.secondary[200] }}
        >
          Loading...
        </Typography>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          p="1rem 0.25rem"
          flex="1 1 100%"
          backgroundColor='#ffffff'
          borderRadius="0.55rem"
          height='20%'

        >
        <FlexBetween 
            gap="1rem"
            >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p="0.25rem"
          >
          <Typography
            variant="h3"
            fontWeight="600"
            sx={{ 
              color: theme.palette.secondary[200],
              fontSize: '1.3rem', 
            }}
          >
            {value1}
          </Typography>
          {isCompare && (
            <FlexBetween
            gap="0.5rem"
          >
            <Typography
              variant="h6"
              fontStyle="italic"
              
              display="flex"
              alignItems="center" // Align items vertically in the center
              sx={{
                color: increase !== null ? (increase < 0 ? theme.palette.error.main : theme.palette.success.main) : theme.palette.secondary.light,
                fontSize: '0.87rem',
              }}
            >
              {increase !== null && (
                <>
                  {increase > 0 ? <TrendingUpIcon sx={{ marginRight: '0.2rem' }} /> : <TrendingDownIcon sx={{ marginRight: '0.2rem' }} />}
                  {`${Math.abs(increase)}` + "%"}
                </>
              )}
            </Typography>
            <Typography
              variant="h6"
              fontWeight="400"
              sx={{
                color: theme.palette.secondary[200],
                fontSize: '0.87rem',
              }}
            >
              {"vs " + value2}
            </Typography>
          </FlexBetween>
          )}
          </Box>
          
          </FlexBetween>
        </Box>
      )}
        <Typography variant="subtitle1" sx={{ color: '#00B185', display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
          {"Participation"}
        </Typography>

      {customDataUniqueIsLoading || customCompareDataUniqueIsLoading || weekLoading || CompareToDataIsLoading ? (
        <Typography
          variant="h3"
          fontWeight="600"
          sx={{ color: theme.palette.secondary[200] }}
        >
          Loading...
        </Typography>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          flex="1 1 100%"
          p="1rem 0.25rem"
          backgroundColor='#ffffff'
          borderRadius="0.55rem"
          height='auto'

        >
        <FlexBetween 
            gap="1rem"
            >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p="0.25rem"
          >
          <Typography
            variant="h3"
            fontWeight="600"
            sx={{ 
              color: theme.palette.secondary[200],
              fontSize: '1.3rem', 
            }}
          >
            {value3 + "%"}
          </Typography>
          {isCompare && (
          <FlexBetween
            gap="0.5rem"
          >
            <Typography
              variant="h6"
              fontStyle="italic"
              display="flex"
              alignItems="center" // Align items vertically in the center
              sx={{
                color: increase !== null ? (increase < 0 ? theme.palette.error.main : theme.palette.success.main) : theme.palette.secondary.light,
                fontSize: '0.87rem',
              }}
            >
              {increase !== null && (
                <>
                  {increase > 0 ? <TrendingUpIcon sx={{ marginRight: '0.2rem' }} /> : <TrendingDownIcon sx={{ marginRight: '0.2rem' }} />}
                  {`${Math.abs(increase1)}` + "%"}
                </>
              )}
            </Typography>
            <Typography
              variant="h6"
              fontWeight="400"
              sx={{
                color: theme.palette.secondary[200],
                fontSize: '0.87rem',
              }}
            >
              {"vs " + value4 + "%"}
            </Typography>
          </FlexBetween>
          )}
          </Box>
          
          </FlexBetween>
        </Box>
      )}
    </Box>
  );
}

export default StatBoxGame;
