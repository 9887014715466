import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";



const storedToken = sessionStorage.getItem('token');

function getWeekAgoDate() {
    const currentDate = new Date();
    const weekAgo = new Date(currentDate);
    weekAgo.setDate(currentDate.getDate() - 7);
    return weekAgo;
}






const initialState = {
    mode: "light",
    userId: null,
    userName: null,
    userEmail: null,
    token: storedToken || null,
    userRole: null,
    userLastName: null, 
    img: null,
    startDate: moment(getWeekAgoDate()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    compareStartDate : moment(new Date()).format("YYYY-MM-DD"),
    compareEndDate : moment(new Date()).format("YYYY-MM-DD"),
    isCompare : false,
    dataRender : "Custom",
    CompareToRender: "Null",    
    weeklyDataState: [],
    compareToData: [],
    dataLoading : false,
    weekId : null,
    weekCompareId : null
};



export let START_DATE_CHANGED = initialState.startDate;
export let  END_DATE_CHANGED = initialState.endDate;

export let COMPARE_START_DATE_CHANGED = initialState.compareStartDate;
export let  COMPARE_END_DATE_CHANGED = initialState.compareEndDate;

export const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        setMode: (state) => {
            state.mode = state.mode === 'light' ? "dark" : 'light';
        },
        signIn: (state,action) => {
            state.token = action.payload;
        },
        logOut: () => initialState,
        setUserInfo: (state, action) => {
            const { userId, userName, userEmail , userLastName , userRole , img } = action.payload;
            state.userId = userId;
            state.userName = userName;
            state.userEmail = userEmail;
            state.userLastName = userLastName;
            state.userRole = userRole;
            state.img = img
        },
        startDateChanged: (state, action) => {
            state.startDate = action.payload;
            START_DATE_CHANGED = action.payload;
        },
        endDateChanged: (state, action) => {
            state.endDate = action.payload;
            END_DATE_CHANGED = action.payload;
        },
        rangeDateChanged: (state, action) => {
            const { formatedStart, formatedEnd } = action.payload;
            state.dataRender = "Custom";
            if (!state.isCompare) {
                state.weeklyDataState = []; 
            }
            state.startDate = formatedStart;
            state.endDate = formatedEnd;
        },
        compareToDateChanged : (state , action) => {
            state.CompareToRender = "Custom";
            state.compareToData = []; 
            const {formatedCompareStart , formatedCompareEnd } = action.payload;
            state.compareStartDate = COMPARE_START_DATE_CHANGED = formatedCompareStart;
            state.compareEndDate = COMPARE_END_DATE_CHANGED = formatedCompareEnd;
        },
        weeklyData: (state, action) => {
            const { weekId} = action.payload;
            state.dataRender = "Week";
            state.weekId = weekId;
        },
        customData: (state, action) => {
            state.dataRender = "Custom";
        },
        setStateIsCompare: (state, action) => {
            const compareState = action.payload && action.payload.compareState;
            if (compareState !== undefined) {
              state.isCompare = compareState;
            } else {
              state.isCompare = !state.isCompare;
            }
        },
        setCompareToData: (state, action) => {
            const {weekCompareId} = action.payload;
            state.CompareToRender = "Week";
            state.weekCompareId = weekCompareId;
        },
        resetweeklyDataState(state , action ){
            //console.log("Hello delete array week");
            return {
                ...state,
                weeklyDataState: [],
                weekId : null
            };
        },
        resetweeklyCompareDataState(state , action ){
            //console.log("Hello delete array Compare week");
            return {
                ...state,
                compareToData: [],
                weekCompareId : null
            };
        },
    }
});

export const { setMode , signIn , logOut , setUserInfo , startDateChanged , endDateChanged , rangeDateChanged , weeklyData , customData , setStateIsCompare  , compareToDateChanged , setCompareToData , resetweeklyDataState , resetweeklyCompareDataState } = globalSlice.actions;


export default globalSlice.reducer;
