import React, { useState, useEffect } from "react";
import { FormControl, MenuItem, InputLabel, Box, Select } from "@mui/material";


const FilterActivities = ({ onChange, defaultValue }) => {
  const [view, setView] = useState(() => defaultValue || "national"); // Set initial state using a function

  useEffect(() => {
    if (defaultValue !== view) {
      setView(defaultValue || "national");
    }
  }, [defaultValue]); // Update view state if defaultValue changes

  const handleChange = (e) => {
    const region = e.target.value;
    setView(region);
    onChange(region); // Trigger the callback function with the selected region
  };

  return (
    <Box>
      <FormControl>
        <InputLabel>View</InputLabel>
        <Select
          value={view}
          label="View"
          onChange={handleChange}
        >
          <MenuItem value="global">All</MenuItem>
          <MenuItem value="national">National</MenuItem>
          <MenuItem value="center">Center</MenuItem>
          <MenuItem value="east">East</MenuItem>
          <MenuItem value="west">West</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default FilterActivities;












