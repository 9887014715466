import { InputAdornment, FormControl, Box, Button, Container, CssBaseline, TextField, Typography } from '@mui/material';
import { LockOutlined, EmailOutlined, Password } from '@mui/icons-material';
import { useTheme, useMediaQuery } from '@mui/material';
import PasswordChecklist from "react-password-checklist"
import InputFieldWezign from "../components/Input";
import { Link } from 'react-router-dom';

import Email from '../assets/email_jti.png'
import Lock from '../assets/lock_jti.png'
import BgSVG from '../assets/backgroundIntro.svg';
import IconSVG from '../assets/Logo_jti.png';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { setUserInfo, signIn } from 'state';
import { useNavigate } from 'react-router-dom';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import FlexBetween from 'components/FlexBetween';

import { useResetPasswordQuery } from "state/api";

function ResetPassword() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [token, setToken] = useState('');
  const [waiting, setIsWaiting] = useState(false);
  const [error, setError] = useState('');
  const [resetEmailChek, setResetEmailChek] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [newPasswordSent, setNewPasswordSent] = useState(false);
  const [password, setPassword] = useState("")
	const [passwordAgain, setPasswordAgain] = useState("")
	const [PasswordValidation, setPasswordValidation] = useState(false)
	const [Pwdtoken, setPwdtoken] = useState(null)
	const [UserEmail, setUserEmail] = useState(null)
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  const signInFunction = useSignIn();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
   /*  const handleSignIn = async () => {
      if (isSignedIn) {
        try {
          await signInFunction({
            auth: {
              token: token.token,
              type: 'Bearer',
            }
          });
          sessionStorage.setItem('token', token.token);
          sessionStorage.setItem('userData', JSON.stringify({
            userId : token.userId,
            userName: token.name,
            userEmail: token.userEmail,
            userRole: token.role,
            userLastName: token.lastName,
            img : token.img,
          }));
          dispatch(signIn(token.token));
          dispatch(
            setUserInfo({
              userId: token.userId,
              userName: token.name,
              userEmail: token.userEmail,
              userRole: token.role,
              userLastName: token.lastName,
              img : token.img,
            })
          );
          navigate('/');
        } catch (error) {
          console.error('Error during sign-in:', error);
        }
      }
    };

    handleSignIn();  */
  }, [isSignedIn, navigate, signInFunction, token, dispatch , emailSent]);

  const Changepassword = async (event) => {
    event.preventDefault();

    setIsWaiting(true);

    const userData = {
      email: document.querySelector('#reset').value,
    };

    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      const response = await axios.post(`${apiUrl}/user/pwdcontinue`, userData, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', // Set the allowed origin for the request
        },
      });
      //console.log(response);
      if (response.status === 201) {
        
          // First setSTate to Enter new password then in the next function if request 200 do : 
          // send new password hash it to db user.password
        // Here we need to sign the user and reroute him to /Dashboard, 
        //const responseData = await response.json();
      } else {
        
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'Unknown error occurred');
    } finally {
      setIsWaiting(false);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if(event.target[0].value === null || event.target[0].value === undefined || event.target[0].value === ""){
      setError("Please provide a valid email address");
      return;
    }
    setError('');

    setIsWaiting(true);



    if( emailSent ) {

      CodeSubmit();
      return;
    }

    const userData = {
      email: document.querySelector('#email').value,
    };

    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      const response = await axios.post(`${apiUrl}/user/resetPassword`, userData, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',  // Set the allowed origin for the request
        },
      });
      //console.log(response);
      if (response.status === 200) {
        
        setResetEmailChek(response.data.member);
        setEmailSent(true);
        //const responseData = await response.json();
      } else {
        
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'Unknown error occurred');
    } finally {
      setIsWaiting(false);
    }
  };

  const CodeSubmit = async (event) => {
    event.preventDefault();
    
    if(event.target[0].value === null || event.target[0].value === undefined || event.target[0].value === ""){
      setError("Please provide a valid code");
      return;
    }
    setError('');

    setIsWaiting(true);
   const userData = {
      code: document.querySelector('#reset').value,
      member : resetEmailChek
    };

    const apiUrl = process.env.REACT_APP_API_URL;
    
    try {
      const response = await axios.post(`${apiUrl}/user/resetPasswordcode`, userData, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', // Set the allowed origin for the request
        },
      });
  
  
      //console.log(response);
      if (response.status === 200) {
        setCodeSent(true);
        setPwdtoken(response.data.resetToken);
        setUserEmail(response.data.email);
        // Handle success case here
      } else {
        // Handle failure case here
        setIsWaiting(false);
        setError(response.message)
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'Unknown error occurred');
    } finally {
      setIsWaiting(false);
    }
    
  }
  
  const PasswordReset = async (event) => {
    
    event.preventDefault();

    const userData = {
      Pwdtoken,
      UserEmail,
      password
    }

    if(!PasswordValidation){
      setError('Please make sure that you password matches our security rules');
      return
    }
    
    setError('');
  
    setIsWaiting(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      const response = await axios.post(`${apiUrl}/user/pwdcontinue`, userData, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', // Set the allowed origin for the request
        },
      });
  
  
      //console.log(response);
      if (response.status === 200) {
        setNewPasswordSent(true);
        // Handle success case here
      } else {
        // Handle failure case here
        setIsWaiting(false);
        setError(response.message)
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'Unknown error occurred');
    } finally {
      setIsWaiting(false);
    }
   
  }
  return (
    <Box className='Intro'  sx={{ display: 'flex', height: '100vh'  }}>
      {/* Left side without background image */}
      <Box 
        sx={{ 
          display: 'flex', 
          flex: 1, 
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent : "space-around",
          padding : "2em 0em 2em 0em",
          userSelect :"none"
        }}>
        <img className='LogoIntro_Wezign'  src={IconSVG} alt="Icon" style={{ width: '100%', height: 'auto' , userSelect:"none" , WebkitUserSelect: "none" , userDrag :"none"}} />
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems : "center",
            userSelect : "none",

          }} >
          <Typography 
            component="h1" 
            variant="h3"
            className='Wlcm_wezign' 
            sx={{ 
              fontSize: 30,
              fontFamily: 'Roboto',
              fontWeight: 'bold',
              marginBottom: '10px', 
              marginTop: '20px'
            }}
          >
            Welcome Back
          </Typography>
          <Typography 
            component="h1" 
            variant="h3"
            className='Wlcm_wezign' 
            sx={{ 
              fontSize: 30,
              fontFamily: 'Roboto',
              fontWeight: 'bold',
              marginBottom: '10px', 
              marginTop: '20px'
            }}
          >
            Password Reset
          </Typography>
          
      {!emailSent && !codeSent && !newPasswordSent && (
      // State: Enter email address
      <Box>
        <Typography 
          component="h1" 
          variant="h3" 
          sx={{ 
            fontSize: 30,
            fontFamily: 'Roboto',
            fontWeight: 'bold',
            color:'#06B085',
            marginBottom: '10px', 
            marginTop: '10px' 
          }}
        >
          Enter your Email Address
        </Typography>
        <form onSubmit={handleSubmit} variant="standard" style={{ width: '100%' }}>
          <Box sx={{ width: '100%', margin: '0 auto' }}>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1em",
              margin: "2em 0em 2em 0em"
            }}>
              <InputFieldWezign type="email" name="email" id="email" img={Email} color={"#06B085"} placeHolder="The Email linked with account"/>
            </Box>
          
            {error && (
              <Box sx={{
                display: "flex",
                justifyContent: "center"
              }}>
                <Typography variant="body2" style={{ color: 'red', marginBottom: '16px' }}>
                  {error}
                </Typography>
              </Box>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                color: 'white',
                backgroundColor: '#06B085',
                borderRadius: '77px',
                marginBottom: '20px',
                width: "17em",
                height: "3em",
                fontSize: "larger",
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                border: '1px solid #06B085',

                '&:hover': {
                  backgroundColor: '#06B085',
                },
              }}
              disabled={waiting}
            >
              {waiting ? 'Please Wait...' : 'Send email'}
            </Button>
          </Box>
        </form>
      </Box>
)}

      {emailSent && !codeSent && !newPasswordSent && (
        // State: Email sent, enter reset code
        <Box>
          <Typography 
            component="h1" 
            variant="h3" 
            sx={{ 
              fontSize: 30,
              fontFamily: 'Roboto',
              fontWeight: 'bold',
              color:'#06B085',
              marginBottom: '10px', 
              marginTop: '10px' 
            }}
          >
            Check your Inbox for verification code
          </Typography>
          <form onSubmit={CodeSubmit} variant="standard" style={{ width: '100%' }}>
            <Box sx={{ width: '100%', margin: '0 auto' }}>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1em",
                margin: "2em 0em 2em 0em"
              }}>
                <InputFieldWezign type="text" name="reset" id="reset" img={Email} color={"#06B085"} placeHolder="cddaa0fe-adc6-45cc-b497-56570c508f1d"/>
              </Box>
            
              {error && (
                <Box sx={{
                  display: "flex",
                  justifyContent: "center"
                }}>
                  <Typography variant="body2" style={{ color: 'red', marginBottom: '16px' }}>
                    {error}
                  </Typography>
                </Box>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: '#06B085',
                  borderRadius: '77px',
                  marginBottom: '20px',
                  width: "17em",
                  height: "3em",
                  fontSize: "larger",
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  border: '1px solid #06B085',

                  '&:hover': {
                    backgroundColor: '#06B085',
                  },
                }}
                disabled={waiting}
              >
                {waiting ? 'Please Wait...' : 'Continue'}
              </Button>
            </Box>
          </form>
        </Box>
      )}

      {emailSent && codeSent && !newPasswordSent && (
        // State: Reset code sent, enter new password
        <Box>
        <Typography 
          component="h1" 
          variant="h3" 
          sx={{ 
            fontSize: 30,
            fontFamily: 'Roboto',
            fontWeight: 'bold',
            color:'#06B085',
            marginBottom: '10px', 
            marginTop: '10px',
            textAlign : "center"
          }}
        >
          Set your new password
        </Typography>
        <form onSubmit={PasswordReset} variant="standard" style={{ width: '100%' }}>
          <Box sx={{ width: '100%', margin: '0 auto' }}>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1em",
              margin: "2em 0em 2em 0em"
            }}>
              <InputFieldWezign type="text" name="password1" id="password1" img={Lock} color={"#06B085"} placeHolder="Enter your new password" onChangeCallback={(r) => {setPassword(r)}}/>
              <InputFieldWezign type="text" name="password2" id="password2" img={Lock} color={"#06B085"} placeHolder="Confirm your new password" onChangeCallback={(r) => {setPasswordAgain(r)}}/>
              <PasswordChecklist
				    rules={["minLength","specialChar","number","capital","match"]}
				    minLength={8}
				    value={password}
				    valueAgain={passwordAgain}
            onChange={(isValid) => {
              setPasswordValidation(isValid)
            }}
				    /* messages={{
					    minLength: "La contraseña tiene más de 8 caracteres.",
					    specialChar: "La contraseña tiene caracteres especiales.",
					    number: "La contraseña tiene un número.",
					    capital: "La contraseña tiene una letra mayúscula.",
					    match: "Las contraseñas coinciden.",
				}} */
			/>
            </Box>
          
            {error && (
              <Box sx={{
                display: "flex",
                justifyContent: "center"
              }}>
                <Typography variant="body2" style={{ color: 'red', marginBottom: '16px' }}>
                  {error}
                </Typography>
              </Box>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                color: 'white',
                backgroundColor: '#06B085',
                borderRadius: '77px',
                marginBottom: '20px',
                width: "17em",
                height: "3em",
                fontSize: "larger",
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                border: '1px solid #06B085',

                '&:hover': {
                  backgroundColor: '#06B085',
                },
              }}
              disabled={waiting}
            >
              {waiting ? 'Please Wait...' : 'Reset Password'}
            </Button>
          </Box>
        </form>
      </Box>
      )}


      {emailSent && codeSent && newPasswordSent &&(
        <Box>
        <Typography 
          component="h1" 
          variant="h3" 
          sx={{ 
            fontSize: 32,
            fontFamily: 'Roboto',
            fontWeight: 'bold',
            color:'#06B085',
            marginBottom: '10px', 
            marginTop: '10px' 
          }}
        >
          Your Password Has Been Reset
        </Typography>
        <form  variant="standard" style={{ width: '100%' }}>
          <Box sx={{ width: '100%', margin: '0 auto' }}>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1em",
              margin: "2em 0em 2em 0em"
            }}>
            </Box>
          
            {error && (
              <Box sx={{
                display: "flex",
                justifyContent: "center"
              }}>
                <Typography variant="body2" style={{ color: 'red', marginBottom: '16px' }}>
                  {error}
                </Typography>
              </Box>
            )}
            
          </Box>
        </form>
      </Box>
      )}
      </Box>
          

      <Box>
      
          <Box sx={{ textAlign: 'center' }}>
            <Typography className='Wlcm_wezign' sx={{
              fontWeight : '500'
            }} >
            Return back to login <Link style={{color :"#06B085" }} to="/auth">Login</Link>
            </Typography>
            
          </Box>
      </Box>
    </Box>
      {/* Right side with background image */}
      <Box 
      className='rightSideLogin'
        sx={{
          flex: '1',
          width: '100%', // Set width to 100% on mobile, otherwise 50%
          height: '100%', // Always take up all the height of the screen
        }}
      >
        <img
          src={BgSVG}
          alt="Background"
          style={{
            width: '100%', // Ensure the image takes up the entire width of its container
            height: '100%', // Ensure the image takes up the entire height of its container
            objectFit: 'cover',
          }}
        />
      </Box>
    </Box>
  ) 

      
}

export default ResetPassword;




