import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme, IconButton, Tooltip} from "@mui/material";
import FlexBetween from './FlexBetween';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ActiveUsersSVG from '../assets/active_users.4a9fceb4b889f56a27805ae5ab559b4e.svg.svg';
import { useSelector } from 'react-redux';
import { useGETWeeklyDataQuery, useGetActivemembersBetweenDatesQuery } from 'state/api';


const StatBoxActive = ({title, help}) => {
  const theme = useTheme();
  const startDate = useSelector(state => state.global.startDate);
  const endDate = useSelector(state => state.global.endDate);
  const dataRender = useSelector(state => state.global.dataRender);
  const CompareToRender = useSelector(state => state.global.CompareToRender);
  const compareStartDate = useSelector(state => state.global.compareStartDate);
  const compareEndDate = useSelector(state => state.global.compareEndDate);
  const weekId = useSelector(state => state.global.weekId);
  const weekCompareId = useSelector(state => state.global.weekCompareId);
  const isCompare = useSelector(state => state.global.isCompare);
  const [showHelp, setShowHelp] = useState(false);
  const [value1, setValue1] = useState(0); // Initialize value state
  const [value2, setValue2] = useState(0); // Initialize value state
  const [increase, setIncrease] = useState(null); // Initialize increase state

  //console.log(weekId?.id, "StatBOX");
  //console.log(weekCompareId?.id, "StatBOX");

  const { data: customData, isLoading: customDataIsLoading, error: customDataError } = useGetActivemembersBetweenDatesQuery({
    startDate,
    endDate
  });

  const { data: customCompareData, isLoading: customCompareDataIsLoading, error: customCompareDataError } = useGetActivemembersBetweenDatesQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });

  const { data : weekData , isLoading : weekLoading , refetch: refetchWeekData} = useGETWeeklyDataQuery(weekId ? {weekId : weekId.id} : null);
  const { data : CompareToData , isLoading : CompareToDataIsLoading , refetch: refetchCompareToData} = useGETWeeklyDataQuery(weekCompareId ? {weekId : weekCompareId.id }: null);

  //console.log(weekData, "WEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEK");
  //console.log(CompareToData, "WEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEK");



  useEffect(() => {

    let primaryData;
    let comparisonData;

    switch (dataRender) {
      case 'Custom':
        primaryData = customData ? customData[0] : null;
        comparisonData = isCompare ? CompareToRender === "Custom" ? customCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[1]?.data : null : null;
        setValue1(primaryData?.globalActivePercentage);
        setValue2(isCompare ? comparisonData?.globalActivePercentage : null);
        break;
      case 'Week':
        primaryData = weekData ? weekData[1]?.data : null;
        comparisonData = isCompare ? CompareToRender === "Custom" ? customCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[1]?.data : null : null;
        setValue1(primaryData?.globalActivePercentage);
        setValue2(isCompare ? comparisonData?.globalActivePercentage : null);
        break;
      default:
        primaryData = customData ? customData[0] : null;
        comparisonData = isCompare ? CompareToRender === "Custom" ? customCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[1]?.data : null : null;
        break;
    }

    setIncrease(isCompare && primaryData !== null && comparisonData !== null ? (primaryData?.globalActivePercentage - comparisonData?.globalActivePercentage).toFixed(2) : null); // Update increase state
  }, [
    startDate, 
    endDate,
    compareStartDate,
    compareEndDate,
    isCompare,
    dataRender,
    CompareToRender,
    weekData,
    CompareToData,   
    customData,
    customDataIsLoading,  
    customDataError,    
    customCompareData, 
    customCompareDataIsLoading, 
    customCompareDataError,
    weekLoading,
    CompareToDataIsLoading,
  ]);

  useEffect(() => {
    refetchWeekData();
    refetchCompareToData();
  },[weekId,weekCompareId])

  
  return (
    <Box
      gridColumn="span 1"
      gridRow="span 1"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor='#FFFFFF'
      borderRadius="0.55rem"
    >
      <FlexBetween margin='0rem 0rem 0.75rem 0rem'>
        <FlexBetween gap="0.75rem">
          <img src={ActiveUsersSVG} alt="Active Users" width="36" height="36" />
          <Typography variant="subtitle1" sx={{ color: theme.palette.secondary[100], fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
            {title}
          </Typography>
        </FlexBetween>
        <Tooltip title={help} arrow>
          <IconButton
            size="small"
            onMouseEnter={() => setShowHelp(true)}
            onMouseLeave={() => setShowHelp(false)}
          >
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </FlexBetween>

      {customDataIsLoading || customCompareDataIsLoading || weekLoading || CompareToDataIsLoading  ? (
        <Typography
          variant="h3"
          fontWeight="600"
          sx={{ color: theme.palette.secondary[200] }}
        >
          Loading...
        </Typography>
      ) : (
        <>
        <FlexBetween 
            gap="1rem"
            >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p="0.25rem"
          >
          <Typography
            variant="h3"
            fontWeight="600"
            sx={{ 
              color: theme.palette.secondary[200],
              fontSize: '1.3rem', 
            }}
          >
            {value1 + " %"}
          </Typography>
          {isCompare && (
          <FlexBetween
            gap="0.5rem"
          >
            <Typography
              variant="h6"
              fontStyle="italic"
              
              display="flex"
              alignItems="center" // Align items vertically in the center
              sx={{
                color: increase !== null ? (increase < 0 ? theme.palette.error.main : theme.palette.success.main) : theme.palette.secondary.light,
                fontSize: '0.87rem',
              }}
            >
              {increase !== null && (
                <>
                  {increase > 0 ? <TrendingUpIcon sx={{ marginRight: '0.2rem' }} /> : <TrendingDownIcon sx={{ marginRight: '0.2rem' }} />}
                  {`${Math.abs(increase)} %`}
                </>
              )}
            </Typography>
            <Typography
              variant="h6"
              fontWeight="400"
              sx={{
                color: theme.palette.secondary[200],
                fontSize: '0.87rem',
              }}
            >
              vs {value2} %
            </Typography>
          </FlexBetween>
          )}

          </Box>
          
        </FlexBetween>
        </>
      )}
    </Box>
  );
}

export default StatBoxActive;
