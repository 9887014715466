import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { configureStore } from '@reduxjs/toolkit';
import globalReducer from 'state';
import { Provider } from 'react-redux';
import createStore from 'react-auth-kit/createStore';
import AuthProvider from 'react-auth-kit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from 'state/api';

const Authstore = createStore({
  authName: '_auth',
  authType: 'cookie',
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === 'https:',
});

const store = configureStore({
  reducer: {
    global: globalReducer,
    [api.reducerPath] : api.reducer,
  },
  middleware : (getDefault) => getDefault().concat(api.middleware)
});
setupListeners(store.dispatch);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider store={Authstore}>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthProvider>
  </React.StrictMode>
);
