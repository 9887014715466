import React, { useState ,useEffect , useRef } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Switch from '@mui/material/Switch';
import { useDispatch } from 'react-redux';
import { DateRangePicker , DefinedRange , DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import moment from "moment";
import { useSelector } from "react-redux";
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { setStateIsCompare , rangeDateChanged , weeklyData , customData , compareToDateChanged , setCompareToData , resetweeklyDataState , resetweeklyCompareDataState } from '../state';
import {  useGetWeeksListQuery , useGETWeeklyDataQuery} from 'state/api';
import TimeComponentSelect from './TimeComponentSelect';

function getWeekAgoDate() {
  const currentDate = new Date();
  const weekAgo = new Date(currentDate);
  weekAgo.setDate(currentDate.getDate() - 7);
  return weekAgo;
}

const theme = createTheme({
  palette: {
    customColor: {
      main: '#ABAFBA',
    },
    customColorSecondary: {
      main: '#0090FF',
    },
  },
});

const waitTwoSeconds = () => {
  return new Promise(resolve => {
      setTimeout(() => {
          resolve();
      }, 4000);
  });
};

// Calculate the current date
const currentDate = new Date();

currentDate.setHours(0, 0, 0, 0); // Set time to midnight to include the entire day
// Generate an array of dates from today onwards
const disabledDates = [];
for (let i = 1; i < 365; i++) { // You can adjust the number of days as needed
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() + i);
    disabledDates.push(date);
}

// Calculate the max date (20 years after the current date)
const maxDate = new Date(currentDate);
maxDate.setFullYear(maxDate.getFullYear() + 1);

// Calculate the min date (100 years before the current date)
const minDate = new Date(currentDate);
minDate.setFullYear(minDate.getFullYear() - 5);


const renderStaticRangeLabel = (week) => {
    return `${moment(week.Days[0]).format("MMM Do YY")} - ${moment(week.Days[6]).format("MMM Do YY")}`;
  };

const NewTimeComponent = ({isExtraSmallScreens , isSmallScreens , isMediumScreens}) => {
    const dispatch = useDispatch();
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [isCompare , setIsCompare] = useState(useSelector((state) => state.global.isCompare));
    const [focusedRange, setFocusedRange] = useState([0, 0]);
    const [selectedWeek, setSelectedWeek] = useState(null); // State for selected week
    const [selectedCompareWeek, setSelectedCompareWeek] = useState(null); // State for selected week
    const [compareSelectedWeek, setCompareSelectedWeek] = useState(null); // State for selected week
    const [applyDisabled , setApplyDisabled] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const timeComponentRef = useRef(null);
    const [isLoadingButton,setIsLoadingButton] = useState(false);
    const [state, setState] = useState({
        selection1: {
          startDate: getWeekAgoDate(),
          endDate: addDays(new Date(), 0),
          key: 'selection1'
        },
        selection2: {
          startDate: addDays(new Date(), 0),
          endDate: addDays(new Date(), 0),
          key: 'selection2'
        }
      });


      //console.log(selectedWeek, "Weeeeeek");
      /*console.log(useSelector(state => state.global));*/


      const RenderData = useSelector(state => state.global.dataRender);
     

      //console.log(RenderData , "Compare To :" , selectedCompareWeek);

      const { data : weeksList, isLoading: weeksListIsLoading ,  refetch: refetchWeeksList } = useGetWeeksListQuery();
      
      
      useEffect(() => {
        const handleClickOutside = (event) => {
          if (timeComponentRef.current && !timeComponentRef.current.contains(event.target)) {
              setShowDatePicker(false);
          }
      };
     
      // Add event listener when the component mounts
      document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
      }, []) 


      useEffect(() => {
        // Change focused range when isCompare changes
        if (isCompare) {
            setFocusedRange([1, 0]); // Focus on the second range
        } else {
            setFocusedRange([0, 0]); // Focus on the first range
        }
    }, [isCompare]); // Run when isCompare changes

    
   /* useEffect(() => {
      if(weekCompareData){
        dispatch(
          setCompareToData(weekCompareData)
        );
      }
      if(weekData){
        dispatch(
          weeklyData(weekData)
        );
      }
    }, [weekCompareData, weekData]);*/
    
    
    
      const toggleDatePicker = () => {
        setShowDatePicker(!showDatePicker);
      };  

      const resetState = () => {
        setIsCompare(false);
        dispatch(setStateIsCompare({compareState : false}));
        setSelectedWeek(null);
        setSelectedCompareWeek(null);
        setCompareSelectedWeek(null);
        setState({
          selection1: {
            startDate: getWeekAgoDate(),
            endDate: addDays(new Date(), 0),
            key: 'selection1'
          },
          selection2: {
            startDate: addDays(new Date(), 0),
            endDate: addDays(new Date(), 0),
            key: 'selection2'
          }
        })
        
        dispatch(
          rangeDateChanged({formatedStart : formatDate(state.selection1.startDate) , formatedEnd : formatDate(state.selection1.endDate)  })
        )
        dispatch(
          compareToDateChanged({formatedCompareStart : formatDate(state.selection2.startDate) , formatedCompareEnd : formatDate(state.selection2.endDate)  })
        )
        //toggleDatePicker();
      };

      const formatDate = (date) => {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        return formattedDate;
      };


      const applyChanges = async  () => {
        setIsLoadingButton(true);
        waitTwoSeconds().then(() => {
          setApplyDisabled(true)
          // Your code to execute after 2 seconds
          dispatch(setStateIsCompare({compareState : isCompare}));
 
      

        switch (isCompare) {
            case false:
                if (!state.selection1.startDate || !state.selection1.endDate)
              return 
            if (!selectedWeek){
              
            const formatedStart = formatDate(state.selection1.startDate);
            const formatedEnd = formatDate(state.selection1.endDate);
            dispatch(customData()) 
            dispatch(
              rangeDateChanged({formatedStart , formatedEnd  })
            )
            }
            if(selectedWeek) {
              dispatch(
                weeklyData({weekId : selectedWeek})
              )
              //Here
              /*setIsLoadingButton(true);
              setTimeout(() => {
                dispatch(weeklyData({weekData , weekLoading}));
                setIsLoadingButton(false);
            }, 7000); */
                
            }
            if(compareSelectedWeek != null ){
              dispatch(
                setCompareToData({weekCompareId : selectedCompareWeek})
              )
              /*setIsLoadingButton(true);
              setTimeout(() => {
                dispatch(setCompareToData(weekCompareData));
                setIsLoadingButton(false);
            }, 7000);*/
              
            }
              
                break;
            case true:

            if ((selectedWeek && selectedWeek != null) && (compareSelectedWeek && compareSelectedWeek != null)) {
              dispatch(setCompareToData({ weekCompareId: compareSelectedWeek }));
              dispatch(weeklyData({ weekId: selectedWeek }));
          } else if ((!selectedWeek || selectedWeek == null || selectedWeek == undefined) && (!compareSelectedWeek || compareSelectedWeek == null) && state.selection2.startDate && state.selection2.endDate) {
              //console.log('Compare Custom vs Custom');
              const formatedStart = formatDate(state.selection1.startDate);
              const formatedEnd = formatDate(state.selection1.endDate);
              dispatch(rangeDateChanged({ formatedStart, formatedEnd }));
              dispatch(compareToDateChanged({ formatedCompareStart: formatDate(state.selection2.startDate), formatedCompareEnd: formatDate(state.selection2.endDate) }));
              dispatch(resetweeklyCompareDataState());
              dispatch(resetweeklyDataState());
          } else if ((selectedWeek && selectedWeek != null && selectedWeek != undefined) && state.selection2.startDate && state.selection2.endDate) {
              dispatch(weeklyData({ weekId: selectedWeek }));
              dispatch(compareToDateChanged({ formatedCompareStart: formatDate(state.selection2.startDate), formatedCompareEnd: formatDate(state.selection2.endDate) }));
          } else if ((compareSelectedWeek && compareSelectedWeek != null) && state.selection1.startDate && state.selection1.endDate) {
              //console.log("We are Here!!!");
              dispatch(rangeDateChanged({ formatedStart: formatDate(formatDate(state.selection1.startDate)), formatedEnd: formatDate(formatDate(state.selection1.endDate)) }));
              dispatch(setCompareToData({ weekCompareId: selectedCompareWeek }));
          }

                
                
                /*else if(RenderData === "Custom" && CompareToRender === "Week"){
                  console.log("Compare Week vs Custom");
                 
                  refetchWeekData();
                  dispatch(
                    compareToDateChanged({formatedCompareStart : formatDate(state.selection2.startDate) , formatedCompareEnd : formatDate(state.selection2.endDate)  })
                  )
                } else if(RenderData === "Week" && CompareToRender === "Week"){
                  
                 console.log("Week VS Week");
                 refetchWeekData();
                 refetchCompareWeekData();
                  /* WEEK - WEEK GREEN 
                }



                  if(!weekLoading && isCompare) {
          dispatch(weeklyData({weekData}));
        }

        if(!weekCompareLoading && isCompare){
          dispatch(setCompareToData({weekCompareData}))
        }

        applyChanges();



                 /* CUSTOM  - WEEK GREEN*/
                
                break;
        
            default:
                break;
        }

        }).finally(() => {
          setApplyDisabled(false);
          setIsLoadingButton(false);
          toggleDatePicker();
        });
        
      }

      const handleChangeSwitch = () => {
        if(isCompare){
          setIsCompare(false);
        }else{
          setIsCompare(true);
        }
      };

      const customRanges = weeksList?.map((week) => ({
        startDate: new Date(week.Days[0]),
        endDate: new Date(week.Days[6]),
        key: week.id,
      })) || [];

      const handleSelect = (item) => {
        
        if (item.selection1 && item.selection1.startDate) {
          setSelectedWeek(null);
            setState({
                ...state,
                selection1: {
                    ...state.selection1,
                    startDate: item.selection1.startDate,
                    endDate: item.selection1.endDate,
                    key: 'selection1'
                }
            });
        }
    
        if (item.selection2 && item.selection2.startDate && isCompare) {
          setCompareSelectedWeek(null)
            setState({
                ...state,
                selection2: {
                    ...state.selection2,
                    startDate: item.selection2.startDate,
                    endDate: item.selection2.endDate,
                    key: 'selection2'
                }
            });
        }

    
    };

    const handleWeekSelect = (event) => {
      // Extract the selected value from the event
      const selectedValue = event.target.value;
      // Find the week object corresponding to the selected value
      const selectedWeekObject = weeksList.find(week => week.id === selectedValue);
      // Set the selected week state

      if(selectedWeekObject != null){
        setSelectedWeek(selectedWeekObject);
      setState({
          ...state,
          selection1: {
              ...state.selection1,
              startDate:  new Date(selectedWeekObject.Days[0] ),
              endDate: new Date(selectedWeekObject.Days[6] ),
              key: 'selection1'
          }
      });
      }else if(selectedWeekObject == null){
        resetState();
      }
      
    };

    const handleCompareWeekSelect = (event) => {
      const selectedValue = event.target.value;
      // Find the week object corresponding to the selected value
      const selectedCompareWeekObject = weeksList.find(week => week.id === selectedValue);
      //console.log(selectedCompareWeekObject , "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
      //console.log(compareSelectedWeek , "bbbbbbbbbbbbbbbbbbbbbbbbbbbbb");
      // Toggle selection: if the clicked week is already selected, deselect it; otherwise, select it
      if (selectedCompareWeekObject != null) {
        setCompareSelectedWeek(selectedCompareWeekObject);
        setState({
          ...state,
          selection2: {
            ...state.selection2,
            startDate: new Date(selectedCompareWeekObject.Days[0]),
            endDate: new Date(selectedCompareWeekObject.Days[6]),
            key: 'selection2'
          }
        });
      } else {
        setCompareSelectedWeek(null);
        setState({
          ...state,
          selection2: {
            ...state.selection2,
            startDate: addDays(new Date(), 0),
            endDate: addDays(new Date(), 0),
            key: 'selection2'
          }
        });
      }
    };




  return (
    <Box zIndex={50}>
    <Box display="flex" alignContent="center" gap="0.5em">
      <Box  position="relative" className="timeComponent" onClick={toggleDatePicker}>
      {state.selection1.startDate && state.selection1.endDate ? (
  <Box sx={{
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: ".5em",
    backgroundColor: "#FFFFFF",
    border: "1px solid #239DF9",
    p: ".7em",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "center",
  }}>
    <span style={{ fontSize: "1em" }}>
      {moment(state.selection1.startDate).format("MMM Do YY")}
    </span>
    <ArrowForwardIcon sx={{ fontSize: "1em" }} />
    <span style={{ fontSize: "1em" }}>
      {moment(state.selection1.endDate).format("MMM Do YY")}
    </span>
    <CalendarMonthOutlinedIcon />
  </Box>
) : state.selection1.startDate ? (
  <Box sx={{
    backgroundColor: "#FFFFFF",
    border: "1px solid #239DF9",
    p: ".7em",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}>
    <span style={{ fontSize: "1em" }}>
      {`${moment(state.selection1.startDate).format("MMM Do YY")}`}
    </span>
    <ArrowForwardIcon sx={{ fontSize: "1em" }} />
   
  </Box>
) : (
  <Box sx={{
    backgroundColor: "#FFFFFF",
    border: "1px solid #239DF9",
    p: ".7em",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}>
    Please Select a date range to Continue
  </Box>
  
)}
        
        { showDatePicker && (<Box ref={timeComponentRef} position="absolute" top="2em" right="-5em" backgroundColor="#FFFFFF" padding=".5em" borderRadius="5px" display="flex"  onClick={(e) => e.stopPropagation()} >
        
        <Box >
        <Box  flexDirection={isExtraSmallScreens || isSmallScreens ? "column" : isMediumScreens ? "row" : "row"} sx={{display :" flex",  flexWrap:"nowrap", justifyContent :'center'  , textAlign :"center"}} >
          
        <DateRange 
        onChange={item => handleSelect(item)}
        ranges={isCompare ? [state.selection1, state.selection2] : [state.selection1]}
        disabledDates={disabledDates}
        dragSelectionEnabled={false}
        initialFocusedRange={focusedRange}
        maxDate={maxDate}
        minDate={minDate}      
        isCompare={isCompare}                   
        />
        <Box>
          
          <TimeComponentSelect
        weeks={weeksList} // Assuming weeksList is defined in your parent component
        selectedWeek={selectedWeek}
        setSelectedWeek={setSelectedWeek}
        handleWeekSelect={handleWeekSelect} // Pass the handleWeekSelect function here
        handleChangeSwitch={handleChangeSwitch}
        isCompare={isCompare}
        compareSelectedWeek={compareSelectedWeek}
        handleCompareWeekSelect={handleCompareWeekSelect}
      />
        </Box>
        
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' , flexDirection :"column" }} >
        <Box sx={{display : 'inline-block', width :"100%" , height : ".05em" , backgroundColor : "#ABAFBA", margin :'1em 0em 1em 0em'}} ></Box>
        <Box sx={{display :"flex", flexDirection :"row" , justifyContent :"flex-end"}} >
        <ThemeProvider theme={theme}>
  <Stack spacing={2} direction="row">
    <Button variant="outlined" onClick={toggleDatePicker} color="customColor">Cancel</Button>
    {isLoadingButton ? <Button variant="contained" disabled={isLoadingButton}  color="customColorSecondary">Loading...</Button> : <Button variant="contained" disabled={applyDisabled}  onClick={applyChanges} color="customColorSecondary">Apply</Button>}  
    <Button variant="outlined" onClick={resetState} color="customColor">Reset</Button>
  </Stack>
</ThemeProvider>
        </Box>
        </Box>
        </Box>
        
        
    </Box>)}</Box></Box>
        </Box>
  );
};

export default NewTimeComponent;


/*
<Stack direction="row" spacing={2} display="flex" flexDirection="column">
                                <Box>Business Dates</Box>
                                {weeksList && weeksList.map((week) => (
                                    <Box key={week.id} onClick={() => handleWeekSelect(week)}>
                                        <Button
                                            sx={{
                                                width : "100%",
                                                color:selectedWeek && selectedWeek.id === week.id ? "#FFFFFF" : "#3D91FF",
                                                borderColor: selectedWeek && selectedWeek.id === week.id ? "#3D91FF" : "#3D91FF",
                                                backgroundColor: selectedWeek && selectedWeek.id === week.id ? "#3D91FF" : "transparent",
                                                '&:hover': {
                                                    backgroundColor: selectedWeek && selectedWeek.id === week.id ? "transparent" : selectedWeek ? "#3D91FF" : "transparent",
                                                    color:selectedWeek && selectedWeek.id === week.id ? "#3D91FF" : selectedWeek ? "#FFFFFF" : "#3D91FF",
                                                }
                                            }}
                                            variant="outlined">
                                            {week.id}
                                        </Button>
                                    </Box>
                                ))}
                            </Stack>
                             */




                            /*
                            {isCompare && <Box maxHeight="32em" minHeight="10em" overflow="scroll"
    overflow-x="auto" margin="0" padding="0" sx={{
      '&::-webkit-scrollbar': {
        width: '0 !important', // hide horizontal scrollbar
        height: '0 !important', // hide vertical scrollbar
      }}}>
        <Stack direction="row" spacing={2} display="flex" flexDirection="column" marginRight={2}>
                                <Box>Compare To :</Box>
                                {weeksList && weeksList.map((week) => (
                                    <Box key={week.id} onClick={() => handleCompareWeekSelect(week)}>
                                        <Button
                                            sx={{
                                                width : "100%",
                                                color:compareSelectedWeek && compareSelectedWeek.id === week.id ? "#FFFFFF" : "#3ECF8E",
                                                borderColor: compareSelectedWeek && compareSelectedWeek.id === week.id ? "#3ECF8E" : "#3ECF8E",
                                                backgroundColor: compareSelectedWeek && compareSelectedWeek.id === week.id ? "#3ECF8E" : "transparent",
                                                '&:hover': {
                                                    backgroundColor: compareSelectedWeek && compareSelectedWeek.id === week.id ? "transparent" : compareSelectedWeek ? "#3ECF8E" : "transparent",
                                                    color:compareSelectedWeek && compareSelectedWeek.id === week.id ? "#3ECF8E" : compareSelectedWeek ? "#FFFFFF" : "#3ECF8E",
                                                }
                                            }}
                                            variant="outlined">
                                            {week.id}
                                        </Button>
                                    </Box>
                                ))}
                            </Stack>
        </Box>}
                            
                            
                            */