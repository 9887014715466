import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import {
  useGetRegionPercentageParticipationActivitiesQuery,
  useGetGlobalPercentageParticipationActivitiesQuery,
  useGETWeeklyDataQuery
} from "state/api";

const GlobalActivitiesBarChart = ({ height }) => {
  const startDate = useSelector(state => state.global.startDate);
  const endDate = useSelector(state => state.global.endDate);
  const dataRender = useSelector(state => state.global.dataRender);
  const CompareToRender = useSelector(state => state.global.CompareToRender);
  const compareStartDate = useSelector(state => state.global.compareStartDate);
  const compareEndDate = useSelector(state => state.global.compareEndDate);
  const weekId = useSelector(state => state.global.weekId);
  const weekCompareId = useSelector(state => state.global.weekCompareId);
  const isCompare = useSelector(state => state.global.isCompare);
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  const { data: regionData, isLoading: regionDataIsLoading, error: regionDataError } = useGetRegionPercentageParticipationActivitiesQuery({
    startDate,
    endDate,
  });

  const { data: globalData, isLoading: globalDataIsLoading, error: globalDataError } = useGetGlobalPercentageParticipationActivitiesQuery({
    startDate,
    endDate
  });

  const { data: globalCompareData, isLoading: globalCompareDataIsLoading, error: globalCompareDataError } = useGetGlobalPercentageParticipationActivitiesQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });

  const { data: regionCompareData, isLoading: regionCompareDataIsLoading, error: regionCompareDataError } = useGetRegionPercentageParticipationActivitiesQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });

  const { data: weekData, isLoading: weekLoading, refetch: refetchWeekData } = useGETWeeklyDataQuery(weekId ? { weekId: weekId.id } : null);
  const { data: CompareToData, isLoading: CompareToDataIsLoading, refetch: refetchCompareToData } = useGETWeeklyDataQuery(weekCompareId ? { weekId: weekCompareId.id } : null);

  useEffect(() => {

    let primaryChartNationalData;
    let primaryChartCenterData;
    let primaryChartEastData;
    let primaryChartWestData;
    let comparisonChartNationalData;
    let comparisonChartCenterData;
    let comparisonChartEastData;
    let comparisonChartWestData;
    switch (dataRender) {
      case 'Custom':
        primaryChartNationalData = globalData ? globalData[0] : null;
        comparisonChartNationalData = isCompare ? CompareToRender === "Custom" ? globalCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[17]?.data : null : null;
        primaryChartCenterData = regionData ? regionData[0]?.regions?.[0] : null;
        comparisonChartCenterData = isCompare ? CompareToRender === "Custom" ? regionCompareData[0]?.regions?.[0] : CompareToRender === "Week" ? CompareToData?.[18]?.data?.regions[0] : null : null;
        primaryChartEastData = regionData ? regionData[0]?.regions?.[1] : null;
        comparisonChartEastData = isCompare ? CompareToRender === "Custom" ? regionCompareData[0]?.regions?.[1] : CompareToRender === "Week" ? CompareToData?.[18]?.data?.regions[1] : null : null;
        primaryChartWestData = regionData ? regionData[0]?.regions?.[2] : null;
        comparisonChartWestData = isCompare ? CompareToRender === "Custom" ? regionCompareData[0]?.regions?.[2] : CompareToRender === "Week" ? CompareToData?.[18]?.data?.regions[2] : null : null;
        break;
      case 'Week':
        primaryChartNationalData = weekData ? weekData[17]?.data : null;
        comparisonChartNationalData = isCompare ? CompareToRender === "Custom" ? globalCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[17]?.data : null : null;
        primaryChartCenterData = weekData ? weekData[18]?.data?.regions[0] : null;
        comparisonChartCenterData = isCompare ? CompareToRender === "Custom" ? regionCompareData[0]?.regions?.[0] : CompareToRender === "Week" ? CompareToData?.[18]?.data?.regions[0] : null : null;
        primaryChartEastData = weekData ? weekData[18]?.data?.regions[1] : null;
        comparisonChartEastData = isCompare ? CompareToRender === "Custom" ? regionCompareData[0]?.regions?.[1] : CompareToRender === "Week" ? CompareToData?.[18]?.data?.regions[1] : null : null;
        primaryChartWestData = weekData ? weekData[18]?.data?.regions[2] : null;
        comparisonChartWestData = isCompare ? CompareToRender === "Custom" ? regionCompareData[0]?.regions?.[2] : CompareToRender === "Week" ? CompareToData?.[18]?.data?.regions[2] : null : null;
        break;
      default:
        primaryChartNationalData = globalData ? globalData[0] : null;
        comparisonChartNationalData = isCompare ? CompareToRender === "Custom" ? globalCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[17]?.data : null : null;
        primaryChartCenterData = regionData ? regionData[0]?.regions?.[0] : null;
        comparisonChartCenterData = isCompare ? CompareToRender === "Custom" ? regionCompareData[0]?.regions?.[0] : CompareToRender === "Week" ? CompareToData?.[18]?.data?.regions[0] : null : null;
        primaryChartEastData = regionData ? regionData[0]?.regions?.[1] : null;
        comparisonChartEastData = isCompare ? CompareToRender === "Custom" ? regionCompareData[0]?.regions?.[1] : CompareToRender === "Week" ? CompareToData?.[18]?.data?.regions[1] : null : null;
        primaryChartWestData = regionData ? regionData[0]?.regions?.[2] : null;
        comparisonChartWestData = isCompare ? CompareToRender === "Custom" ? regionCompareData[0]?.regions?.[2] : CompareToRender === "Week" ? CompareToData?.[18]?.data?.regions[2] : null : null;
    }

    const baseOptions = {
      chart: {
        type: 'bar',
        id: "basic-bar",
        height: 350
      },
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      grid: {
        padding: {
          top: isCompare ? 30 : 13,
          bottom: 0,
          right: 5,
          left: 5,
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " %"
          }
        }
      },
      legend: {
        position: 'top',
        floating: true,
        horizontalAlign: 'center',
      },
    };

    const isCompareOptions = {
      ...baseOptions,
      xaxis: {
        categories: primaryChartNationalData?.activityTypes || [],
      },
      yaxis: {
        title: {
          text: "Percentage",
        },
        min: 0,
        max: 100,
      },
    };

    const isNotCompareOptions = {
      ...baseOptions,
      xaxis: {
        categories: primaryChartNationalData?.activityTypes || [],
      },
      yaxis: {
        title: {
          text: "Percentage",
        },
        min: 0,
        max: 100,
      },
    };

    setOptions(isCompare ? isCompareOptions : isNotCompareOptions);

    let primaryChartDataNationalPercentages = [];
    let primaryChartDataCenterPercentages = [];
    let primaryChartDataEastPercentages = [];
    let primaryChartDataWestPercentages = [];

    if (primaryChartNationalData?.percentages.length === 1) {
      primaryChartDataNationalPercentages = primaryChartNationalData?.percentages[0];
    } else {
      primaryChartDataNationalPercentages = primaryChartNationalData?.percentages;
    }

    if (primaryChartCenterData?.percentages.length === 1) {
      primaryChartDataCenterPercentages = primaryChartCenterData?.percentages[0];
    } else {
      primaryChartDataCenterPercentages = primaryChartCenterData?.percentages;
    }

    if (primaryChartEastData?.percentages.length === 1) {
      primaryChartDataEastPercentages = primaryChartEastData?.percentages[0];
    } else {
      primaryChartDataEastPercentages = primaryChartEastData?.percentages;
    }

    if (primaryChartWestData?.percentages.length === 1) {
      primaryChartDataWestPercentages = primaryChartWestData?.percentages[0];
    } else {
      primaryChartDataWestPercentages = primaryChartWestData?.percentages;
    }

    let comparisonChartDataNationalPercentages = [];
    let comparisonChartDataCenterPercentages = [];
    let comparisonChartDataEastPercentages = [];
    let comparisonChartDataWestPercentages = [];
    if (comparisonChartNationalData?.percentages.length === 1) {
      comparisonChartDataNationalPercentages = comparisonChartNationalData?.percentages[0];
    } else {
      comparisonChartDataNationalPercentages = comparisonChartNationalData?.percentages;
    }
    if (comparisonChartCenterData?.percentages.length === 1) {
      comparisonChartDataCenterPercentages = comparisonChartCenterData?.percentages[0];
    } else {
      comparisonChartDataCenterPercentages = comparisonChartCenterData?.percentages;
    }
    if (comparisonChartEastData?.percentages.length === 1) {
      comparisonChartDataEastPercentages = comparisonChartEastData?.percentages[0];
    } else {
      comparisonChartDataEastPercentages = comparisonChartEastData?.percentages;
    }
    if (comparisonChartWestData?.percentages.length === 1) {
      comparisonChartDataWestPercentages = comparisonChartWestData?.percentages[0];
    } else {
      comparisonChartDataWestPercentages = comparisonChartWestData?.percentages;
    }

    const primarySeries = [
      { name: "National", data: primaryChartDataNationalPercentages || [], color: "#00E396" },
      { name: "Center", data: primaryChartDataCenterPercentages || [], color: "#FFBB00" },
      { name: "East", data: primaryChartDataEastPercentages || [], color: "#071076" },
      { name: "West", data: primaryChartDataWestPercentages || [], color: "#BD66A1" },
    ];

    const comparisonSeries = [
      { name: "Selected Data: National", data: primaryChartDataNationalPercentages || [], color: "#00E396" },
      { name: "Selected Data: Center", data: primaryChartDataCenterPercentages || [], color: "#FFBB00" },
      { name: "Selected Data: East", data: primaryChartDataEastPercentages || [], color: "#071076" },
      { name: "Selected Data: West", data: primaryChartDataWestPercentages || [], color: "#BD66A1" },
      { name: "Compared Data: National", data: comparisonChartDataNationalPercentages || [], color: "#008FFB" },
      { name: "Compared Data: Center", data: comparisonChartDataCenterPercentages || [], color: "#B06500" },
      { name: "Compared Data: East", data: comparisonChartDataEastPercentages || [], color: "#6671BD" },
      { name: "Compared Data: West", data: comparisonChartDataWestPercentages || [], color: "#AF66BD" },
    ];

    setSeries(isCompare ? comparisonSeries : primarySeries);

  }, [
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    isCompare,
    dataRender,
    CompareToRender,
    weekData,
    CompareToData,
    weekLoading,
    CompareToDataIsLoading,
    regionData,
    regionDataIsLoading,
    regionDataError,
    regionCompareData,
    regionCompareDataIsLoading,
    regionCompareDataError,
    globalData,
    globalDataIsLoading,
    globalDataError,
    globalCompareData,
    globalCompareDataIsLoading,
    globalCompareDataError,
  ]);

  useEffect(() => {
    refetchWeekData();
    refetchCompareToData();
  }, [weekId, weekCompareId]);

  return (
    <Chart
      options={options}
      series={series}
      height={height}
      type="bar"
    />
  );
};

export default GlobalActivitiesBarChart;
