import React, { useState } from "react";
import { Search } from "@mui/icons-material";
import { IconButton, TextField, InputAdornment } from "@mui/material";
import {
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import FlexBetween from "./FlexBetween";

const DataGridCustomToolbar = ({ setPaginationModel, formatDataForExport }) => {
  const [text,setText] = useState("");
  return (
    <GridToolbarContainer> 
      <FlexBetween width="100%">
        <FlexBetween>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport
          onClick={() => {
            const formattedData = formatDataForExport();
            
            // Get headers
            const headers = Object.keys(formattedData[0]);

            // Create CSV content with headers
            const csvContent = "data:text/csv;charset=utf-8," + 
              [headers.join(","), ...formattedData.map(row => headers.map(header => `"${row[header]}"`).join(","))].join("\n");
              
            // Create a temporary anchor element to trigger download
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "export.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }}
        />
        </FlexBetween>
        <TextField
          label="Search..."
          sx={{ mb: "0.5rem", width: "15rem" }}
          onChange={(e) => {
            setText(e.target.value);
          }}
          
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    setPaginationModel(prev => ({
                      ...prev,  // Keep the previous state unchanged
                      search: text  // Update only the search field
                    }));
                  }}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FlexBetween>
    </GridToolbarContainer>
  );
};

export default DataGridCustomToolbar;
