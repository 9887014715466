import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import {
  useGetRegionPercentageParticipationActivitiesQuery,
  useGetGlobalPercentageParticipationActivitiesQuery,
  useGETWeeklyDataQuery
} from "state/api";

const ActivitiesBarChart = ({ height, view }) => {
  const startDate = useSelector(state => state.global.startDate);
  const endDate = useSelector(state => state.global.endDate);
  const dataRender = useSelector(state => state.global.dataRender);
  const CompareToRender = useSelector(state => state.global.CompareToRender);
  const compareStartDate = useSelector(state => state.global.compareStartDate);
  const compareEndDate = useSelector(state => state.global.compareEndDate);
  const weekId = useSelector(state => state.global.weekId);
  const weekCompareId = useSelector(state => state.global.weekCompareId);
  const isCompare = useSelector(state => state.global.isCompare);
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  const { data: regionData, isLoading: regionDataIsLoading, error: regionDataError } = useGetRegionPercentageParticipationActivitiesQuery({
    startDate,
    endDate,
  });

  const { data: globalData, isLoading: globalDataIsLoading, error: globalDataError } = useGetGlobalPercentageParticipationActivitiesQuery({
    startDate,
    endDate
  });

  const { data: globalCompareData, isLoading: globalCompareDataIsLoading, error: globalCompareDataError } = useGetGlobalPercentageParticipationActivitiesQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });

  const { data: regionCompareData, isLoading: regionCompareDataIsLoading, error: regionCompareDataError } = useGetRegionPercentageParticipationActivitiesQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });

  const { data: weekData, isLoading: weekLoading, refetch: refetchWeekData } = useGETWeeklyDataQuery(weekId ? { weekId: weekId.id } : null);
  const { data: CompareToData, isLoading: CompareToDataIsLoading, refetch: refetchCompareToData } = useGETWeeklyDataQuery(weekCompareId ? { weekId: weekCompareId.id } : null);

  useEffect(() => {
    let primaryChartData;
    let comparisonChartData;
    if (dataRender === "Custom") {
      switch (view) {
        case "national":
          primaryChartData = globalData ? globalData[0] : null;
          comparisonChartData = isCompare ? (CompareToRender === "Custom" ? globalCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[17]?.data : null) : null;
          break;
        case "center":
          primaryChartData = regionData ? regionData[0]?.regions?.[0] : null;
          comparisonChartData = isCompare ? (CompareToRender === "Custom" ? regionCompareData[0]?.regions?.[0] : CompareToRender === "Week" ? CompareToData?.[18]?.data?.regions[0] : null) : null;
          break;
        case "east":
          primaryChartData = regionData ? regionData[0]?.regions?.[1] : null;
          comparisonChartData = isCompare ? (CompareToRender === "Custom" ? regionCompareData[0]?.regions?.[1] : CompareToRender === "Week" ? CompareToData?.[18]?.data?.regions[1] : null) : null;
          break;
        case "west":
          primaryChartData = regionData ? regionData[0]?.regions?.[2] : null;
          comparisonChartData = isCompare ? (CompareToRender === "Custom" ? regionCompareData[0]?.regions?.[2] : CompareToRender === "Week" ? CompareToData?.[18]?.data?.regions[2] : null) : null;
          break;
        default:
          primaryChartData = globalData ? globalData[0] : null;
          comparisonChartData = isCompare ? (CompareToRender === "Custom" ? globalCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[17]?.data : null) : null;
          break;
      }
    } else if (dataRender === "Week") {
      switch (view) {
        case "national":
          primaryChartData = weekData ? weekData[17]?.data : null;
          comparisonChartData = isCompare ? (CompareToRender === "Custom" ? globalCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[17]?.data : null) : null;
          break;
        case "center":
          primaryChartData = weekData ? weekData[18]?.data?.regions[0] : null;
          comparisonChartData = isCompare ? (CompareToRender === "Custom" ? regionCompareData[0]?.regions?.[0] : CompareToRender === "Week" ? CompareToData?.[18]?.data?.regions[0] : null) : null;
          break;
        case "east":
          primaryChartData = weekData ? weekData[18]?.data?.regions[1] : null;
          comparisonChartData = isCompare ? (CompareToRender === "Custom" ? regionCompareData[0]?.regions?.[1] : CompareToRender === "Week" ? CompareToData?.[18]?.data?.regions[1] : null) : null;
          break;
        case "west":
          primaryChartData = weekData ? weekData[18]?.data?.regions[2] : null;
          comparisonChartData = isCompare ? (CompareToRender === "Custom" ? regionCompareData[0]?.regions?.[2] : CompareToRender === "Week" ? CompareToData?.[18]?.data?.regions[2] : null) : null;
          break;
        default:
          primaryChartData = weekData ? weekData[17]?.data : null;
          comparisonChartData = weekData ? weekData[18]?.data?.regions[0] : null;
          break;
      }
    }

    const baseOptions = {
      chart: {
        type: 'bar',
        id: "basic-bar",
        height: 350
      },
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " %"
          }
        }
      }
    };

    const colorMap = {
      national: ["#00E396", "#008FFB"],
      center: ["#FFBB00", "#B06500"],
      east: ["#071076", "#6671BD"],
      west: ["#BD66A1", "#AF66BD"]
    };

    const getColorArray = (view, isCompare) => {
      switch (view) {
        case "national":
          return isCompare ? colorMap.national : [colorMap.national[0]];
        case "center":
          return isCompare ? colorMap.center : [colorMap.center[0]];
        case "east":
          return isCompare ? colorMap.east : [colorMap.east[0]];
        case "west":
          return isCompare ? colorMap.west : [colorMap.west[0]];
        default:
          return isCompare ? colorMap.national : [colorMap.national[0]];
      }
    };

    const updatedOptions = {
      ...baseOptions,
      colors: getColorArray(view, isCompare),
      xaxis: {
        categories: primaryChartData?.activityTypes || [],
      },
      yaxis: {
        title: {
          text: "Percentage",
        },
        min: 0,
        max: 100,
      }
    };

    setOptions(updatedOptions);

    let primaryChartDataPercentages = [];
    if (primaryChartData?.percentages.length === 1) {
      primaryChartDataPercentages = primaryChartData?.percentages[0];
    } else {
      primaryChartDataPercentages = primaryChartData?.percentages;
    }

    let comparisonChartDataPercentages = [];
    if (comparisonChartData?.percentages.length === 1) {
      comparisonChartDataPercentages = comparisonChartData?.percentages[0];
    } else {
      comparisonChartDataPercentages = comparisonChartData?.percentages;
    }

    const primarySeries = [
      { name: "Participation Rate", data: primaryChartDataPercentages || [] },
    ];

    const comparisonSeries = [
      { name: "Selected Data: Participation Rate", data: primaryChartDataPercentages || [] },
      { name: "Compared Data: Participation Rate", data: comparisonChartDataPercentages || [] },
    ];

    setSeries(isCompare ? comparisonSeries : primarySeries);

  }, [
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    isCompare,
    dataRender,
    CompareToRender,
    weekData,
    CompareToData,
    weekLoading,
    CompareToDataIsLoading,
    regionData,
    regionDataIsLoading,
    regionDataError,
    regionCompareData,
    regionCompareDataIsLoading,
    regionCompareDataError,
    globalData,
    globalDataIsLoading,
    globalDataError,
    globalCompareData,
    globalCompareDataIsLoading,
    globalCompareDataError,
    view
  ]);

  useEffect(() => {
    refetchWeekData();
    refetchCompareToData();
  }, [weekId, weekCompareId]);

  return (
    <Chart
      options={options}
      series={series}
      height={height}
      type="bar"
    />
  );
};

export default ActivitiesBarChart;
