import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from 'axios';

const WalletComponent = ({ accountCode, initialPointBalance, onPointBalanceChange }) => {
  const [isLoading, setIsLoading] = useState(true); // Set initial state of isLoading to true
  const [pointBalance, setPointBalance] = useState(initialPointBalance);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPointBalance(); // Automatically fetch point balance when component mounts
  }, []);

  useEffect(() => {
    setPointBalance(initialPointBalance);
  }, [initialPointBalance]);

  const fetchPointBalance = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/members/${accountCode}`);
      setPointBalance(response.data.pointBalance);
      if (onPointBalanceChange) {
        onPointBalanceChange(response.data.pointBalance);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Tooltip title='Refresh Point Balance'>
      <div>
        <IconButton
          variant='contained'
          onClick={fetchPointBalance}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={30} sx={{ color: '#00B185'}} /> : <RefreshIcon sx={{ color: '#00B185'}} />}
        </IconButton>
        {pointBalance !== null && !error && <span>{pointBalance}</span>}
        {error && <span>Error fetching data</span>}
      </div>
    </Tooltip>
  );
};

export default WalletComponent;
