import React, { useEffect, useState } from "react";
import { useGetPercentageRedemptionBetweendatesQuery, useGETWeeklyDataQuery } from "state/api";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";

const PercentageRedemptionChart = () => {
  const startDate = useSelector(state => state.global.startDate);
  const endDate = useSelector(state => state.global.endDate);
  const dataRender = useSelector(state => state.global.dataRender);
  const CompareToRender = useSelector(state => state.global.CompareToRender);
  const compareStartDate = useSelector(state => state.global.compareStartDate);
  const compareEndDate = useSelector(state => state.global.compareEndDate);
  const weekId = useSelector(state => state.global.weekId);
  const weekCompareId = useSelector(state => state.global.weekCompareId);
  const isCompare = useSelector(state => state.global.isCompare);
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  const { data: customData, isLoading: customDataIsLoading, error: customDataError } = useGetPercentageRedemptionBetweendatesQuery({
    startDate,
    endDate
  });

  const { data: customCompareData, isLoading: customCompareDataIsLoading, error: customCompareDataError } = useGetPercentageRedemptionBetweendatesQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });

  const { data : weekData , isLoading : weekLoading , refetch: refetchWeekData} = useGETWeeklyDataQuery(weekId ? {weekId : weekId.id} : null);
  const { data : CompareToData , isLoading : CompareToDataIsLoading , refetch: refetchCompareToData} = useGETWeeklyDataQuery(weekCompareId ? {weekId : weekCompareId.id }: null);


  useEffect(() => {
    
    let primaryChartData;
    let comparisonChartData;
  
    switch (dataRender) {
      case 'Custom':
        primaryChartData = customData ? customData[0] : null;
        comparisonChartData = isCompare ? (CompareToRender === "Custom" ? customCompareData?.[0] : (CompareToRender === "Week" ? CompareToData?.[29]?.data : null)) : null;
        break;
      case 'Week':
        primaryChartData = weekData ? weekData[29]?.data : null;
        comparisonChartData = isCompare ? (CompareToRender === "Custom" ? customCompareData?.[0] : (CompareToRender === "Week" ? CompareToData?.[29]?.data : null)) : null;
        break;
      default:
        primaryChartData = customData ? customData[0] : null;
        comparisonChartData = isCompare ? (CompareToRender === "Custom" ? customCompareData?.[0] : (CompareToRender === "Week" ? CompareToData?.[29]?.data : null)) : null;
        break;
    }
  
    const primarySeries = [
      primaryChartData?.totalRedemptionsPercentage || 0
    ];
    const comparisonSeries = [
      primaryChartData?.totalRedemptionsPercentage || 0, 
      comparisonChartData?.totalRedemptionsPercentage || 0,
    ];
  
    setSeries(isCompare ? comparisonSeries : primarySeries);

    const baseOptions = {
      chart: {
        height: '100%',
        type: 'radialBar',
        toolbar: {
          show: true
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          offsetY: 35, // Center the chart vertically
          /*offsetX: -4,*/ // Center the chart horizontally
          hollow: {
            margin: 0,
            size: '60%',
            background: '#fff',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24
            }
          },
          // Exclude total label when isCompare is false
          ...(isCompare ? {
            dataLabels: {
              total: {
                show: true,
                label: "Comparison",
                formatter: function (w) {
                  let diff = (w.config.series[0] - w.config.series[1]).toFixed(2);
                  if (diff > 0) {
                    diff = `+${diff} %`;
                } else if (diff < 0) {
                    diff = `${diff} %`;
                }
                  return diff; // Use the third value from comparisonSeries
                }
              }
            }
          }: {
            dataLabels: {
              total: {
                show: true,
                label: "Progress",
                formatter: function (w) {
                  let diff = w.config.series[0];
                  diff = diff + " %"
                  return diff; // Use the third value from comparisonSeries
                }
              }
            }
          }
          )
        }
      },
      track: {
        background: '#fff',
        strokeWidth: '67%',
        margin: 0,
        dropShadow: {
          enabled: true,
          top: -3,
          left: 0,
          blur: 4,
          opacity: 0.35
        }
      },
      dataLabels: {
        show: true,
        name: {
          offsetY: -10,
          show: true,
          color: '#888',
          fontSize: '17px'
        },
        value: {
          formatter: function(val) {
            return parseInt(val);
          },
          color: '#111',
          fontSize: '36px',
          show: true,
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#ABE5A1'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      legend:{
        show: true,
        floating: true,
        fontSize: '14px',
        position: 'top',
        formatter: function(seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + " %"
        },
      },
      colors: isCompare ? ['#00E396', '#008FFB'] : ['#00E396'], // Use the specified colors
      labels: isCompare ? ['Selected', 'Compared'] : ["Progress"]
    };
  
    setOptions(baseOptions);
  }, [
    startDate, 
    endDate,
    compareStartDate,
    compareEndDate,
    isCompare,
    dataRender,
    CompareToRender,
    weekData,
    CompareToData,   
    weekLoading,
    CompareToDataIsLoading,
    customData,
    customDataIsLoading,  
    customDataError,    
    customCompareData, 
    customCompareDataIsLoading, 
    customCompareDataError,
  ]);

  useEffect(() => {
    refetchWeekData();
    refetchCompareToData();
  },[weekId,weekCompareId])

  

  return (
    <div style={{position :"absolute", display:"block", width:"100%" }} > {/* Wrapper component with key to force re-renders */}
      {customDataIsLoading || customCompareDataIsLoading || weekLoading ||CompareToDataIsLoading ? (
        <>Loading ...</>
      ) : (
        <Chart
          options={options}
          labels={options.labels}
          series={series}
          type="radialBar"
        />
      )}
    </div>
  );
};

export default PercentageRedemptionChart;
