import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme, IconButton, Tooltip} from "@mui/material";
import FlexBetween from './FlexBetween';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AverageTimeSVG from '../assets/average_time.4703971706685dc2004e9f78746cc4a6.svg fill.svg';
import { useSelector } from 'react-redux';
import { useGetGAAverageEngagementTimeQuery, useGETWeeklyDataQuery } from 'state/api';

const StatBoxAverageTime = ({ title, help }) => {
  const theme = useTheme();
  const startDate = useSelector(state => state.global.startDate);
  const endDate = useSelector(state => state.global.endDate);
  const dataRender = useSelector(state => state.global.dataRender);
  const CompareToRender = useSelector(state => state.global.CompareToRender);
  const compareStartDate = useSelector(state => state.global.compareStartDate);
  const compareEndDate = useSelector(state => state.global.compareEndDate);
  const weekId = useSelector(state => state.global.weekId);
  const weekCompareId = useSelector(state => state.global.weekCompareId);
  const isCompare = useSelector(state => state.global.isCompare);
  const [showHelp, setShowHelp] = useState(false);
  const [displayValue1, setDisplayValue1] = useState(0);
  const [displayValue2, setDisplayValue2] = useState(0);
  const [increase, setIncrease] = useState(null);

  const { data: customData, isLoading: customDataIsLoading, error: customDataError } = useGetGAAverageEngagementTimeQuery({
    startDate,
    endDate
  });

  const { data: customCompareData, isLoading: customCompareDataIsLoading, error: customCompareDataError } = useGetGAAverageEngagementTimeQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });

  const { data : weekData , isLoading : weekLoading , refetch: refetchWeekData} = useGETWeeklyDataQuery(weekId ? {weekId : weekId.id} : null);
  const { data : CompareToData , isLoading : CompareToDataIsLoading , refetch: refetchCompareToData} = useGETWeeklyDataQuery(weekCompareId ? {weekId : weekCompareId.id }: null);


  const { data: weeklyData, isLoading: weeklyDataIsLoading, error: weeklyDataError } = useGetGAAverageEngagementTimeQuery({
    startDate: weekData && weekData[0]?.data?.startDate?.split("T")[0],
    endDate: weekData && weekData[0]?.data?.endDate?.split("T")[0]
  });

  const { data: weeklyCompareData, isLoading: weeklyCompareDataIsLoading, error: weeklyCompareDataError } = useGetGAAverageEngagementTimeQuery({
    startDate: CompareToData && CompareToData[0]?.data?.startDate?.split("T")[0],
    endDate: CompareToData && CompareToData[0]?.data?.endDate?.split("T")[0]
  });

  useEffect(() => {
    
    let primaryData;
    let comparisonData;
    let value1;
    let value2;
  
    switch (dataRender) {
      case 'Custom':
        primaryData = customData ? customData : null;
        comparisonData = isCompare ? customCompareData && CompareToRender === "Custom" ? customCompareData : weeklyCompareData && CompareToRender === "Week" ? weeklyCompareData : null : null;
        value1 = primaryData;
        value2 = isCompare ? comparisonData : null;
        break;
      case 'Week':
        primaryData = weeklyData ? weeklyData : null;
        comparisonData = isCompare ? customCompareData && CompareToRender === "Custom" ? customCompareData : weeklyCompareData && CompareToRender === "Week" ? weeklyCompareData : null : null;
        value1 = primaryData;
        value2 = isCompare ? comparisonData : null;
        break;
      default:
        primaryData = customData ? customData : null;
        comparisonData = isCompare ? customCompareData && CompareToRender === "Custom" ? customCompareData : weeklyCompareData && CompareToRender === "Week" ? weeklyCompareData : null : null;
        break;
    }
    
  
    setIncrease(isCompare && primaryData !== null && comparisonData !== null ? (((primaryData - comparisonData) / primaryData) * 100).toFixed(2) : null);
  
    if (value1 != null) {
      const minutes = Math.floor(value1 / 60);
      const seconds = Math.floor(value1 % 60);
      setDisplayValue1(`${minutes}m ${seconds}s`);
    }
    if (value2 != null) {
      const minutes = Math.floor(value2 / 60);
      const seconds = Math.floor(value2 % 60);
      setDisplayValue2(`${minutes}m ${seconds}s`);
    }
  
  }, [
    startDate, 
    endDate,
    compareStartDate,
    compareEndDate,
    isCompare,
    dataRender,
    CompareToRender,
    weekData,
    CompareToData, 
    weekLoading,
    CompareToDataIsLoading,  
    customData,
    customDataIsLoading,  
    customDataError,    
    customCompareData, 
    customCompareDataIsLoading, 
    customCompareDataError,
    weeklyData,
    weeklyDataIsLoading,
    weeklyDataError,
    weeklyCompareData,
    weeklyCompareDataIsLoading,
    weeklyCompareDataError,
  ]);

  useEffect(() => {
    refetchWeekData();
    refetchCompareToData();
  },[weekId,weekCompareId])

  
  

  return (
    <Box
      gridColumn="span 1"
      gridRow="span 1"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor='#FFFFFF'
      borderRadius="0.55rem"
    >
      <FlexBetween margin='0rem 0rem 0.75rem 0rem'>
        <FlexBetween gap="0.75rem">
          <img src={AverageTimeSVG} alt="Average Time" width="36" height="36" />
          <Typography variant="subtitle1" sx={{ color: theme.palette.secondary[100], fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
            {title}
          </Typography>
        </FlexBetween>
        <Tooltip title={help} arrow>
          <IconButton
            size="small"
            onMouseEnter={() => setShowHelp(true)}
            onMouseLeave={() => setShowHelp(false)}
          >
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </FlexBetween>

      {customDataIsLoading || customCompareDataIsLoading || weekLoading || CompareToDataIsLoading ? (
        <Typography
          variant="h3"
          fontWeight="600"
          sx={{ color: theme.palette.secondary[200] }}
        >
          Loading...
        </Typography>
      ) : (
        <>
        <FlexBetween 
            gap="1rem"
            >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p="0.25rem"
          >
          <Typography
            variant="h3"
            fontWeight="600"
            sx={{ 
              color: theme.palette.secondary[200],
              fontSize: '1.3rem', 
            }}
          >
            {displayValue1}
          </Typography>
          {isCompare && (
          <FlexBetween
            gap="0.5rem"
          >
            <Typography
              variant="h6"
              fontStyle="italic"
              
              display="flex"
              alignItems="center" // Align items vertically in the center
              sx={{
                color: increase !== null ? (increase < 0 ? theme.palette.error.main : theme.palette.success.main) : theme.palette.secondary.light,
                fontSize: '0.87rem',
              }}
            >
              {increase !== null && (
                <>
                  {increase > 0 ? <TrendingUpIcon sx={{ marginRight: '0.2rem' }} /> : <TrendingDownIcon sx={{ marginRight: '0.2rem' }} />}
                  {`${Math.abs(increase)} %`}
                </>
              )}
            </Typography>
            <Typography
              variant="h6"
              fontWeight="400"
              sx={{
                color: theme.palette.secondary[200],
                fontSize: '0.87rem',
              }}
            >
              vs {displayValue2}
            </Typography>
          </FlexBetween>
          )}

          </Box>
          
        </FlexBetween>
        </>
      )}
    </Box>
  );
}

export default StatBoxAverageTime;

