import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import {
  useGetRegionBARPercentageParticipationSuccessGameQuery,
  useGETWeeklyDataQuery
} from "state/api";

const WeeklyGameBarChart = ({ height}) => {
  const startDate = useSelector(state => state.global.startDate);
  const endDate = useSelector(state => state.global.endDate);
  const dataRender = useSelector(state => state.global.dataRender);
  const CompareToRender = useSelector(state => state.global.CompareToRender);
  const compareStartDate = useSelector(state => state.global.compareStartDate);
  const compareEndDate = useSelector(state => state.global.compareEndDate);
  const weekId = useSelector(state => state.global.weekId);
  const weekCompareId = useSelector(state => state.global.weekCompareId);
  const isCompare = useSelector(state => state.global.isCompare);
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  const { data: customData, isLoading: customDataIsLoading, error: customDataError } = useGetRegionBARPercentageParticipationSuccessGameQuery({
    startDate,
    endDate
  });

  const { data: customCompareData, isLoading: customCompareDataIsLoading, error: customCompareDataError } = useGetRegionBARPercentageParticipationSuccessGameQuery({
    startDate : compareStartDate,
    endDate: compareEndDate
  });

  const { data : weekData , isLoading : weekLoading , refetch: refetchWeekData} = useGETWeeklyDataQuery(weekId ? {weekId : weekId.id} : null);
  const { data : CompareToData , isLoading : CompareToDataIsLoading , refetch: refetchCompareToData} = useGETWeeklyDataQuery(weekCompareId ? {weekId : weekCompareId.id }: null);



  useEffect(() => {
    let primaryChartData;
    let comparisonChartData;

    switch (dataRender) {
      case 'Custom':
        primaryChartData = customData ? customData[0] : null;
        comparisonChartData = isCompare ? CompareToRender === "Custom" ? customCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[27]?.data : null : null;
        break;
      case 'Week':
        primaryChartData = weekData ? weekData[27]?.data : null;
        comparisonChartData = isCompare ? CompareToRender === "Custom" ? customCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[27]?.data : null : null;
        break;
      default:
        primaryChartData = customData ? customData[0] : null;
        comparisonChartData = isCompare ? CompareToRender === "Custom" ? customCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[27]?.data : null : null;
        break;
    }

    const baseOptions = {
      chart: {
        type: 'bar',
        id: "basic-bar",
        height: 350
      },
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " %"
          }
        }
      }
    };

    const isCompareOptions ={
      ...baseOptions,
      xaxis: {
        categories: primaryChartData?.regions || []
      },
      yaxis: {
        title: {
          text: 'Percentage'
        },
        min: 0,
        max: 100
      },
    };

    const isNotCompareOptions ={
      ...baseOptions,
      xaxis: {
        categories: primaryChartData?.regions || []
      },
      yaxis: {
        title: {
          text: 'Percentage'
        },
        min: 0,
        max: 100
      },
      
    };

    setOptions(isCompare ? isCompareOptions : isNotCompareOptions);

    const primarySeries = [
      { name: "Participation Rate", data: primaryChartData?.percentageParticipants || [], color: "#00E396" },
      { name: "Success Rate", data: primaryChartData?.percentageSuccess || [], color: "#B02A00" },
    ];

    const comparisonSeries = [
      { name: "Selected Data: Participation Rate", data: primaryChartData?.percentageParticipants || [], color: "#00E396" },
      { name: "Selected Data: Success Rate", data: primaryChartData?.percentageSuccess || [], color: "#B02A00" },
      { name: "Compared Data: Participation Rate", data: comparisonChartData?.percentageParticipants || [], color: "#008FFB" },
      { name: "Compared Data: Success Rate", data: comparisonChartData?.percentageSuccess || [], color: "#FA9600" }
    ];

    setSeries(isCompare ? comparisonSeries : primarySeries);


  }, [
    startDate, 
    endDate,
    compareStartDate,
    compareEndDate,
    isCompare,
    dataRender,
    CompareToRender,
    weekData,
    CompareToData,   
    customData,
    customDataIsLoading,  
    customDataError,    
    customCompareData, 
    customCompareDataIsLoading, 
    customCompareDataError,
    weekLoading,
    CompareToDataIsLoading,
  ]);

  useEffect(() => {
    refetchWeekData();
    refetchCompareToData();
  },[weekId,weekCompareId])



  return (
    <Chart
      options={options}
      series={series}
      height={height}
      type="bar"
    />
  );
};

export default WeeklyGameBarChart;
