import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { Box, FormControlLabel } from '@mui/material';

export default function TimeComponentSelect({ weeks, setSelectedWeek, selectedWeek, handleWeekSelect ,isCompare , handleChangeSwitch , compareSelectedWeek , handleCompareWeekSelect }) {
    const comparedWeeks = weeks;
  return (
    
    <Box sx={{borderLeft :"1px solid #ABAFBA" , height : "100%" , display :"flex" , flexDirection : "column" , gap : "2.5em" , padding : "3em 0em 3em 0em"}} >
        <Box>
        <span>Date Ranges</span>
      <Box>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <Select
          native 
          
          id="week-select"
          value={selectedWeek ? selectedWeek.id : ''}
    
          onChange={handleWeekSelect}
        >
          <option value={null}>Custom</option>
          {weeks && weeks.map((week) => (
            <option key={week.id} value={week.id}>
              {week.id}
            </option>
          ))}
        </Select>
      </FormControl>
      </Box>
      <FormControlLabel
          control={
            <Switch checked={isCompare} onChange={handleChangeSwitch} name="compare" />
          }
          label="Compare With"
        />
        </Box>
        <Box>
        {isCompare && (
  <FormControl sx={{ m: 1, minWidth: 200 }}>
     <span>Date Ranges</span>
    <Select
      native 
      id="week-compare-select"
      value={compareSelectedWeek ? compareSelectedWeek.id : ''}
      onChange={handleCompareWeekSelect}
    >
      <option value={null}>Custom</option>
      {comparedWeeks && isCompare && comparedWeeks.map((compareweek) => (
        <option key={compareweek.id} value={compareweek.id}>
          {compareweek.id}
        </option>
      ))}
    </Select> 
  </FormControl>
)}
        </Box>
    </Box>

  );
}