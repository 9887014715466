import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme, IconButton, Tooltip, CircularProgress } from "@mui/material";
import FlexBetween from './FlexBetween';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import EngagedUsersSVG from '../assets/engeged_users.215a071176aca13c12f2715615a4e5af.svg fill.svg';
import { useSelector } from 'react-redux';
import { 
  useGetGlobalPercentageEngagedPSGQuery, 
  useGetGlobalPercentageEngagedPQGQuery,
  useGetGlobalPercentageEngagedPSQQuery,
  useGetGlobalPercentageEngagedSGQQuery,
  ///////////////
  useGetGlobalPercentageEngagedPSQuery,
  useGetGlobalPercentageEngagedPGQuery,
  useGetGlobalPercentageEngagedPQQuery,
  useGetGlobalPercentageEngagedSGQuery,
  useGetGlobalPercentageEngagedSQQuery,
  useGetGlobalPercentageEngagedGQQuery,
  useGetGlobalPercentageEngagedPQuery,
  useGetGlobalPercentageEngagedSQuery,
  useGetGlobalPercentageEngagedGQuery,
  useGetGlobalPercentageEngagedQQuery,
  useGetEngagedChallengesQuery,
  useGETWeeklyDataQuery
} from 'state/api';

const StatBoxEngaged = ({ title, help }) => {
  const theme = useTheme();
  const startDate = useSelector(state => state.global.startDate);
  const endDate = useSelector(state => state.global.endDate);
  const dataRender = useSelector(state => state.global.dataRender);
  const CompareToRender = useSelector(state => state.global.CompareToRender);
  const compareStartDate = useSelector(state => state.global.compareStartDate);
  const compareEndDate = useSelector(state => state.global.compareEndDate);
  const weekId = useSelector(state => state.global.weekId);
  const weekCompareId = useSelector(state => state.global.weekCompareId);
  const isCompare = useSelector(state => state.global.isCompare);
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state
  const [showHelp, setShowHelp] = useState(false);
  const [value1, setValue1] = useState(0); // Initialize value state
  const [value2, setValue2] = useState(0); // Initialize value state
  const [increase, setIncrease] = useState(null); // Initialize increase state

  //console.log(compareStartDate, startDate , "STAAAAAAAAAAAAAAAART DATA");
  console.log("dataRender", dataRender);
  console.log("CompareToRender", CompareToRender);

  const { data : weekData , isLoading : weekLoading , refetch: refetchWeekData} = useGETWeeklyDataQuery(weekId ? {weekId : weekId.id} : null);
  const { data : CompareToData , isLoading : CompareToDataIsLoading , refetch: refetchCompareToData} = useGETWeeklyDataQuery(weekCompareId ? {weekId : weekCompareId.id }: null);

  // Selected Week data
  const { data: selectedWeekData, isLoading: selectedWeekDataIsLoading, error: selectedWeekDataError } = useGetEngagedChallengesQuery({
    startDate,
    endDate
  });
 
  //console.log(compareStartDate, "compareStartDate");
  const { data: selectedCompareWeekData, isLoading: selectedCompareWeekDataIsLoading, error: selectedCompareWeekDataError } = useGetEngagedChallengesQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });
 

  // 3 ACTIVITIES
  // PLANO STOCK GAME
  const { data: customDataPSG, isLoading: customDataPSGIsLoading, error: customDataPSGError } = useGetGlobalPercentageEngagedPSGQuery({
    startDate,
    endDate
  });

  const { data: customCompareDataPSG, isLoading: customCompareDataPSGIsLoading, error: customCompareDataPSGError } = useGetGlobalPercentageEngagedPSGQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });
  // PLANO QUIZ GAME
  const { data: customDataPQG, isLoading: customDataPQGIsLoading, error: customDataPQGError } = useGetGlobalPercentageEngagedPQGQuery({
    startDate,
    endDate
  });

  const { data: customCompareDataPQG, isLoading: customCompareDataPQGIsLoading, error: customCompareDataPQGError } = useGetGlobalPercentageEngagedPQGQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });
  // PLANO STOCK QUIZ
  const { data: customDataPSQ, isLoading: customDataPSQIsLoading, error: customDataPSQError } = useGetGlobalPercentageEngagedPSQQuery({
    startDate,
    endDate
  });

  const { data: customCompareDataPSQ, isLoading: customCompareDataPSQIsLoading, error: customCompareDataPSQError } = useGetGlobalPercentageEngagedPSQQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });
  // STOCK GAME QUIZ
  const { data: customDataSGQ, isLoading: customDataSGQIsLoading, error: customDataSGQError } = useGetGlobalPercentageEngagedSGQQuery({
    startDate,
    endDate
  });

  const { data: customCompareDataSGQ, isLoading: customCompareDataSGQIsLoading, error: customCompareDataSGQError } = useGetGlobalPercentageEngagedSGQQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });

  // 2 ACTIVITIES

  // PLANO STOCK
  const { data: customDataPS, isLoading: customDataPSIsLoading, error: customDataPSError } = useGetGlobalPercentageEngagedPSQuery({
    startDate,
    endDate
  });

  const { data: customCompareDataPS, isLoading: customCompareDataPSIsLoading, error: customCompareDataPSError } = useGetGlobalPercentageEngagedPSQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });
  // PLANO GAME
  const { data: customDataPG, isLoading: customDataPGIsLoading, error: customDataPGError } = useGetGlobalPercentageEngagedPGQuery({
    startDate,
    endDate
  });

  const { data: customCompareDataPG, isLoading: customCompareDataPGIsLoading, error: customCompareDataPGError } = useGetGlobalPercentageEngagedPGQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });
  // PLANO QUIZ
  const { data: customDataPQ, isLoading: customDataPQIsLoading, error: customDataPQError } = useGetGlobalPercentageEngagedPQQuery({
    startDate,
    endDate
  });

  const { data: customCompareDataPQ, isLoading: customCompareDataPQIsLoading, error: customCompareDataPQError } = useGetGlobalPercentageEngagedPQQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });
  // STOCK GAME
  const { data: customDataSG, isLoading: customDataSGIsLoading, error: customDataSGError } = useGetGlobalPercentageEngagedSGQuery({
    startDate,
    endDate
  });

  const { data: customCompareDataSG, isLoading: customCompareDataSGIsLoading, error: customCompareDataSGError } = useGetGlobalPercentageEngagedSGQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });
  // STOCK QUIZ
  const { data: customDataSQ, isLoading: customDataSQIsLoading, error: customDataSQError } = useGetGlobalPercentageEngagedSQQuery({
    startDate,
    endDate
  });

  const { data: customCompareDataSQ, isLoading: customCompareDataSQIsLoading, error: customCompareDataSQError } = useGetGlobalPercentageEngagedSQQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });
  // GAME QUIZ
  const { data: customDataGQ, isLoading: customDataGQIsLoading, error: customDataGQError } = useGetGlobalPercentageEngagedGQQuery({
    startDate,
    endDate
  });

  const { data: customCompareDataGQ, isLoading: customCompareDataGQIsLoading, error: customCompareDataGQError } = useGetGlobalPercentageEngagedGQQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });

  // 1 Activity
  // PLANO
  const { data: customDataP, isLoading: customDataPIsLoading, error: customDataPError } = useGetGlobalPercentageEngagedPQuery({
    startDate,
    endDate
  });

  const { data: customCompareDataP, isLoading: customCompareDataPIsLoading, error: customCompareDataPError } = useGetGlobalPercentageEngagedPQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });
  // STOCK
  const { data: customDataS, isLoading: customDataSIsLoading, error: customDataSError } = useGetGlobalPercentageEngagedSQuery({
    startDate,
    endDate
  });

  const { data: customCompareDataS, isLoading: customCompareDataSIsLoading, error: customCompareDataSError } = useGetGlobalPercentageEngagedSQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  });
  // GAME
  const { data: customDataG, isLoading: customDataGIsLoading, error: customDataGError } = useGetGlobalPercentageEngagedGQuery({
    startDate,
    endDate
  });

  const { data: customCompareDataG, isLoading: customCompareDataGIsLoading, error: customCompareDataGError } = useGetGlobalPercentageEngagedGQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  }); 
  // QUIZ
  const { data: customDataQ, isLoading: customDataQIsLoading, error: customDataQError } = useGetGlobalPercentageEngagedQQuery({
    startDate,
    endDate
  });

  const { data: customCompareDataQ, isLoading: customCompareDataQIsLoading, error: customCompareDataQError } = useGetGlobalPercentageEngagedQQuery({
    startDate: compareStartDate,
    endDate: compareEndDate
  }); 
/*
  console.log("customDataPS", customDataPS);
  console.log("customDataPG", customDataPG);
  console.log("customDataPQ", customDataPQ);
  console.log("customDataSG", customDataSG);
  console.log("customDataSQ", customDataSQ);
  console.log("customDataGQ", customDataGQ);
  console.log("customDataP", customDataP);
  console.log("customDataS", customDataS);
  console.log("customDataG", customDataG);
  console.log("customDataQ", customDataQ);
*/  
  //console.log("weekData", weekData);
  //console.log("CompareDate", CompareToData);

  //console.log("selectedWeek", selectedWeekData);
  //console.log("selectedCompareWeek", selectedCompareWeekData);
  //console.log("compareStartDate", compareStartDate);
  //console.log("customCompareDataPQG", customCompareDataPQG);
  //console.log("CompaaaaaaareData", CompareToData);

  useEffect(() => {

    let primaryData;
    let comparisonData;

    switch (dataRender) {
      case 'Custom':       
        // 3 activities
        // plano stock game
        if ( selectedWeekData ? selectedWeekData[0]?.engagedChallenges.includes('plano') && selectedWeekData[0]?.engagedChallenges.includes('stock') && selectedWeekData[0]?.engagedChallenges.includes('game') : null) { 
          primaryData = customDataPSG ? customDataPSG[0] : null;
        }
        // plano quiz game    
        else if (selectedWeekData ? selectedWeekData[0]?.engagedChallenges.includes('plano') && selectedWeekData[0]?.engagedChallenges.includes('quiz') && selectedWeekData[0]?.engagedChallenges.includes('game'): null) {
          primaryData = customDataPQG ? customDataPQG[0] : null;
        }
        // plano stock quiz    
        else if (selectedWeekData ? selectedWeekData[0]?.engagedChallenges.includes('plano') && selectedWeekData[0]?.engagedChallenges.includes('stock') && selectedWeekData[0]?.engagedChallenges.includes('quiz'): null) {
          primaryData = customDataPSQ ? customDataPSQ[0] : null;
        }
        // stock game quiz  
        else if (selectedWeekData ? selectedWeekData[0]?.engagedChallenges.includes('stock') && selectedWeekData[0]?.engagedChallenges.includes('game') && selectedWeekData[0]?.engagedChallenges.includes('quiz'): null) {
          primaryData = customDataSGQ ? customDataSGQ[0] : null;
        }
        // 2 activities
        // plano stock
        else if (selectedWeekData ? selectedWeekData[0]?.engagedChallenges.includes('plano') && selectedWeekData[0]?.engagedChallenges.includes('stock'): null) {
          primaryData = customDataPS ? customDataPS[0] : null;
        }
        // plano quiz  
        else if (selectedWeekData ? selectedWeekData[0]?.engagedChallenges.includes('plano') && selectedWeekData[0]?.engagedChallenges.includes('quiz'): null) {
          primaryData = customDataPQ ? customDataPQ[0] : null;
        }
        // plano game
        else if (selectedWeekData ? selectedWeekData[0]?.engagedChallenges.includes('plano') && selectedWeekData[0]?.engagedChallenges.includes('game'): null) {
          primaryData = customDataPG ? customDataPG[0] : null;
        }
        // stock game
        else if (selectedWeekData ? selectedWeekData[0]?.engagedChallenges.includes('stock') && selectedWeekData[0]?.engagedChallenges.includes('game'): null) {
          primaryData = customDataSG ? customDataSG[0] : null;
        }
        // stock quiz
        else if (selectedWeekData ? selectedWeekData[0]?.engagedChallenges.includes('stock') && selectedWeekData[0]?.engagedChallenges.includes('quiz'): null) {
          primaryData = customDataSQ ? customDataSQ[0] : null;
        }
        // game quiz
        else if (selectedWeekData ? selectedWeekData[0]?.engagedChallenges.includes('game') && selectedWeekData[0]?.engagedChallenges.includes('quiz'): null) {
          primaryData = customDataGQ ? customDataGQ[0] : null;
        }
        // 1 activity
        // plano
        else if (selectedWeekData ? selectedWeekData[0]?.engagedChallenges.includes('plano'): null) {
          primaryData = customDataP ? customDataP[0] : null;
        }
        // stock
        else if (selectedWeekData ? selectedWeekData[0]?.engagedChallenges.includes('stock'): null) {
          primaryData = customDataS ? customDataS[0] : null;
        }
        // game
        else if (selectedWeekData ? selectedWeekData[0]?.engagedChallenges.includes('game'): null) {
          primaryData = customDataG ? customDataG[0] : null;
        }
        // quiz
        else if (selectedWeekData ? selectedWeekData[0]?.engagedChallenges.includes('quiz'): null) {
          primaryData = customDataG ? customDataG[0] : null;
        }
        ////////////////////////////
        // 3 activities
        // plano stock game
        if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('plano') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('stock') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('game')) ||
          (CompareToData?.[9]?.data?.engagedChallenges.includes('plano') &&
            CompareToData?.[9]?.data?.engagedChallenges.includes('stock') &&
            CompareToData?.[9]?.data?.engagedChallenges.includes('game'))
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataPSG?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[9]?.data
              : null
            : null;
        }
        // plano quiz game    
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('plano') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('quiz') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('game')) ||
          (CompareToData?.[7]?.data?.engagedChallenges.includes('plano') &&
            CompareToData?.[7]?.data?.engagedChallenges.includes('quiz') &&
            CompareToData?.[7]?.data?.engagedChallenges.includes('game'))
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataPQG?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[7]?.data
              : null
            : null;
        }
        // plano stock quiz   
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('plano') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('stock') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('quiz')) ||
          (CompareToData?.[10]?.data?.engagedChallenges.includes('plano') &&
            CompareToData?.[10]?.data?.engagedChallenges.includes('stock') &&
            CompareToData?.[10]?.data?.engagedChallenges.includes('quiz'))
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataPSQ?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[10]?.data
              : null
            : null;
        }
        // stock game quiz  
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('stock') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('game') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('quiz')) ||
          (CompareToData?.[14]?.data?.engagedChallenges.includes('stock') &&
            CompareToData?.[14]?.data?.engagedChallenges.includes('game') &&
            CompareToData?.[14]?.data?.engagedChallenges.includes('quiz'))
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataSGQ?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[14]?.data
              : null
            : null;
        }
        // 2 activities
        // plano stock
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('plano') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('stock') ) ||
          (CompareToData?.[8]?.data?.engagedChallenges.includes('plano') &&
            CompareToData?.[8]?.data?.engagedChallenges.includes('stock') )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataPS?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[8]?.data
              : null
            : null;
        }
        // plano quiz  
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('plano') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('quiz') ) ||
          (CompareToData?.[6]?.data?.engagedChallenges.includes('plano') &&
            CompareToData?.[6]?.data?.engagedChallenges.includes('quiz') )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataPQ?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[6]?.data
              : null
            : null;
        }
        // plano game
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('plano') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('game') ) ||
          (CompareToData?.[5]?.data?.engagedChallenges.includes('plano') &&
            CompareToData?.[5]?.data?.engagedChallenges.includes('game') )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataPG?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[5]?.data
              : null
            : null;
        }
        // stock game
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('stock') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('game') ) ||
          (CompareToData?.[13]?.data?.engagedChallenges.includes('stock') &&
            CompareToData?.[13]?.data?.engagedChallenges.includes('game') )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataSG?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[13]?.data
              : null
            : null;
        }
        // stock quiz
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('stock') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('quiz') ) ||
          (CompareToData?.[15]?.data?.engagedChallenges.includes('stock') &&
            CompareToData?.[15]?.data?.engagedChallenges.includes('quiz') )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataSQ?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[15]?.data
              : null
            : null;
        }
        // game quiz
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('game') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('quiz') ) ||
          (CompareToData?.[3]?.data?.engagedChallenges.includes('game') &&
            CompareToData?.[3]?.data?.engagedChallenges.includes('quiz') )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataGQ?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[3]?.data
              : null
            : null;
        }
        // 1 activity 
        // plano
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('plano') ) ||
          (CompareToData?.[4]?.data?.engagedChallenges.includes('plano')  )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataP?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[4]?.data
              : null
            : null;
        }
        // stock
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('stock') ) ||
          (CompareToData?.[12]?.data?.engagedChallenges.includes('stock')  )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataS?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[12]?.data
              : null
            : null;
        }
        // game
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('game') ) ||
          (CompareToData?.[2]?.data?.engagedChallenges.includes('game')  )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataG?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[2]?.data
              : null
            : null;
        }
        // quiz
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('quiz') ) ||
          (CompareToData?.[11]?.data?.engagedChallenges.includes('quiz')  )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataQ?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[11]?.data
              : null
            : null;
        }
     
        break;
      case 'Week':
        // 3 activities
        // plano stock game
        if (
          (weekData?.[9]?.data?.engagedChallenges.includes('plano') &&
            weekData?.[9]?.data?.engagedChallenges.includes('stock') &&
            weekData?.[9]?.data?.engagedChallenges.includes('game'))
        ) {
          primaryData = weekData ? weekData[9]?.data : null;
        }
        // plano quiz game    
        else if (
          (weekData?.[7]?.data?.engagedChallenges.includes('plano') &&
            weekData?.[7]?.data?.engagedChallenges.includes('quiz') &&
            weekData?.[7]?.data?.engagedChallenges.includes('game'))
        ) {
          primaryData = weekData ? weekData[7]?.data : null;
        }
        // plano stock quiz    
        else if (
          (weekData?.[10]?.data?.engagedChallenges.includes('plano') &&
            weekData?.[10]?.data?.engagedChallenges.includes('stock') &&
            weekData?.[10]?.data?.engagedChallenges.includes('quiz'))
        ) {
          primaryData = weekData ? weekData[10]?.data : null;
        }
        // stock game quiz  
        else if (
          (weekData?.[14]?.data?.engagedChallenges.includes('stock') &&
            weekData?.[14]?.data?.engagedChallenges.includes('game') &&
            weekData?.[14]?.data?.engagedChallenges.includes('quiz'))
        ) {
          primaryData = weekData ? weekData[14]?.data : null;
        }
        // 2 activities
        // plano stock
        else if (
          (weekData?.[8]?.data?.engagedChallenges.includes('plano') &&
            weekData?.[8]?.data?.engagedChallenges.includes('stock') )
        ) {
          primaryData = weekData ? weekData[8]?.data : null;
        }
        // plano quiz  
        else if (
          (weekData?.[6]?.data?.engagedChallenges.includes('plano') &&
            weekData?.[6]?.data?.engagedChallenges.includes('quiz') )
        ) {
          primaryData = weekData ? weekData[6]?.data : null;
        }
        // plano game
        else if (
          (weekData?.[5]?.data?.engagedChallenges.includes('plano') &&
            weekData?.[5]?.data?.engagedChallenges.includes('game') )
        ) {
          primaryData = weekData ? weekData[5]?.data : null;
        }
        // stock game
        else if (
          (weekData?.[13]?.data?.engagedChallenges.includes('stock') &&
            weekData?.[13]?.data?.engagedChallenges.includes('game') )
        ) {
          primaryData = weekData ? weekData[13]?.data : null;
        }
        // stock quiz
        else if (
          (weekData?.[15]?.data?.engagedChallenges.includes('stock') &&
            weekData?.[15]?.data?.engagedChallenges.includes('quiz') )
        ) {
          primaryData = weekData ? weekData[15]?.data : null;
        }
        // game quiz
        else if (
          (weekData?.[3]?.data?.engagedChallenges.includes('game') &&
            weekData?.[3]?.data?.engagedChallenges.includes('quiz') )
        ) {
          primaryData = weekData ? weekData[3]?.data : null;
        }
        // 1 activity
        // plano
        else if (
          (weekData?.[4]?.data?.engagedChallenges.includes('plano') )
        ) {
          primaryData = weekData ? weekData[4]?.data : null;
        }
        // stock
        else if (
          (weekData?.[12]?.data?.engagedChallenges.includes('stock') )
        ) {
          primaryData = weekData ? weekData[12]?.data : null;
        }
        // game
        else if (
          (weekData?.[2]?.data?.engagedChallenges.includes('game') )
        ) {
          primaryData = weekData ? weekData[2]?.data : null;
        }
        // quiz
        else if (
          (weekData?.[11]?.data?.engagedChallenges.includes('quiz') )
        ) {
          primaryData = weekData ? weekData[11]?.data : null;
        }
        ////////////////////////////
        // 3 activities
        // plano stock game
        if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('plano') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('stock') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('game')) ||
          (CompareToData?.[9]?.data?.engagedChallenges.includes('plano') &&
            CompareToData?.[9]?.data?.engagedChallenges.includes('stock') &&
            CompareToData?.[9]?.data?.engagedChallenges.includes('game'))
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataPSG?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[9]?.data
              : null
            : null;
        }
        // plano quiz game    
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('plano') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('quiz') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('game')) ||
          (CompareToData?.[7]?.data?.engagedChallenges.includes('plano') &&
            CompareToData?.[7]?.data?.engagedChallenges.includes('quiz') &&
            CompareToData?.[7]?.data?.engagedChallenges.includes('game'))
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataPQG?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[7]?.data
              : null
            : null;
        }
        // plano stock quiz   
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('plano') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('stock') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('quiz')) ||
          (CompareToData?.[10]?.data?.engagedChallenges.includes('plano') &&
            CompareToData?.[10]?.data?.engagedChallenges.includes('stock') &&
            CompareToData?.[10]?.data?.engagedChallenges.includes('quiz'))
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataPSQ?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[10]?.data
              : null
            : null;
        }
        // stock game quiz  
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('stock') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('game') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('quiz')) ||
          (CompareToData?.[14]?.data?.engagedChallenges.includes('stock') &&
            CompareToData?.[14]?.data?.engagedChallenges.includes('game') &&
            CompareToData?.[14]?.data?.engagedChallenges.includes('quiz'))
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataSGQ?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[14]?.data
              : null
            : null;
        }
        // 2 activities
        // plano stock
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('plano') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('stock') ) ||
          (CompareToData?.[8]?.data?.engagedChallenges.includes('plano') &&
            CompareToData?.[8]?.data?.engagedChallenges.includes('stock') )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataPS?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[8]?.data
              : null
            : null;
        }
        // plano quiz  
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('plano') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('quiz') ) ||
          (CompareToData?.[6]?.data?.engagedChallenges.includes('plano') &&
            CompareToData?.[6]?.data?.engagedChallenges.includes('quiz') )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataPQ?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[6]?.data
              : null
            : null;
        }
        // plano game
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('plano') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('game') ) ||
          (CompareToData?.[5]?.data?.engagedChallenges.includes('plano') &&
            CompareToData?.[5]?.data?.engagedChallenges.includes('game') )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataPG?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[5]?.data
              : null
            : null;
        }
        // stock game
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('stock') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('game') ) ||
          (CompareToData?.[13]?.data?.engagedChallenges.includes('stock') &&
            CompareToData?.[13]?.data?.engagedChallenges.includes('game') )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataSG?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[13]?.data
              : null
            : null;
        }
        // stock quiz
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('stock') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('quiz') ) ||
          (CompareToData?.[15]?.data?.engagedChallenges.includes('stock') &&
            CompareToData?.[15]?.data?.engagedChallenges.includes('quiz') )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataSQ?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[15]?.data
              : null
            : null;
        }
        // game quiz
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('game') &&
            selectedCompareWeekData?.[0]?.engagedChallenges.includes('quiz') ) ||
          (CompareToData?.[3]?.data?.engagedChallenges.includes('game') &&
            CompareToData?.[3]?.data?.engagedChallenges.includes('quiz') )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataGQ?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[3]?.data
              : null
            : null;
        }
        // 1 activity 
        // plano
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('plano') ) ||
          (CompareToData?.[4]?.data?.engagedChallenges.includes('plano')  )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataP?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[4]?.data
              : null
            : null;
        }
        // stock
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('stock') ) ||
          (CompareToData?.[12]?.data?.engagedChallenges.includes('stock')  )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataS?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[12]?.data
              : null
            : null;
        }
        // game
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('game') ) ||
          (CompareToData?.[2]?.data?.engagedChallenges.includes('game')  )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataG?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[2]?.data
              : null
            : null;
        }
        // quiz
        else if (
          (selectedCompareWeekData?.[0]?.engagedChallenges.includes('quiz') ) ||
          (CompareToData?.[11]?.data?.engagedChallenges.includes('quiz')  )
        ) {
          comparisonData = isCompare
            ? CompareToRender === 'Custom'
              ? customCompareDataQ?.[0]
              : CompareToRender === 'Week'
              ? CompareToData?.[11]?.data
              : null
            : null;
        }
     

        break;
      default:
        primaryData = 0;
        comparisonData = 0;
        break;
    }
    console.log("primaryyyyyyyy", primaryData);

    console.log("compariiiiiiiiii", comparisonData);

    setValue1(primaryData?.percentageEngaged);
    setValue2(isCompare ? comparisonData?.percentageEngaged : null);
    setIncrease(isCompare && primaryData !== null && comparisonData !== null ? (primaryData?.percentageEngaged - comparisonData?.percentageEngaged).toFixed(2) : null); // Update increase state
    setIsLoading(false);
  }, [
    customDataPSG, 
    customCompareDataPSG, 
    customDataPQG, 
    customCompareDataPQG, 
    customDataPSQ, 
    customCompareDataPSQ, 
    customDataSGQ, 
    customCompareDataSGQ,
    ///////////////////////////////////
    customDataPS,
    customCompareDataPS,
    customDataPQ,
    customCompareDataPQ,
    customDataPG,
    customCompareDataPG,
    customDataSQ,
    customCompareDataSQ,
    customDataSG,
    customCompareDataSG,
    customDataGQ,
    customCompareDataGQ,
    customDataP,
    customCompareDataP,
    customDataS,
    customCompareDataS,
    customDataG,
    customCompareDataG,
    customDataQ,
    customCompareDataQ,  
    selectedWeekData,
    selectedCompareWeekData,
    weekData, 
    CompareToData,
    weekLoading,
    CompareToDataIsLoading, 
    selectedWeekDataIsLoading,
    selectedCompareWeekDataIsLoading,
    increase, 
    isCompare,
    compareStartDate,
    compareEndDate,
    startDate,
    endDate
  ]);

  useEffect(() => {
    refetchWeekData();
    refetchCompareToData();
  },[weekId,weekCompareId])

  


  return (
    <Box
      gridColumn="span 1"
      gridRow="span 1"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor='#FFFFFF'
      borderRadius="0.55rem"
    >
      <FlexBetween margin='0rem 0rem 0.75rem 0rem'>
        <FlexBetween gap="0.75rem">
          <img src={EngagedUsersSVG} alt="Engaged Users" width="36" height="36" />
          <Typography variant="subtitle1" sx={{ color: theme.palette.secondary[100], fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
            {title}
          </Typography>
        </FlexBetween>
        <Tooltip title={help} arrow>
          <IconButton
            size="small"
            onMouseEnter={() => setShowHelp(true)}
            onMouseLeave={() => setShowHelp(false)}
          >
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </FlexBetween>

      {
        customDataPSGIsLoading || 
        customCompareDataPSGIsLoading || 
        customDataPQGIsLoading || 
        customCompareDataPQGIsLoading || 
        customDataPSQIsLoading || 
        customCompareDataPSQIsLoading || 
        customDataSGQIsLoading || 
        customCompareDataSGQIsLoading ||
        ////////////////////
        customDataPSIsLoading || 
        customCompareDataPSIsLoading ||
        customDataPQIsLoading || 
        customCompareDataPQIsLoading ||
        customDataPGIsLoading || 
        customCompareDataPGIsLoading ||
        customDataSQIsLoading || 
        customCompareDataSQIsLoading ||
        customDataSGIsLoading || 
        customCompareDataSGIsLoading ||
        customDataGQIsLoading || 
        customCompareDataGQIsLoading ||
        customDataPIsLoading || 
        customCompareDataPIsLoading ||
        customDataSIsLoading || 
        customCompareDataSIsLoading ||
        customDataGIsLoading || 
        customCompareDataGIsLoading ||
        customDataQIsLoading || 
        weekLoading || 
        CompareToDataIsLoading
        ? (
        <Typography
          variant="h3"
          fontWeight="600"
          sx={{ color: theme.palette.secondary[200] }}
        >
          Loading...
        </Typography>
      ) : (
        <>
        <FlexBetween 
            gap="1rem"
            >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p="0.25rem"
          >
          <Typography
            variant="h3"
            fontWeight="600"
            sx={{ 
              color: theme.palette.secondary[200],
              fontSize: '1.3rem', 
            }}
          >
            {value1 + " %"}
          </Typography>
          {isCompare && (
          <FlexBetween
            gap="0.5rem"
          >
            <Typography
              variant="h6"
              fontStyle="italic"
              
              display="flex"
              alignItems="center" // Align items vertically in the center
              sx={{
                color: increase !== null ? (increase < 0 ? theme.palette.error.main : theme.palette.success.main) : theme.palette.secondary.light,
                fontSize: '0.87rem',
              }}
            >
              {increase !== null && (
                <>
                  {increase > 0 ? <TrendingUpIcon sx={{ marginRight: '0.2rem' }} /> : <TrendingDownIcon sx={{ marginRight: '0.2rem' }} />}
                  {`${Math.abs(increase)} %`}
                </>
              )}
            </Typography>
            <Typography
              variant="h6"
              fontWeight="400"
              sx={{
                color: theme.palette.secondary[200],
                fontSize: '0.87rem',
              }}
            >
              vs {value2} %
            </Typography>
          </FlexBetween>
          )}

          </Box>
          
        </FlexBetween>
        </>
      )}
    </Box>
  );
}

export default StatBoxEngaged;
