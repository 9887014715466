import React from 'react';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const DownloadButton = () => {
  const handleDownload = async () => {
    try {
      // Make a GET request to initiate file download
      const response = await fetch(`${process.env.REACT_APP_API_URL}/download`);
      
      // Check if the request was successful
      if (response.ok) {
        // Create a blob from the response data
        const blob = await response.blob();

        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'weekly_report.html'; // Specify the file name
        document.body.appendChild(link);

        // Click the link to start the download
        link.click();

        // Clean up after the download
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } else {
        console.error('Failed to download file');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <Button
      sx={{
        backgroundColor: "#00B185",
        color: '#FFFFFF',
        fontSize: "14px",
        fontWeight: "bold",
        padding: "8px 20px",
        '&:hover': {
          backgroundColor: "#00D5A0", // Change color on hover
        },
      }}
      onClick={handleDownload}
    >
      <FileDownloadOutlinedIcon sx={{ mr: "10px" }} />
      Export Reports
    </Button>
  );
};

export default DownloadButton;