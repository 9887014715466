import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";


const getUserToken = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("_auth"))
        ?.split("=")[1];
    return cookieValue ? decodeURIComponent(cookieValue) : null;
};
  
export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: (headers) => {
            headers.set("Content-Type", "multipart/form-data")
            headers.set("Authorization", getUserToken());
            return headers;
        }
    }),
    reducerPath: "adminApi",
  tagTypes: ["User",
    "UserF",
    "Extensions",
    "LoggedMembers",
    "Products",
    "Customers",
    "Transactions",
    "Geography",
    "Sales",
    "Admins",
    "Performance",
    "Dashboard",
    "ActivemembersBetweenDates",
    "GlobalPercentageEngagedPQG",
    "GlobalPercentageEngagedPSG",
    "GlobalPercentageEngagedPSQ",
    "GlobalPercentageEngagedSGQ",
    /////////////
    "GlobalPercentageEngagedPS",
    "GlobalPercentageEngagedPG",
    "GlobalPercentageEngagedPQ",
    "GlobalPercentageEngagedSG",
    "GlobalPercentageEngagedSQ",
    "GlobalPercentageEngagedGQ",
    "GlobalPercentageEngagedP",
    "GlobalPercentageEngagedS",
    "GlobalPercentageEngagedG",
    "GlobalPercentageEngagedQ",
    "JpointsTotalbetweendates",
    "GlobalPercentageParticipationActivities",
    "RegionPercentageParticipationActivities",
    "GlobalPercentageParticipationSuccessPlanoeachday",
    "RegionPercentageParticipationSuccessPlano",
    "GlobalPercentageParticipationSuccessStockeachday",
    "RegionPercentageParticipationSuccessStock",
    "GlobalPercentageParticipationSuccessQuizeachday",
    "RegionPercentageParticipationSuccessQuiz",
    "RegionBARPercentageParticipationSuccessGame",
    "GlobalPercentageParticipationGameeachdayBetweendates",
    "RegionPercentageParticipationGameeachdayBetweendates",
    "Percentageuniqueparticipantsingame",
    "NumberLaunchGameBetweendates",
    "PercentageRedemptionBetweendates",
    "GAPageViews",
    "GAAverageEngagementTime",
    "MembersActiveParticipations",
    "WeeksList",
    "DownloadweeklyReport",
    "WeeksData",
    "getEngagedChallenges",
    "resetPassword"
  ],
    endpoints: (build) => ({
      // fake data endpoints
        getUserF: build.query({
          query: (id) => `general/user/${id}`,
          providesTags: ["UserF"],
        }),
        getProducts: build.query({
          query: () => "client/products",
          providesTags: ["Products"],
        }),
        getCustomers: build.query({
          query: () => "client/customers",
          providesTags:["Customers"],
        }),
        getTransactions: build.query({
          query: ({ page, pageSize, sort, search}) => ({
            url: "client/transactions",
            method: "GET",
            params: { page, pageSize, sort, search },
          }),
          providesTags:["Transactions"],
        }),
        getGeography: build.query({
          query: () => "client/geography",
          providesTags:["Geography"],
        }),
        getSales: build.query({
          query: () => "sales/sales",
          providesTags:["Sales"],
        }),
        getAdmins: build.query({
          query: () => "management/admins",
          providesTags:["Admins"],
        }),
        getUserPerformance: build.query({
          query: (id) => `management/performance/${id}`,
          providesTags:["Performance"],
        }),
        getDashboard: build.query({
          query: () => "general/dashboard",
          providesTags:["Dashboard"],
        }),
        //------------------------------------------------------ Jiby Data
        getUser: build.query({
            query: (id) => `user/${id}`,
            providesTags: ["User"],
        }),
        getLoggedInMembers: build.query({
            query: (id) => `aggr/logged`,
            providesTags: ["loggedInMembers"],
        }),
        getActivemembersBetweenDates: build.query({
          query: ({ startDate, endDate }) => ({
            url: 'aggr/ActivemembersBetweenDates',
            method: 'POST',
            params: { startDate , endDate}
          }),
          transformResponse: (response) => {
            return response;
          },
          providesTags: ["ActivemembersBetweenDates"],
          invalidatesTags : ["ActivemembersBetweenDates"],
        }),
        getGlobalPercentageEngagedPQG: build.query({
          query: ({ startDate, endDate }) => ({
            url: 'aggr/GlobalPercentageEngagedPQG',
            method: 'POST',
            params: { startDate , endDate}
          }),
          transformResponse: (response) => {
            return response;
          },
          providesTags: ["GlobalPercentageEngagedPQG"],
          invalidatesTags : ["GlobalPercentageEngagedPQG"],
      }),
      getGlobalPercentageEngagedPSG: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageEngagedPSG',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageEngagedPSG"],
        invalidatesTags : ["GlobalPercentageEngagedPSG"],
      }),
      getGlobalPercentageEngagedPSQ: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageEngagedPSQ',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageEngagedPSQ"],
        invalidatesTags : ["GlobalPercentageEngagedPSQ"],
      }),
      getGlobalPercentageEngagedSGQ: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageEngagedSGQ',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageEngagedSGQ"],
        invalidatesTags : ["GlobalPercentageEngagedSGQ"],
      }),
      /////////////////////////////////////////////////////////////////////////
      getGlobalPercentageEngagedPS: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageEngagedPS',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageEngagedPS"],
        invalidatesTags : ["GlobalPercentageEngagedPS"],
      }),
      getGlobalPercentageEngagedPG: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageEngagedPG',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageEngagedPG"],
        invalidatesTags : ["GlobalPercentageEngagedPG"],
      }),
      getGlobalPercentageEngagedPQ: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageEngagedPQ',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageEngagedPQ"],
        invalidatesTags : ["GlobalPercentageEngagedPQ"],
      }),
      getGlobalPercentageEngagedSG: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageEngagedSG',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageEngagedSG"],
        invalidatesTags : ["GlobalPercentageEngagedSG"],
      }),
      getGlobalPercentageEngagedSQ: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageEngagedSQ',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageEngagedSQ"],
        invalidatesTags : ["GlobalPercentageEngagedSQ"],
      }),
      getGlobalPercentageEngagedGQ: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageEngagedGQ',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageEngagedGQ"],
        invalidatesTags : ["GlobalPercentageEngagedGQ"],
      }),
      getGlobalPercentageEngagedP: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageEngagedP',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageEngagedP"],
        invalidatesTags : ["GlobalPercentageEngagedP"],
      }),
      getGlobalPercentageEngagedS: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageEngagedS',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageEngagedS"],
        invalidatesTags : ["GlobalPercentageEngagedS"],
      }),
      getGlobalPercentageEngagedG: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageEngagedG',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageEngagedG"],
        invalidatesTags : ["GlobalPercentageEngagedG"],
      }),
      getGlobalPercentageEngagedQ: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageEngagedQ',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageEngagedQ"],
        invalidatesTags : ["GlobalPercentageEngagedQ"],
      }),
      getJpointsTotalbetweendates: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/JpointsTotalbetweendates',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["JpointsTotalbetweendates"],
        invalidatesTags : ["JpointsTotalbetweendates"],
      }),
      getGlobalPercentageParticipationActivities: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageParticipationActivities',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageParticipationActivities"],
        invalidatesTags : ["GlobalPercentageParticipationActivities"],
      }),
      getRegionPercentageParticipationActivities: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/RegionPercentageParticipationActivities',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["RegionPercentageParticipationActivities"],
        invalidatesTags : ["RegionPercentageParticipationActivities"],
      }),
      getGlobalPercentageParticipationSuccessPlanoeachday: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageParticipationSuccessPlanoeachday',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageParticipationSuccessPlanoeachday"],
        invalidatesTags : ["GlobalPercentageParticipationSuccessPlanoeachday"],
      }),
      getRegionPercentageParticipationSuccessPlano: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/RegionPercentageParticipationSuccessPlano',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["RegionPercentageParticipationSuccessPlano"],
        invalidatesTags : ["RegionPercentageParticipationSuccessPlano"],
      }),
      getGlobalPercentageParticipationSuccessStockeachday: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageParticipationSuccessStockeachday',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageParticipationSuccessStockeachday"],
        invalidatesTags : ["GlobalPercentageParticipationSuccessStockeachday"],
      }),
      getRegionPercentageParticipationSuccessStock: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/RegionPercentageParticipationSuccessStock',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["RegionPercentageParticipationSuccessStock"],
        invalidatesTags : ["RegionPercentageParticipationSuccessStock"],
      }),
      getGlobalPercentageParticipationSuccessQuizeachday: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageParticipationSuccessQuizeachday',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageParticipationSuccessQuizeachday"],
        invalidatesTags : ["GlobalPercentageParticipationSuccessQuizeachday"],
      }),
      getRegionPercentageParticipationSuccessQuiz: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/RegionPercentageParticipationSuccessQuiz',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["RegionPercentageParticipationSuccessQuiz"],
        invalidatesTags : ["RegionPercentageParticipationSuccessQuiz"],
      }),
      getRegionBARPercentageParticipationSuccessGame: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/RegionBARPercentageParticipationSuccessGame',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["RegionBARPercentageParticipationSuccessGame"],
        invalidatesTags : ["RegionBARPercentageParticipationSuccessGame"],
      }),
      getGlobalPercentageParticipationGameeachdayBetweendates: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/GlobalPercentageParticipationGameeachdayBetweendates',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GlobalPercentageParticipationGameeachdayBetweendates"],
        invalidatesTags : ["GlobalPercentageParticipationGameeachdayBetweendates"],
      }),
      getPercentageuniqueparticipantsingame : build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/Percentageuniqueparticipantsingame',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["Percentageuniqueparticipantsingame"],
        invalidatesTags : ["Percentageuniqueparticipantsingame"],
      }),
      getRegionPercentageParticipationGameeachdayBetweendates: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/RegionPercentageParticipationGameeachdayBetweendates',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["RegionPercentageParticipationGameeachdayBetweendates"],
        invalidatesTags : ["RegionPercentageParticipationGameeachdayBetweendates"],
      }),
      getNumberLaunchGameBetweendates: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/NumberLaunchGameBetweendates',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["NumberLaunchGameBetweendates"],
        invalidatesTags : ["NumberLaunchGameBetweendates"],
      }),
      getPercentageRedemptionBetweendates: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/PercentageRedemptionBetweendates',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["PercentageRedemptionBetweendates"],
        invalidatesTags : ["PercentageRedemptionBetweendates"],
      }),
      getGAPageViews: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'google/views',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GAPageViews"],
        invalidatesTags : ["GAPageViews"],
      }),
      getGAAverageEngagementTime: build.query({
        query: ({ startDate, endDate }) => ({
          url: 'google/sessionduration',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["GAAverageEngagementTime"],
        invalidatesTags : ["GAAverageEngagementTime"],
      }),
      getMembersActiveParticipations: build.query({
        query: ({ page, pageSize, sort, search}) => ({
          url: 'aggr/GetMembersActiveParticipations',
          method: 'GET',
          params: { page, pageSize, sort, search}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["MembersActiveParticipations"],
        invalidatesTags : ["MembersActiveParticipations"],
      }),
      getEngagedChallenges : build.query({
        query: ({ startDate, endDate }) => ({
          url: 'aggr/findEngagedChallenges',
          method: 'POST',
          params: { startDate , endDate}
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["getEngagedChallenges"],
        invalidatesTags : ["getEngagedChallenges"],
      }),
      /* WEEKS DATA */
      getWeeksList: build.query({
        query: () => ({
          url: 'weeks/getWeeksList',
          method: 'GET',
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["WeeksList"],
        invalidatesTags : ["WeeksList"],

      }),
      GETWeeklyData: build.query({
        async queryFn({ weekId }, _queryApi, _extraOptions, fetchWithBQ) {
            
            try { 

              const week = [];
                // Fetch data with parameters
                const RegionBARPercentageParticipationSuccessGame = await fetchWithBQ(`/weeks/getRegionBARPercentageParticipationSuccessGame?weekId=${weekId}`);
                const Activememberseachweek = await fetchWithBQ(`/weeks/getActivememberseachweek?weekId=${weekId}`);
                const GlobalPercentageEngagedGameeachweek = await fetchWithBQ(`/weeks/getGlobalPercentageEngagedGameeachweek?weekId=${weekId}`);
                const GlobalPercentageEngagedGameQuizeachweek = await fetchWithBQ(`/weeks/getGlobalPercentageEngagedGameQuizeachweek?weekId=${weekId}`);
                const GlobalPercentageEngagedPlano = await fetchWithBQ(`/weeks/getGlobalPercentageEngagedPlano?weekId=${weekId}`);
                const GlobalPercentageEngagedPlanoGame = await fetchWithBQ(`/weeks/getGlobalPercentageEngagedPlanoGame?weekId=${weekId}`);
                const GlobalPercentageEngagedPlanoQuiz = await fetchWithBQ(`/weeks/getGlobalPercentageEngagedPlanoQuiz?weekId=${weekId}`);
                const GlobalPercentageEngagedPlanoQuizGame = await fetchWithBQ(`/weeks/getGlobalPercentageEngagedPlanoQuizGame?weekId=${weekId}`);
                const GlobalPercentageEngagedPlanoStock = await fetchWithBQ(`/weeks/getGlobalPercentageEngagedPlanoStock?weekId=${weekId}`);
                const GlobalPercentageEngagedPlanoStockGame = await fetchWithBQ(`/weeks/getGlobalPercentageEngagedPlanoStockGame?weekId=${weekId}`);
                const GlobalPercentageEngagedPlanoStockQuiz = await fetchWithBQ(`/weeks/getGlobalPercentageEngagedPlanoStockQuiz?weekId=${weekId}`);
                const GlobalPercentageEngagedQuiz = await fetchWithBQ(`/weeks/getGlobalPercentageEngagedQuiz?weekId=${weekId}`);
                const GlobalPercentageEngagedStock = await fetchWithBQ(`/weeks/getGlobalPercentageEngagedStock?weekId=${weekId}`);
                const GlobalPercentageEngagedStockGame = await fetchWithBQ(`/weeks/getGlobalPercentageEngagedStockGame?weekId=${weekId}`);
                const GlobalPercentageEngagedStockGameQuiz = await fetchWithBQ(`/weeks/getGlobalPercentageEngagedStockGameQuiz?weekId=${weekId}`);
                const GlobalPercentageEngagedStockQuiz = await fetchWithBQ(`/weeks/getGlobalPercentageEngagedStockQuiz?weekId=${weekId}`);
                const JpointsTotal = await fetchWithBQ(`/weeks/getJpointsTotal?weekId=${weekId}`);
                const GlobalPercentageParticipationActivities = await fetchWithBQ(`/weeks/getGlobalPercentageParticipationActivities?weekId=${weekId}`);
                const RegionPercentageParticipationActivities = await fetchWithBQ(`/weeks/getRegionPercentageParticipationActivities?weekId=${weekId}`);
                const GlobalPercentageParticipationSuccessPlanoeachday = await fetchWithBQ(`/weeks/getGlobalPercentageParticipationSuccessPlanoeachday?weekId=${weekId}`);
                const RegionPercentageParticipationSuccessPlano = await fetchWithBQ(`/weeks/getRegionPercentageParticipationSuccessPlano?weekId=${weekId}`);
                const GlobalPercentageParticipationSuccessStockeachday = await fetchWithBQ(`/weeks/getGlobalPercentageParticipationSuccessStockeachday?weekId=${weekId}`);
                const RegionPercentageParticipationSuccessStock = await fetchWithBQ(`/weeks/getRegionPercentageParticipationSuccessStock?weekId=${weekId}`);
                const GlobalPercentageParticipationSuccessQuizeachday = await fetchWithBQ(`/weeks/getGlobalPercentageParticipationSuccessQuizeachday?weekId=${weekId}`);
                const RegionPercentageParticipationSuccessQuiz = await fetchWithBQ(`/weeks/getRegionPercentageParticipationSuccessQuiz?weekId=${weekId}`);
                const GlobalPercentageParticipationGameeachdayeachweek = await fetchWithBQ(`/weeks/getGlobalPercentageParticipationGameeachdayeachweek?weekId=${weekId}`);
                const RegionPercentageParticipationGameeachdayeachweek = await fetchWithBQ(`/weeks/getRegionPercentageParticipationGameeachdayeachweek?weekId=${weekId}`);
                const RegionBarPercentageParticipationGameeachweek = await fetchWithBQ(`/weeks/getRegionBARPercentageParticipationSuccessGame?weekId=${weekId}`);
                const NumberLaunchGameeachweek = await fetchWithBQ(`/weeks/getNumberLaunchGameeachweek?weekId=${weekId}`);
                const PercentageRedemptioneachweek = await fetchWithBQ(`/weeks/getPercentageRedemptioneachweek?weekId=${weekId}`);
                const Percentageuniqueparticipantsingame = await fetchWithBQ(`/weeks/getPercentageuniqueparticipantsingame?weekId=${weekId}`);
                
                // Store fetched data in the week array
                week.push({
                  kpi : "Weekly_RegionBARPercentageParticipationSuccessGame",
                  data : RegionBARPercentageParticipationSuccessGame.data
                });
                week.push({
                  kpi : "Weekly_Activememberseachweek",
                  data: Activememberseachweek.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageEngagedGameeachweek",
                  data: GlobalPercentageEngagedGameeachweek.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageEngagedGameQuizeachweek",
                  data: GlobalPercentageEngagedGameQuizeachweek.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageEngagedPlano",
                  data: GlobalPercentageEngagedPlano.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageEngagedPlanoGame",
                  data: GlobalPercentageEngagedPlanoGame.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageEngagedPlanoQuiz",
                  data: GlobalPercentageEngagedPlanoQuiz.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageEngagedPlanoQuizGame",
                  data: GlobalPercentageEngagedPlanoQuizGame.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageEngagedPlanoStock",
                  data: GlobalPercentageEngagedPlanoStock.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageEngagedPlanoStockGame",
                  data: GlobalPercentageEngagedPlanoStockGame.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageEngagedPlanoStockQuiz",
                  data: GlobalPercentageEngagedPlanoStockQuiz.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageEngagedQuiz",
                  data: GlobalPercentageEngagedQuiz.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageEngagedStock",
                  data: GlobalPercentageEngagedStock.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageEngagedStockGame",
                  data: GlobalPercentageEngagedStockGame.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageEngagedStockGameQuiz",
                  data: GlobalPercentageEngagedStockGameQuiz.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageEngagedStockQuiz",
                  data: GlobalPercentageEngagedStockQuiz.data
                });
                week.push({
                  kpi : "Weekly_JpointsTotal",
                  data: JpointsTotal.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageParticipationActivities",
                  data: GlobalPercentageParticipationActivities.data
                });
                week.push({
                  kpi : "Weekly_RegionPercentageParticipationActivities",
                  data: RegionPercentageParticipationActivities.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageParticipationSuccessPlanoeachday",
                  data: GlobalPercentageParticipationSuccessPlanoeachday.data
                });
                week.push({
                  kpi : "Weekly_RegionPercentageParticipationSuccessPlano",
                  data: RegionPercentageParticipationSuccessPlano.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageParticipationSuccessStockeachday",
                  data: GlobalPercentageParticipationSuccessStockeachday.data
                });
                week.push({
                  kpi : "Weekly_RegionPercentageParticipationSuccessStock",
                  data: RegionPercentageParticipationSuccessStock.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageParticipationSuccessQuizeachday",
                  data: GlobalPercentageParticipationSuccessQuizeachday.data
                });
                week.push({
                  kpi : "Weekly_RegionPercentageParticipationSuccessQuiz",
                  data: RegionPercentageParticipationSuccessQuiz.data
                });
                week.push({
                  kpi : "Weekly_GlobalPercentageParticipationGameeachdayeachweek",
                  data: GlobalPercentageParticipationGameeachdayeachweek.data
                });
                week.push({
                  kpi : "Weekly_RegionPercentageParticipationGameeachdayeachweek",
                  data: RegionPercentageParticipationGameeachdayeachweek.data
                });
                week.push({
                  kpi : "Weekly_RegionBarPercentageParticipationGameeachweek",
                  data: RegionBarPercentageParticipationGameeachweek.data
                });
                week.push({
                  kpi : "Weekly_NumberLaunchGameeachweek",
                  data: NumberLaunchGameeachweek.data
                });
                week.push({
                  kpi : "Weekly_PercentageRedemptioneachweek",
                  data: PercentageRedemptioneachweek.data
                });
                week.push({
                  kpi : "Weekly_Percentageuniqueparticipantsingame",
                  data: Percentageuniqueparticipantsingame.data
                });
              
                return { data: week }
            } catch (error) {
                // Handle errors
                console.error("Error fetching data:", error);
                // Re-throw the error to be handled by the caller
                throw error;
            }
        },
        providesTags: ["WeeksData"],
        invalidatesTags: ["WeeksData"],
    }),
        // Add more build.query calls for additional endpoints if needed
      /* OTHER FUNCTIONALITIES */ 
      downloadWeeklyReport: build.query({
        query: () => ({
          url: 'download',
          method: 'GET',
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["DownloadweeklyReport"],
        invalidatesTags : ["DownloadweeklyReport"],

      }),
      resetPassword : build.query({
        query: ({ email }) => ({
          // reset url
          url: 'user/resetPassword',
          method: 'POST',
          params: { email }
        }),
        transformResponse: (response) => {
          return response;
        },
        providesTags: ["resetPassword"],
        invalidatesTags : ["resetPassword"],
      }),
    }),
});


export const {
  useGetUserQuery,
  useGetUserFQuery,
  useGetLoggedInMembersQuery,
  useGetProductsQuery,
  useGetCustomersQuery,
  useGetTransactionsQuery,
  useGetGeographyQuery,
  useGetSalesQuery,
  useGetAdminsQuery,
  useGetUserPerformanceQuery,
  useGetDashboardQuery,
  useGetActivemembersBetweenDatesQuery,
  useGetGlobalPercentageEngagedPQGQuery,
  useGetGlobalPercentageEngagedPSGQuery,
  useGetGlobalPercentageEngagedPSQQuery,
  useGetGlobalPercentageEngagedSGQQuery,
  //////////////
  useGetGlobalPercentageEngagedPSQuery,
  useGetGlobalPercentageEngagedPGQuery,
  useGetGlobalPercentageEngagedPQQuery,
  useGetGlobalPercentageEngagedSGQuery,
  useGetGlobalPercentageEngagedSQQuery,
  useGetGlobalPercentageEngagedGQQuery,
  useGetGlobalPercentageEngagedPQuery,
  useGetGlobalPercentageEngagedSQuery,
  useGetGlobalPercentageEngagedGQuery,
  useGetGlobalPercentageEngagedQQuery,
  useGetJpointsTotalbetweendatesQuery,
  useGetGlobalPercentageParticipationActivitiesQuery,
  useGetRegionPercentageParticipationActivitiesQuery,
  useGetGlobalPercentageParticipationSuccessPlanoeachdayQuery,
  useGetRegionPercentageParticipationSuccessPlanoQuery,
  useGetGlobalPercentageParticipationSuccessStockeachdayQuery,
  useGetRegionPercentageParticipationSuccessStockQuery,
  useGetGlobalPercentageParticipationSuccessQuizeachdayQuery,
  useGetRegionPercentageParticipationSuccessQuizQuery,
  useGetRegionBARPercentageParticipationSuccessGameQuery,
  useGetGlobalPercentageParticipationGameeachdayBetweendatesQuery,
  useGetRegionPercentageParticipationGameeachdayBetweendatesQuery,
  useGetPercentageuniqueparticipantsingameQuery,
  useGetNumberLaunchGameBetweendatesQuery,
  useGetPercentageRedemptionBetweendatesQuery,
  useGetGAPageViewsQuery,
  useGetGAAverageEngagementTimeQuery,
  useGetMembersActiveParticipationsQuery,
  useGetWeeksListQuery,
  useDownloadWeeklyReportQuery,
  useGETWeeklyDataQuery,
  useGetEngagedChallengesQuery,
  useResetPasswordQuery
} = api;

