import { InputAdornment, FormControl, Box, Button, Container, CssBaseline, TextField, Typography } from '@mui/material';
import { LockOutlined, EmailOutlined } from '@mui/icons-material';
import { useTheme, useMediaQuery } from '@mui/material';
import InputFieldWezign from "../components/Input";
import { Link } from 'react-router-dom';

import Email from '../assets/email_jti.png';
import Lock from '../assets/lock_jti.png'
import BgSVG from '../assets/backgroundIntro.svg';
import IconSVG from '../assets/Logo_jti.png';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { setUserInfo, signIn } from 'state';
import { useNavigate } from 'react-router-dom';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import FlexBetween from 'components/FlexBetween';

function Login() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [token, setToken] = useState('');
  const [waiting, setIsWaiting] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  const signInFunction = useSignIn();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const handleSignIn = async () => {
      if (isSignedIn) {
        try {
          await signInFunction({
            auth: {
              token: token.token,
              type: 'Bearer',
            }
          });
          sessionStorage.setItem('token', token.token);
          sessionStorage.setItem('userData', JSON.stringify({
            userId : token.userId,
            userName: token.name,
            userEmail: token.userEmail,
            userRole: token.role,
            userLastName: token.lastName,
            img : token.img,
          }));
          dispatch(signIn(token.token));
          dispatch(
            setUserInfo({
              userId: token.userId,
              userName: token.name,
              userEmail: token.userEmail,
              userRole: token.role,
              userLastName: token.lastName,
              img : token.img,
            })
          );
          navigate('/');
        } catch (error) {
          console.error('Error during sign-in:', error);
        }
      }
    };

    handleSignIn();
  }, [isSignedIn, navigate, signInFunction, token, dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const userData = {
      email: document.querySelector('#email').value,
      password: document.querySelector('#password').value,
    };

    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      const response = await axios.post(`${apiUrl}/user/signin`, userData, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', // Set the allowed origin for the request
        },
      });
      if (response.status === 201) {
        
        dispatch(signIn(response.data.token));
          dispatch(
            setUserInfo({
              userId: response.data.userId,
              userName: response.data.name,
              userEmail: response.data.userEmail,
              userRole: response.data.role,
              userLastName: token.lastName,
              img : token.img,
            })
          );
        //const responseData = await response.json();
        setToken(response.data);
        setIsSignedIn(true);
      } else {
        
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'Unknown error occurred');
    } finally {
      setIsWaiting(false);
    }
  };

  return (
    <Box className='Intro'  sx={{ display: 'flex', height: '100vh'  }}>
      {/* Left side without background image */}
      <Box 
        sx={{ 
          display: 'flex', 
          flex: 1, 
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent : "space-around",
          padding : "2em 0em 2em 0em",
          userSelect :"none"
        }}>
        <img className='LogoIntro_Wezign'  src={IconSVG} alt="Icon" style={{  userSelect:"none" , WebkitUserSelect: "none" , userDrag :"none"}} />
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems : "center",
            userSelect : "none"
          }} >
          <Typography 
            component="h1" 
            variant="h3"
            className='Wlcm_wezign' 
            sx={{ 
              fontSize: 30,
              fontFamily: 'Roboto',
              fontWeight: 'bold',
              marginBottom: '10px', 
              marginTop: '20px'
            }}
          >
            Welcome Back
          </Typography>
          <Typography 
            component="h1" 
            variant="h3" 
            sx={{ 
              fontSize: 30,
              fontFamily: 'Roboto',
              fontWeight: 'bold',
              color:'#06B085',
              marginBottom: '10px', 
              marginTop: '10px' 
              }}
            >
            Login
          </Typography>
          </Box>
          <form onSubmit={handleSubmit} variant="standard" style={{ width: '100%' }}>
            <Box sx={{ width: '100%', margin: '0 auto' }}>
              <Box sx={{
                display : "flex",
                flexDirection : "column",
                alignItems: "center",
                gap : "1em",
                margin : "2em 0em 2em 0em"
              }} >
              <InputFieldWezign type="email" name="email" id="email" img={Email} color={"#06B085"} placeHolder="Email address"/>
              <InputFieldWezign type="password" name="password" id="password" img={Lock} color={"#06B085"} placeHolder="Password" />
              </Box>
            {/* <TextField
                variant='outlined'
                margin="normal"
                size='small'
                required
                fullWidth
                id="email"
                placeholder="E-mail Address"
                name="email"
                autoComplete="email"
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" >
                      <img src={Email} alt="Icon" style={{ width: '50%', height: 'auto',  }} />
                      <div style={{ borderRight: '15px solid black', height: '100%', marginLeft: '5px', transform: 'rotate(90deg)', }}></div>
                    </InputAdornment>
                  ),
                  
                  style: {
                    borderRadius: '25px',
                    border: '1px solid #008FFB',
                  },
                }}
                sx={{
                  marginBottom: '2px',
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              />
              <TextField
                variant='outlined'
                margin="normal"
                size='small'
                required
                fullWidth
                name="password"
                placeholder="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={Lock} alt="Icon" style={{ width: '50%', height: 'auto' }} />
                      <div style={{ borderRight: '15px solid black', height: '100%', marginLeft: '5px', transform: 'rotate(90deg)', }}></div>

                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: '25px',
                    border: '1px solid #008FFB',

                  },
                }}
                sx={{
                  marginBottom: '30px',
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              /> */}
              {error && (
                <Box sx={{
                  display:"flex",
                  justifyContent:"center"
                }} >
                  <Typography variant="body2" style={{ color: 'red', marginBottom: '16px' }}>
                  {error}
                </Typography>
                </Box>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: '#06B085',
                  borderRadius: '77px',
                  marginBottom: '20px',
                  width: "17em",
                  height: "3em",
                  fontSize: "larger",
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  border: '1px solid #06B085',

                  '&:hover': {
                    backgroundColor: '#06B085',
                  },
                }}
                disabled={waiting}
              >
                {waiting ? 'Logging in...' : 'Log in'}
              </Button>
              
              
            </Box>
          </form>

          <Box>
          <Typography 
                align="center"
                className='Wlcm_wezign'
                sx={{
                  fontSize: 12,
                  fontFamily: 'Roboto',
                  fontWeight: 'regular',
                  color: 'rgba(0, 0, 0, 0.4)',
                }}
              >
                I hereby confirm that I am over 18 years old and <br />
                using my own login details and that <br />
                I am a tobacco merchant.
              </Typography>
              <Box sx={{ textAlign: 'center' }}>
               { <Typography className='Wlcm_wezign' sx={{
                  fontWeight : '500'
                }} >
                Did you forget your password? <Link style={{color :"#06B085" }} to="/reset">Reset it</Link>
                </Typography>}
                <Typography 
                align="center"
                className='Wlcm_wezign'
                sx={{
                  fontSize: 9,
                  fontFamily: 'Roboto',
                  fontWeight: 'regular',
                  color: 'rgba(0, 0, 0, 0.4)',
                  marginTop: '2em'
                }}
              >
                Developed by W.User Agency Algeria<br />
              </Typography>
              </Box>
          </Box>
      </Box>
      {/* Right side with background image */}
      <Box 
      className='rightSideLogin'
        sx={{
          flex: '1',
          width: '100%', // Set width to 100% on mobile, otherwise 50%
          height: '100%', // Always take up all the height of the screen
        }}
      >
        <img
          src={BgSVG}
          alt="Background"
          style={{
            width: '100%', // Ensure the image takes up the entire width of its container
            height: '100%', // Ensure the image takes up the entire height of its container
            objectFit: 'cover',
          }}
        />
      </Box>
    </Box>
  ) 

      
}

export default Login;


/* 

<Button href="#" 
                  sx={{
                    display: 'inline-block',
                    color: 'black', 
                    fontSize: 12,
                    fontFamily: 'Roboto',
                    fontWeight: 'regular',
                    backgroundColor: 'customColor',
                    '&:hover': {
                      backgroundColor: 'customColorHover',
                    }
                  }}
                >
                  Did you forget your password?
                </Button>
                <Button 
                  sx={{ 
                    color: '#008FFB', 
                    fontSize: 12,
                    fontFamily: 'Roboto',
                    fontWeight: 'regular',
                    '& .MuiButton-label': { 
                      color: 'inherit' 
                    } 
                  }} 
                  href="#"
                >
                  Reset it
                </Button>
*/



/* 

(
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <img
          src={BgSVG}
          alt="Background"
          style={{
            position: 'absolute',
            width: '100%', // Ensure the image takes up the entire width of its container
            height: '100%', // Ensure the image takes up the entire height of its container
            objectFit: 'contain',
          }}
        />
        <Box 
          sx={{ 
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '1',
            flex: 1, 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          <Box 
            sx={{ 
              flex: 1, 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
              flexDirection: 'column',
              margin: '5vh'
            }}>
              <img src={IconSVG} alt="Icon" style={{ width: '50%', height: 'auto' }} />
              <Typography 
                component="h1" 
                variant="h3" 
                sx={{ 
                  fontSize: 32,
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                  marginBottom: '10px', 
                  marginTop: '20px',
                  color: 'white'
                }}
              >
                Welcome Back
              </Typography>
              <Typography 
                component="h1" 
                variant="h3" 
                sx={{ 
                  fontSize: 32,
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                  color:'#008FFB',
                  marginBottom: '10px', 
                  marginTop: '10px' 
                  }}
                >
                Login
              </Typography>
              <form onSubmit={handleSubmit} variant="standard" style={{ width: '100%' }}>
                <Box sx={{ width: '100%', margin: '0 auto' }}>
                <InputFieldWezign />
                {/* <TextField
                    variant='outlined'
                    margin="normal"
                    size='small'
                    required
                    fullWidth
                    id="email"
                    placeholder="E-mail Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" >
                          <img src={Email} alt="Icon" style={{ width: '50%', height: 'auto',  }} />
                          <div style={{ borderRight: '15px solid black', height: '100%', marginLeft: '5px', transform: 'rotate(90deg)', }}></div>
                        </InputAdornment>
                      ),
                      
                      style: {
                        borderRadius: '25px',
                        border: '1px solid #008FFB',
                        backgroundColor: 'white'

                      },
                    }}
                    sx={{
                      marginBottom: '2px',
                      width: '70%',
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: 'auto',
                      marginRight: 'auto'
                    }}
                  /> 
                   <TextField
                    variant='outlined'
                    margin="normal"
                    size='small'
                    required
                    fullWidth
                    name="password"
                    placeholder="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={Lock} alt="Icon" style={{ width: '50%', height: 'auto' }} />
                          <div style={{ borderRight: '15px solid black', height: '100%', marginLeft: '5px', transform: 'rotate(90deg)', }}></div>

                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: '25px',
                        border: '1px solid #008FFB',
                        backgroundColor: 'white'

                      },
                    }}
                    sx={{
                      marginBottom: '30px',
                      width: '70%',
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: 'auto',
                      marginRight: 'auto'
                    }}
                  /> 


                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      color: 'white',
                      backgroundColor: '#008FFB',
                      borderRadius: '77px',
                      marginBottom: '20px',
                      width: '70%',
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      border: '1px solid #008FFB',

                      '&:hover': {
                        backgroundColor: '#4A77F7',
                      },
                    }}
                    disabled={waiting}
                  >
                    {waiting ? 'Logging in...' : 'Log in'}
                  </Button>
                  <Typography 
                    align="center"
                    sx={{
                      fontSize: 12,
                      fontFamily: 'Roboto',
                      fontWeight: 'regular',
                      color: 'white',
                      width: '100% '
                    }}
                  >
                    I hereby confirm that I am over 18 years old and <br />
                    using my own login details and that <br />
                    I am a tobacco merchant.
                  </Typography>
                  <Button href="#" 
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: 'black', 
                      fontSize: 12,
                      fontFamily: 'Roboto',
                      fontWeight: 'regular',
                      '& .MuiButton-root': { backgroundColor: 'customColor' } 
                      }}>
                    Did you forget your password? <Button sx={{ color: '#008FFB', '& .MuiButton-label': { color: 'inherit' } }}>Reset it</Button>
                  </Button>
                  {error && (
                    <Typography variant="body2" style={{ color: 'red', marginBottom: '16px' }}>
                      {error}
                    </Typography>
                  )}
                </Box>
              </form>
            </Box>

          </Box>
    </Box>
  ) 

*/