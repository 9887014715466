import React, { useEffect, useState } from "react";
import FlexBetween from "components/FlexBetween";
import { useSelector  } from "react-redux";
import Header from "components/Header";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  DownloadOutlined,
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
} from "@mui/icons-material";
import PageviewIcon from '@mui/icons-material/Pageview';
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
  Tooltip
} from "@mui/material";
import WeeklyPlanoBarChart from "components/WeeklyPlanoBarChart";
import WeeklyStockBarChart from "components/WeeklyStockBarChart";
import WeeklyQuizBarChart from "components/WeeklyQuizBarChart";
import WeeklyGameBarChart from "components/WeeklyGameBarChart";
import WeeklyPlanoLineChart from "components/WeeklyPlanoLineChart";
import WeeklyStockLineChart from "components/WeeklyStockLineChart";
import WeeklyQuizLineChart from "components/WeeklyQuizLineChart";
import WeeklyGameLineChart from "components/WeeklyGameLineChart";
import ActivitiesBarChart from "components/ActivitiesBarChart";
import StatBoxActive from "components/StatBoxActive";
import RadialChart from "components/RadialChart";
import { 
  useGetDashboardQuery, 
  useGetActivemembersBetweenDatesQuery,
  useGetGlobalPercentageEngagedPQGQuery,
  useGetGlobalPercentageEngagedPSGQuery,
  useGetNumberLaunchGameBetweendatesQuery,
  useGetGAPageViewsQuery,
  useGetGAAverageEngagementTimeQuery,
  useDownloadWeeklyReportQuery
} from "state/api";
import StatBox from "components/StatBox";
import StatBoxGame from "components/StatBoxGame";
import TimeComponent from "components/TimeComponent";
import NewTimeComponent from "components/NewTimeComponent";
import FilterActivities from "components/FilterActivities";
import FilterRegions from "components/FilterRegions";
import { END_DATE_CHANGED , START_DATE_CHANGED  } from "state";
import StatBoxPageViews from "components/StatBoxPageViews";
import StatBoxAverageTime from "components/StatBoxAverageTime";
import PercentageRedemptionChart from "components/PercentageRedemptionChart";
import JpointsChart from "components/JpointsChart";
import StatBoxEngaged from "components/StatBoxEngaged";
import GlobalActivitiesBarChart from "components/GlobalActivitesBarChart";
import DownloadButton from "components/DownloadButton";

const Dashboard = () => {
  const theme = useTheme();
  const isMediumScreens = useMediaQuery("(min-width: 791px) and (max-width: 1199px)");
  const isSmallScreens = useMediaQuery("(min-width: 499px) and (max-width: 790px)");
  const isExtraSmallScreens = useMediaQuery("(max-width: 499px)");
  const { data } = useGetDashboardQuery();
  const startDate = useSelector(state => state.global.startDate);
  const endDate = useSelector(state => state.global.endDate);
  const week = useSelector(state => state.global.weeklyDataState);
  const compareToData = useSelector(state => state.global.compareToData);
  const dataLoading = useSelector(state => state.global.dataLoading);
  const [selectedRegionActivities, setSelectedRegionActivities] = useState("national"); // State to store selected region
  const [selectedRegionPlano, setSelectedRegionPlano] = useState("national"); // State to store selected region
  const [selectedRegionStock, setSelectedRegionStock] = useState("national"); // State to store selected region
  const [selectedRegionQuiz, setSelectedRegionQuiz] = useState("national"); // State to store selected region
  const [selectedRegionGame, setSelectedRegionGame] = useState("national"); // State to store selected region
  const [showHelp, setShowHelp] = useState(false);

  //console.log('Week PROBLEM')
  //console.log(week);
  //console.log(compareToData);

  const { data : activeMembersData ,isLoading: activeMembersIsLoading ,  refetch: refetchActivemembers } = useGetActivemembersBetweenDatesQuery({ startDate : startDate, endDate : endDate }, {
    refetchOnMountOrArgChange: true
  });
  const { data : percentageEngagedPQGData ,isLoading: percentageEngagedPQGIsLoading  ,  refetch: refetchPercentageEngagedPQG } = useGetGlobalPercentageEngagedPQGQuery({ startDate : startDate, endDate : endDate }, {
    refetchOnMountOrArgChange: true
  });
  const { data : numberLaunchGameData ,isLoading: numberLaunchGameIsLoading,  refetch: refetchNumberLaunchGame } = useGetNumberLaunchGameBetweendatesQuery({ startDate : startDate, endDate : endDate }, {
    refetchOnMountOrArgChange: true
  });
  const { data : GAPageViews ,isLoading: GAPageViewsIsLoading,  refetch: refetchGAPageViews } = useGetGAPageViewsQuery({ startDate : startDate, endDate : endDate }, {
    refetchOnMountOrArgChange: true
  });

  const { data : GAAverageEngagementTime ,isLoading: GAAverageEngagementTimeIsLoading,  refetch: refetchGAAverageEngagementTime } = useGetGAAverageEngagementTimeQuery({ startDate : startDate, endDate : endDate }, {
    refetchOnMountOrArgChange: true
  });

  const minutes = Math.floor(GAAverageEngagementTime / 60);
  const seconds = Math.floor(GAAverageEngagementTime % 60);

  const responseString = `${minutes}m ${seconds}s`;
  


  const handleRegionChange = (region, setter) => {
    setter(region); // Update selected region state
  };

  return (
    <Box backgroundColor="#F3F5FA" borderRadius="20px 0px 0px 0px" p="1.5em">
      <FlexBetween sx={{flexWrap: "wrap", gap:"1em"}} >
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
        <Box sx={{display : 'flex' , justifyContent :"flex-end" , flexDirection :"row" , gap :"1em", flexGrow :1 , flexWrap: "wrap"}} >
        <NewTimeComponent isMediumScreens={isMediumScreens} isSmallScreens={isSmallScreens} isExtraSmallScreens={isExtraSmallScreens} />
        <Box>
          <DownloadButton />
        </Box>
        </Box>
      </FlexBetween>

      <Box
        mt="20px"
        display={isMediumScreens ? "grid" : isSmallScreens || isExtraSmallScreens ? "flex" : "grid"}
        flexDirection="column"
        gridTemplateColumns={isMediumScreens ? "repeat(4, 1fr)" : isSmallScreens ? "repeat(2, 1fr)" : "repeat(4, 1fr)"}
        gridAutoRows="135px"
        gap="20px"
        sx={{
          "& > div": { 
            gridColumn: isMediumScreens ? "span 12" : undefined,
            gridRow: isMediumScreens ? "span 4" : isSmallScreens ? "span 2" : isExtraSmallScreens ? "span 3" : undefined,
            height : isMediumScreens? "auto" : isSmallScreens || isExtraSmallScreens ? "30rem" : "auto"
          },
        }}
      >
        {/* ROW 1 */}
        <StatBoxActive
          title="Active Users"
          help="User who participated to at least one activity for the time period selected."
        />
        <StatBoxEngaged
          title="Engaged Users"
          help="User who participated to all activities for the time period selected."
        />
        <StatBoxPageViews
          title="Page Views"
          help="Number of pages viewed for the time period selected."
        />
        <StatBoxAverageTime
          title="Average Time Spent"
          help="Average engagement time spent per users for the time period selected."
        />
        
        {/* ROW 2 */}
        <Box sx={{
            display :"flex",
            textAlign:"center",
            gap:".5em",
            flexDirection: "column",
            flexGrow : 1,
            flexWrap :"nowrap",
            width : "auto",
            height :"100%",
            borderRadius : "10px",
            padding : "1em 1em 0em 1em",
            overflow:"hidden"
          }}
          gridColumn="span 3" 
          gridRow="span 4"
          backgroundColor='#FFFFFF'
        >
          <FlexBetween>
            <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>Participation Rate in Activities</Typography>
            <FilterActivities onChange={(region) => handleRegionChange(region, setSelectedRegionActivities)} />
          </FlexBetween>
          {selectedRegionActivities === "global" && <GlobalActivitiesBarChart height="90%" />}
          {selectedRegionActivities === "national" && <ActivitiesBarChart view={"national"} height="90%" />}
          {selectedRegionActivities === "center" && <ActivitiesBarChart view={"center"} height="90%" />}
          {selectedRegionActivities === "east" && <ActivitiesBarChart view={"east"} height="90%" />}
          {selectedRegionActivities === "west" && <ActivitiesBarChart view={"west"} height="90%" />}
        </Box>
        <Box sx={{
            display :"flex",
            textAlign:"center",
            gap:".5em",
            flexDirection: "column",
            flexGrow : 1,
            flexWrap :"nowrap",
            width : "auto",
            height :"100%",
            gridRow :"Span 2",
            gridColumn:"span 1",
            borderRadius : "10px",
            padding : "0em 1em 0em 1em",
            overflow:"hidden"
          }}  backgroundColor='#FFFFFF' > 
          <FlexBetween sx={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Typography paddingTop=".5em" variant="h6" sx={{ color: theme.palette.secondary[100] }}>Jpoints Total</Typography>
            <Tooltip title={"This chart is optimized for assessing weekly performance and may not function properly with custom states"} arrow>
              <IconButton
                size="small"
                onMouseEnter={() => setShowHelp(true)}
                onMouseLeave={() => setShowHelp(false)}
              >
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </FlexBetween>
            <Box position={{position:"relative"}} >
            <JpointsChart  />
            </Box>
        </Box>
        <Box sx={{
            display :"flex",
            textAlign:"center",
            gap:".5em",
            flexDirection: "column",
            flexGrow : 1,
            flexWrap :"nowrap",
            width : "auto",
            height :"100%",
            gridRow :"Span 2",
            gridColumn:"span 1",
            borderRadius : "10px",
            padding : "0em 1em 0em 1em",
            overflow:"hidden"
          }}  backgroundColor='#FFFFFF' > 
            <Typography 
              paddingTop=".5em" 
              variant="h6" 
              sx={{
                display: 'flex',
                justifyContent: 'center', 
                color: theme.palette.secondary[100] 
              }}
            >
              Redemption
            </Typography>

      
            <Box position={{position:"relative"}} >
            <PercentageRedemptionChart />
            </Box>
        </Box>
        

        {/* ROW 3 */}
        <Box
          gridColumn="span 2"
          gridRow="span 3"
          backgroundColor='#FFFFFF'
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <FlexBetween>
            <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
              Participation / Success Rate in Planogram Challenge
            </Typography>
            <FilterRegions onChange={(region) => handleRegionChange(region, setSelectedRegionPlano)} />
          </FlexBetween>
          {selectedRegionPlano === "national" && <WeeklyPlanoLineChart height="92%" />}
          {selectedRegionPlano === "regional" && <WeeklyPlanoBarChart  height="90%" />}
        </Box>
        <Box
          gridColumn="span 2"
          gridRow="span 3"
          backgroundColor='#FFFFFF'
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <FlexBetween>
            <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
              Participation / Success Rate in Stock Challenge
            </Typography>
            <FilterRegions onChange={(region) => handleRegionChange(region, setSelectedRegionStock)} />
          </FlexBetween>
          {selectedRegionStock === "national" && <WeeklyStockLineChart height="92%" />}
          {selectedRegionStock === "regional" && <WeeklyStockBarChart height="90%" />}
        </Box>
        
        {/* ROW 4 */}
        <Box gridColumn="span 2" gridRow="span 3" backgroundColor='#FFFFFF' p="1.5rem" borderRadius="0.55rem">
          <FlexBetween>
            <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>Participation / Success Rate in Quiz Challenge</Typography>
            <FilterRegions onChange={(region) => handleRegionChange(region, setSelectedRegionQuiz)} />
          </FlexBetween>
          {selectedRegionQuiz === "national" && <WeeklyQuizLineChart height="92%" />}
          {selectedRegionQuiz === "regional" && <WeeklyQuizBarChart height="90%" />}
        </Box>
        <Box
          gridColumn="span 2"
          gridRow="span 3"
          backgroundColor='#FFFFFF'
          p="1.5rem"
          borderRadius="0.55rem"
          sx={{
            position: 'relative',
            overflow: 'hidden',
            '&:hover .challenge-chart': {
              width: '70%',
            },
            '&:not(:hover) .challenge-chart': {
              width: '50%',
            }
          }}
        >
          <FlexBetween>
            <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
              Game launch and participation metrics
            </Typography>
            <FilterRegions onChange={(region) => handleRegionChange(region, setSelectedRegionGame)} />
          </FlexBetween>

          <FlexBetween sx={{ width: '100%', height: '100%', flex: 1 }}>
            <Box className="challenge-chart" sx={{ width: '50%', height: '100%', transition: 'width 0s' }}>
              {selectedRegionGame === "national" && <WeeklyGameLineChart height="92%" />}
              {selectedRegionGame === "regional" && <WeeklyGameBarChart height="90%" />} 
            </Box>
            <Box sx={{ flex: '1', p:"1rem 0rem", height: '100%'}}>
              <StatBoxGame
                title="Nombre de lancement"
              />
            </Box>
          </FlexBetween>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;