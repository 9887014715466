import { Box, List, ListItemButton, ListItemIcon, ListItemText, Divider } from "@mui/material";
import {  EditCalendar } from '@mui/icons-material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import EventIcon from '@mui/icons-material/Event';
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import moment from "moment";
import DatePicker , {CalendarContainer} from "react-datepicker";
import { startDateChanged , endDateChanged , rangeDateChanged , weeklyData , customData , setStateIsCompare } from '../state';

import { useDispatch } from 'react-redux';
import {  useGetWeeksListQuery , useGETWeeklyDataQuery} from 'state/api';
import "react-datepicker/dist/react-datepicker.css";  


const drawerWidth = 240;


const ToggleButton = styled(ListItemButton)(({ theme }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#58BDFF" // Change to your desired background color
  }
}));

function getWeekAgoDate() {
  const currentDate = new Date();
  const weekAgo = new Date(currentDate);
  weekAgo.setDate(currentDate.getDate() - 7);
  return weekAgo;
}


const TimeComponent = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(getWeekAgoDate());
  const [endDate, setEndDate] = useState(new Date());
  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const [showDatePicker, setShowDatePicker] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [weekId,setWeekData] = useState("");
  const [selectedWeek, setSelectedWeek] = useState("Custom");
  const RenderData = useSelector(state => state.global.dataRender);

  const { data : weeksList, isLoading: weeksListIsLoading ,  refetch: refetchWeeksList } = useGetWeeksListQuery();
  const { data : weekData , isLoading : weekLoading} = useGETWeeklyDataQuery({weekId});
  const reFetchCompare = useState(useSelector(state => state.global.isCompare));

  const [compare, setCompare] = useState(useSelector(state => state.global.isCompare)); 


  const handleChangeSwitch = () => {
    dispatch(setStateIsCompare());
    if(compare){
      setCompare(false);
    }else{
      setCompare(true);
    }
  };

  const handleWeekClick = (weekId) => {
    setWeekData(weekId);
    setSelectedWeek(weekId); // Update the selected week
    dispatch(weeklyData(weekId));
  };

  useEffect(() => {
    setStartDate(dateRange[0]);
    setEndDate(dateRange[1]);
    handleDateChange(dateRange[0], dateRange[1]);

    if(weekData && RenderData === "Week"){
      dispatch(weeklyData({weekData}))
    }else if(RenderData === "Custom"){
      const formatedStart = formatDate(startDate);
    const formatedEnd = formatDate(endDate);
      dispatch(rangeDateChanged({formatedStart , formatedEnd  }))
    }
  }, [dateRange,weekData, RenderData , compare])

  const handleDateChange = (startDate, endDate) => {
    if (!startDate || !endDate)
      return 
      const formatedStart = formatDate(startDate);
    const formatedEnd = formatDate(endDate);
    
    dispatch(
      rangeDateChanged({formatedStart , formatedEnd  })
    )
  }
  
  const handleStartDateChange = (date) => {
    setStartDate(date);
    const formatedStart = formatDate(date);
    dispatch(
      startDateChanged(formatedStart)
    )
  };
  
  const handleEndDateChange = (date) => {
    setEndDate(date);
    const formatedEnd = formatDate(date);
    dispatch(
      endDateChanged(formatedEnd)
    )
  };
  
  const formatDate = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    return formattedDate;
  };

  const MyContainer = ({ className, children }) => {
    return (
      <div style={{ padding: "16px", background: "#F0F0F0", color: "#ffffff" , width : "fit-content" }}>
        <CalendarContainer className={className}>
          <div style={{ position: "relative" }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <Box zIndex={1}>
      <Box display="flex" alignContent="center" gap="0.5em">
        <Box position="relative" className="timeComponent" onClick={toggleDatePicker}>
          {startDate && endDate ? `${moment(startDate).format("MMM Do YY")} - ${moment(endDate).format("MMM Do YY")}` : startDate ? `${moment(startDate).format("MMM Do YY")} - ` : `Please Select a date range to Continue`}
          {showDatePicker && (
            <Box position="absolute" top="2em" right="1em" backgroundColor="#F0F0F0" padding="16px" display="flex" onClick={(e) => e.stopPropagation()}>
              <Box maxHeight="27em"
    overflow="scroll"
    overflow-x="auto" margin="0" padding="0" sx={{
      '&::-webkit-scrollbar': {
        width: '0 !important', // hide horizontal scrollbar
        height: '0 !important', // hide vertical scrollbar
      }}} >
                <List>
                  <ToggleButton selected={selectedWeek === "Custom"} onClick={() => {
                    setSelectedWeek("Custom"); dispatch(customData);
                  }}>
                    <ListItemIcon>
                      <EditCalendar />
                    </ListItemIcon>
                    <ListItemText primary="Custom" />
                  </ToggleButton>
                  {weeksList &&
                    weeksList.map((week) => (
                      <ToggleButton
                        key={week.id}
                        selected={selectedWeek === week.id}
                        onClick={() => handleWeekClick(week.id)}
                      >
                        <ListItemIcon>
                          <EventIcon />
                        </ListItemIcon>
                        <ListItemText primary={week.id} />
                      </ToggleButton>
                    ))}
                </List>
                <Divider />
              </Box>

              <Box  display="flex" justifyContent="flex-end" flexDirection="column" >
                <Box display="flex" alignItems="center" justifyContent="center" alignContent="center" flexWrap="wrap"  >
                  <Box>
                    <DatePicker
                      enableTabLoop={false}
                      selected={startDate}
                      onChange={handleStartDateChange}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </Box>
                  <Box>
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                    />
                  </Box>
                </Box>
                <Box>
                  <DatePicker
                    selectsRange={true}
                    selected={startDate}
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    inline={true}
                    calendarContainer={MyContainer}
                  />
                  <FormControlLabel
          control={
            <Switch checked={compare} onChange={handleChangeSwitch} name="compare" />
          }
          label="Compare To"
        /> 
                </Box>
              </Box>
            </Box> 
          )}
        </Box>
        <Box display="flex">
          <svg clipRule="evenodd" width={15} fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" fill="lightblack" xmlns="http://www.w3.org/2000/svg"><path d="m16.843 10.211c.108-.141.157-.3.157-.456 0-.389-.306-.755-.749-.755h-8.501c-.445 0-.75.367-.75.755 0 .157.05.316.159.457 1.203 1.554 3.252 4.199 4.258 5.498.142.184.36.29.592.29.23 0 .449-.107.591-.291 1.002-1.299 3.044-3.945 4.243-5.498z" /></svg>
        </Box>
      </Box>
    </Box>
  );
};

export default TimeComponent;