import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import moment from "moment";

import {
  useGetGlobalPercentageParticipationSuccessQuizeachdayQuery,
  useGETWeeklyDataQuery
} from "state/api";

const WeeklyQuizLineChart = ({ height }) => {
  const startDate = useSelector(state => state.global.startDate);
  const endDate = useSelector(state => state.global.endDate);
  const dataRender = useSelector(state => state.global.dataRender);
  const CompareToRender = useSelector(state => state.global.CompareToRender);
  const compareStartDate = useSelector(state => state.global.compareStartDate);
  const compareEndDate = useSelector(state => state.global.compareEndDate);
  const weekId = useSelector(state => state.global.weekId);
  const weekCompareId = useSelector(state => state.global.weekCompareId);
  const isCompare = useSelector(state => state.global.isCompare);
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  const { data: customData, isLoading: customDataIsLoading, error: customDataError } = useGetGlobalPercentageParticipationSuccessQuizeachdayQuery({
    startDate,
    endDate
  });

  const { data: customCompareData, isLoading: customCompareDataIsLoading, error: customCompareDataError } = useGetGlobalPercentageParticipationSuccessQuizeachdayQuery({
    startDate : compareStartDate,
    endDate: compareEndDate
  });

  const { data : weekData , isLoading : weekLoading , refetch: refetchWeekData} = useGETWeeklyDataQuery(weekId ? {weekId : weekId.id} : null);
  const { data : CompareToData , isLoading : CompareToDataIsLoading , refetch: refetchCompareToData} = useGETWeeklyDataQuery(weekCompareId ? {weekId : weekCompareId.id }: null);


  useEffect(() => {

    let primaryChartData;
    let comparisonChartData;

    switch (dataRender) {
      case 'Custom':
        primaryChartData = customData ? customData[0] : null;
        comparisonChartData = isCompare ? CompareToRender === "Custom" ? customCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[23]?.data : null : null;
        break;
      case 'Week':
        primaryChartData = weekData ? weekData[23]?.data : null;
        comparisonChartData = isCompare ? CompareToRender === "Custom" ? customCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[23]?.data : null : null;
        break;
      default:
        primaryChartData = customData ? customData[0] : null;
        comparisonChartData = isCompare ? CompareToRender === "Custom" ? customCompareData?.[0] : CompareToRender === "Week" ? CompareToData?.[23]?.data : null : null;
        break;
    }

    const baseOptions = {
      chart: {
        height: 300,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.1
        },
        toolbar: {
          show: true
        }
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
        padding:{
          top: 0,
          bottom: isCompare ? 30 : 13,
          right: 5,
          left: 5,
        }
      },
      markers: {
        size: 1
      },
      legend: {
        position: 'bottom',
        floating: true,
      },
      yaxis: {
        title: {
          text: 'Percentage'
        },
       
      },
    };


    const isCompareOptions = isCompare ? {
      ...baseOptions,
      xaxis: {
        categories: primaryChartData?.days ? primaryChartData.days.map(day => moment(day).format("YYYY-MM-DD")) : comparisonChartData?.days ? comparisonChartData.days.map(day => moment(day).format("YYYY-MM-DD")) : [],
      },
      tooltip: {
        x: {
          show: true,
          formatter: function (val, { dataPointIndex }) {
            const primaryDays = primaryChartData?.days ? primaryChartData.days.map(day => moment(day).format("YYYY-MM-DD")) : [];
            const comparisonDays = comparisonChartData?.days ? comparisonChartData.days.map(day => moment(day).format("YYYY-MM-DD")) : [];
            let tooltipContent = `${val}: ${primaryDays[dataPointIndex]} (Selected)`;
            if (comparisonDays.length && comparisonDays[dataPointIndex]) {
              tooltipContent += ` vs ${comparisonDays[dataPointIndex]} (Compared)`;
            }
            return tooltipContent;
          }
        }
      }
    } : null;
    
    const isNotCompareOptions = !isCompare ? {
      ...baseOptions,
      xaxis: {
        categories: primaryChartData?.days ? primaryChartData.days.map(day => moment(day).format("YYYY-MM-DD")) : [],
      },
      tooltip: {
        x: {
          show: true,
          formatter: function (val, { dataPointIndex }) {
            const primaryDays = primaryChartData?.days ? primaryChartData.days.map(day => moment(day).format("YYYY-MM-DD")) : [];
            return `${val}: ${primaryDays[dataPointIndex]}`;
          }
        }
      }
    } : null;

    setOptions(isCompare ? isCompareOptions : isNotCompareOptions);

    const primarySeries = [
      { name: "Participation Rate", data: primaryChartData?.cumulativeParticipationPercentage || [], color: "#00E396" },
      { name: "Success Rate", data: primaryChartData?.cumulativeSuccessPercentage || [], color: "#B02A00"  },
    ];

    const comparisonSeries = [
      { name: "Selected Data: Participation Rate", data: primaryChartData?.cumulativeParticipationPercentage || [], color: "#00E396" },
      { name: "Selected Data: Success Rate", data: primaryChartData?.cumulativeSuccessPercentage || [], color: "#B02A00" },
      { name: "Compared Data: Participation Rate", data: comparisonChartData?.cumulativeParticipationPercentage || [], color: "#008FFB" },
      { name: "Compared Data: Success Rate", data: comparisonChartData?.cumulativeSuccessPercentage || [], color: "#FA9600"  }
    ];

    setSeries(isCompare ? comparisonSeries : primarySeries);

  }, [
    startDate, 
    endDate,
    compareStartDate,
    compareEndDate,
    isCompare,
    dataRender,
    CompareToRender,
    weekData,
    CompareToData,
    weekLoading,
    CompareToDataIsLoading,   
    customData,
    customDataIsLoading,  
    customDataError,    
    customCompareData, 
    customCompareDataIsLoading, 
    customCompareDataError,  
    
  ]);

  useEffect(() => {
    refetchWeekData();
    refetchCompareToData();
  },[weekId,weekCompareId])



  return (
    <Chart
      options={options}
      series={series}
      height={height}
      type="line"
    />
  );
};

export default WeeklyQuizLineChart;
